import React from "react";
import { Container, Row, Col } from "react-bootstrap";
//import home from "../../Assets/HomePageImages/Banner.svg";
import FeaturedSpecializationTabImage from "../../../../Assets/Ceridian/Dayforce_HR_Image.svg";
import Architects from "../../../../Assets/HomePageImages/ceradian/Get on-call.svg";
import Temporary from "../../../../Assets/HomePageImages/ceradian/Ensure a smooth.svg";
import multidisciplined from "../../../../Assets/HomePageImages/ceradian/Reduce your risks.svg";
import teammember from "../../../../Assets/HomePageImages/ceradian/Engage our.svg";



function DayforceHR() {
    return (
        <section>
            <Container fluid='md' className="FeaturedSpecializationTab-section" id="FeaturedSpecializationTab">
                <Row className="Hor-Center">
                    <Col md={12} style={{padding:0}}>
                        <Row className="justify-content-between">
                            <Col md={7} className="FeaturedSpecializationTabConent">
                                
                                <p className="FeaturedSpecializationTabdesc">You can rely on our specialists for technical support and advice on optimizing the value of your technology. </p>
                               
                                <div className="FeaturedSpecializationTabdiv">
                                    <img
                                        src={Architects}
                                        alt="ArchitectsImage pic"
                                        className="img-fluid FeaturedSpecializationTabIcon"
                                    /><span>Get on-call support for configuration and troubleshooting. </span>
                                </div>
                                <div className="FeaturedSpecializationTabdiv">
                                    <img
                                        src={Temporary}
                                        alt="TemporaryImage pic"
                                        className="img-fluid FeaturedSpecializationTabIcon"
                                    /><span>Ensure a smooth self-service experience so your people can resolve their questions and get back to doing what they do best. </span>
                                </div>
                                <div className="FeaturedSpecializationTabdiv">
                                    <img
                                        src={multidisciplined}
                                        alt="multidisciplinedImage pic"
                                        className="img-fluid FeaturedSpecializationTabIcon"
                                    /><span>Reduce your risks and liabilities with expert data control </span>
                                </div>
                                <div className="FeaturedSpecializationTabdiv">
                                    <img
                                        src={teammember}
                                        alt="teammemberImage pic"
                                        className="img-fluid FeaturedSpecializationTabIcon"
                                    /><span>Engage our People Analytics experts to optimize your intelligence delivery so you can make better and faster proactive decisions. </span>
                                </div>
                            </Col>
                            <Col md={5} >
                                <div className="FeaturedSpecializationTabImagediv">
                                    <img
                                        src={FeaturedSpecializationTabImage}
                                        alt="FeaturedSpecializationTabImage pic"
                                        className="img-fluid FeaturedSpecializationTabImg"
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default DayforceHR;