import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import BlogImage1 from "../../Assets/HomePageImages/HomeBlog1.png";
import BlogImage2 from "../../Assets/HomePageImages/HomeBlog2.png";
import BlogImage3 from "../../Assets/HomePageImages/HomeBlog1.png";
import BlogImage4 from "../../Assets/HomePageImages/HomeBlog2.png";


function Blogs(){
    return (
        <section>
            <Container fluid className="Blog-section" id="Blogs">
                <Row>
                    <Col md={12} className="Blogs">
                        <h3 className="Blogstitle">Insights</h3>
                        {/* <Row className="justify-content-center"> */}
                        <Container fluid='md'>
                            <Row className="g-5 justify-content-between" >
                                <Col md={4} sm={12} className="Blogcard">
                                    <Col md={12} className="BlogContent">
                                        <img
                                            src={BlogImage1}
                                            alt="home pic"
                                            className="img-fluid Blogimg"
                                        />
                                        <Col md={12} className="BlogData">
                                            <div><span className="date">08-11-2022</span><span className="Category">Category</span></div>
                                            <h6 className="blogname">Believing neglected so allowance existence departure.</h6>
                                            <p className="blogdesc">End-to-end payments and Financial management in a single solution. Meet the right platform to help.</p>
                                        </Col>
                                    </Col>

                                </Col>
                                <Col md={4} sm={12} className="Blogcard">
                                    <Col md={12} className="BlogContent">
                                        <img
                                            src={BlogImage2}
                                            alt="home pic"
                                            className="img-fluid Blogimg"
                                        />
                                        <Col md={12} className="BlogData">
                                        <div><span className="date">08-11-2022</span><span className="Category">Category</span></div>
                                            <h6 className="blogname">In design active temper be uneasy. Thirty for the remove plenty regard you</h6>
                                            <p className="blogdesc">End-to-end payments and Financial management in a single solution. Meet the right platform to help.</p>
                                         </Col>
                                    </Col>
                                </Col>
                                <Col md={4} sm={12} className="Blogcard">
                                    <Col md={12} className="BlogContent">
                                        <img
                                            src={BlogImage3}
                                            alt="home pic"
                                            className="img-fluid Blogimg"
                                        />
                                        <Col md={12} className="BlogData">
                                        <div><span className="date">08-11-2022</span><span className="Category">Category</span></div>
                                            <h6 className="blogname">Partially on or continuing in particular principles</h6>
                                            <p className="blogdesc">End-to-end payments and Financial management in a single solution. Meet the right platform to help.</p>
                                         </Col>
                                    </Col>
                                    <Col md={12} className="BlogContent">
                                        <img
                                            src={BlogImage4}
                                            alt="home pic"
                                            className="img-fluid Blogimg"
                                        />
                                        <Col md={12} className="BlogData">
                                        <div><span className="date">08-11-2022</span><span className="Category">Category</span></div>
                                            <h6 className="blogname">Partially on or continuing in particular principles</h6>
                                            <p className="blogdesc">End-to-end payments and Financial management in a single solution. Meet the right platform to help.</p>
                                         </Col>
                                    </Col>
                                    <Col md={12} className="BlogContent">
                                        <img
                                            src={BlogImage3}
                                            alt="home pic"
                                            className="img-fluid Blogimg"
                                        />
                                        <Col md={12} className="BlogData">
                                        <div><span className="date">08-11-2022</span><span className="Category">Category</span></div>
                                            <h6 className="blogname">Partially on or continuing in particular principles</h6>
                                            <p className="blogdesc">End-to-end payments and Financial management in a single solution. Meet the right platform to help.</p>
                                        </Col>
                                    </Col>
                                    <Col md={12} className="BlogContent">
                                        <img
                                            src={BlogImage4}
                                            alt="home pic"
                                            className="img-fluid Blogimg"
                                        />
                                        <Col md={12} className="BlogData">
                                        <div><span className="date">08-11-2022</span><span className="Category">Category</span></div>
                                            <h6 className="blogname">Partially on or continuing in particular principles</h6>
                                            <p className="blogdesc">End-to-end payments and Financial management in a single solution. Meet the right platform to help.</p>
                                        </Col>
                                    </Col>
                                </Col>
                               
                            </Row>
                            </Container>
                            <div className="BlogLinkdiv"> <a
                                href="/"
                                target="_blank"
                                rel="noreferrer"
                                className="BlogLink"
                            >
                                See All Blog Posts
                            </a></div>
                        {/* </Row> */}


                    </Col>
                </Row>
            </Container>
        </section >
    );
}

export default Blogs;