import React from 'react';
import ReactDOM from "react-dom/client";
// import { render } from "react-dom";

import './index.css';
import { BrowserRouter } from 'react-router-dom'
import App from './App';
import reportWebVitals from './reportWebVitals';
import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";



// // const corsOptions ={
// //     origin:"https://api.hsforms.com", 
// //     credentials:true,            //access-control-allow-credentials:true
// //     optionSuccessStatus:200
// // }
// // app.use(cors(corsOptions));



const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>    
   <App />   
  </BrowserRouter>,
);

// const rootElement = document.getElementById("root");


// render(
//   <BrowserRouter>
//     <App />
//   </BrowserRouter>
//   ,
//   rootElement
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
