import React from "react";
import { Container, Row, Col } from "react-bootstrap";
//import home from "../../Assets/HomePageImages/Banner.svg";
import DayforceTalentManagementTabImage from "../../../../Assets/HomePageImages/Sumtotal/SumTotal Talent Development Suite.svg";
import Architects from "../../../../Assets/HomePageImages/Sumtotal/Recruting.svg";
import Temporary from "../../../../Assets/HomePageImages/Sumtotal/Performance.svg";
import multidisciplined from "../../../../Assets/HomePageImages/Sumtotal/Manage.svg";
import Strategic from "../../../../Assets/HomePageImages/Sumtotal/Strategic.svg";



function SumTotalTalentDevelopmentSuite() {
    return (
        <section>
           <Container fluid='md' className="FeaturedSpecializationTab-section" id="FeaturedSpecializationTab">
                <Row className="Hor-Center">
                    <Col md={12} style={{padding:0}}>
                        <Row className="justify-content-between">
                            <Col md={7} className="FeaturedSpecializationTabConent">
                               
                                <p className="FeaturedSpecializationTabdesc">Maintaining smooth-running, well-integrated talent development shouldn’t be a heavy burden. </p>
                                <p className="FeaturedSpecializationTabdesc">SumTotal provides the tools, and we help you find the right administrator to make it work for you. </p>
                              
                                <div className="FeaturedSpecializationTabdiv">
                                    <img
                                        src={Architects}
                                        alt="ArchitectsImage pic"
                                        className="img-fluid FeaturedSpecializationTabIcon"
                                    /><span>Recruiting and onboarding specialists to enhance your candidate experience. </span>
                                </div>
                                <div className="FeaturedSpecializationTabdiv">
                                    <img
                                        src={Temporary}
                                        alt="TemporaryImage pic"
                                        className="img-fluid FeaturedSpecializationTabIcon"
                                    /><span>Performance management specialists to help you boost retention and productivity. </span>
                                </div>
                                <div className="FeaturedSpecializationTabdiv">
                                    <img
                                        src={multidisciplined}
                                        alt="multidisciplinedImage pic"
                                        className="img-fluid FeaturedSpecializationTabIcon"
                                    /><span>Manage the entire compensation process, from strategy to paycheck. </span>
                                </div>
                                <div className="FeaturedSpecializationTabdiv">
                                    <img
                                        src={Strategic}
                                        alt="TemporaryImage pic"
                                        className="img-fluid FeaturedSpecializationTabIcon"
                                    /><span>Strategic alignment of your development pipeline. </span>
                                </div>

                            </Col>
                            <Col md={5} >
                                <div className="FeaturedSpecializationTabImagediv">
                                    <img
                                        src={DayforceTalentManagementTabImage}
                                        alt="FeaturedSpecializationTabImage pic"
                                        className="img-fluid FeaturedSpecializationTabImg"
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default SumTotalTalentDevelopmentSuite;