import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

import Cornerstone from "../../Assets/HomePageImages/vendors/Cornerstone.svg";

import Salesforce from "../../Assets/HomePageImages/vendors/Salesforce.svg";
import Servicenow from "../../Assets/HomePageImages/vendors/Servicenow.svg";
import SumTotal from "../../Assets/HomePageImages/vendors/SumTotal.svg";

import Workday from "../../Assets/HomePageImages/vendors/Workday.svg";

function TrustedClients() {
    return (
        <section>
            <Container fluid='md' className="TrustedClients-section" id="TrustedClients">
                <Row>
                    <Col md={12} className="TrustedClientsConent" style={{padding:0}}>
                        <p className="TrustedClientsdesc">Trusted By Over 100+ Startups and freelance business</p>
                        <Row>
                    <Col md={12} className="TrustedClients justify-content-center">
                        
                        <Row className="justify-content-between" style={{padding:0}}>
                            <Col md={12}>
                                <Row  className="justify-content-between" style={{alignItems: "center"}}>
                                   
                                    <Col  className="TrustedClientscard">
                                        <Link to="/Cornerstone">
                                            <Col md={12} className="TrustedClientsContent">
                                                <img
                                                    src={Cornerstone}
                                                    alt="home pic"
                                                    className="img-fluid Blogimg"
                                                />

                                            </Col>
                                        </Link>
                                    </Col>
                                    <Col className="TrustedClientscard">
                                        <Link to="/Servicenow">
                                            <Col md={12} className="TrustedClientsContent">
                                                <img
                                                    src={Servicenow}
                                                    alt="home pic"
                                                    className="img-fluid Blogimg"
                                                />
                                            </Col>
                                        </Link>
                                    </Col>
                                    <Col className="TrustedClientscard">
                                        <Link to="/Salesforce">
                                            <Col md={12} className="TrustedClientsContent">
                                                <img
                                                    src={Salesforce}
                                                    alt="home pic"
                                                    className="img-fluid Blogimg"
                                                />

                                            </Col>
                                        </Link>
                                    </Col>                                  
                                    <Col  className="TrustedClientscard">
                                        <Link to="/Sumtotal">
                                            <Col md={12} className="TrustedClientsContent">
                                                <img
                                                    src={SumTotal}
                                                    alt="home pic"
                                                    className="img-fluid TrustedClientsimg"
                                                />

                                            </Col>
                                        </Link>
                                    </Col>                                    
                                    <Col  className="TrustedClientscard">
                                        <Link to="/Workday">
                                            <Col md={12} className="TrustedClientsContent">
                                                <img
                                                    src={Workday}
                                                    alt="home pic"
                                                    className="img-fluid TrustedClientsimg"
                                                />

                                            </Col>
                                        </Link>
                                    </Col>
                                </Row>
                            </Col>

                        </Row>


                    </Col>
                </Row>
                    </Col>
                </Row>
            </Container>
        </section >
    );
}

export default TrustedClients;