import React from "react";
import { Container, Row, Col } from "react-bootstrap";
//import home from "../../../Assets/HomePageImages/Banner.svg";
import DataScientistsImage from "../../../Assets/HomePageImages/EnterpriseAnalytics/Data Scientists.svg";
import Architects from "../../../Assets/HomePageImages/icons/Architects.svg";
import Temporary from "../../../Assets/HomePageImages/icons/Temporary_talent_to_fill_a_gap.svg";
import multidisciplined from "../../../Assets/HomePageImages/icons/A_multi_disciplined.svg";




function DataScientists() {
    return (
        <section>
            <Container fluid='md' className="DataScientists-section" id="DataScientists">
                <Row className="Hor-Center">
                    <Col md={12} className="px-md-0" >
                        <Row style={{ alignItems: "center" }}>
                            <Col md={6} className="px-md-0" >
                                <div className="DataScientistsImagediv">
                                    <img
                                        src={DataScientistsImage}
                                        alt="DataScientistsImage pic"
                                        className="img-fluid DataScientistsImg"
                                    />
                                </div>
                            </Col>
                            <Col md={6} className="DataScientistsConent px-md-0">
                                <h3 className="DataScientiststitle">Data Scientists</h3>
                                <p className="DataScientistsdesc">Advanced data expertise to solve your most pressing business challenges: </p>
                                <div className="DataScientistsdiv">
                                    <img
                                        src={Architects}
                                        alt="ArchitectsImage pic"
                                        className="img-fluid DataScientistsIcon"
                                    /><span>Design data models to create business intelligence.</span>
                                </div>
                                <div className="DataScientistsdiv">
                                    <img
                                        src={Temporary}
                                        alt="TemporaryImage pic"
                                        className="img-fluid DataScientistsIcon"
                                    /><span>Analyze and automate processes with advanced algorithms.</span>
                                </div>
                                <div className="DataScientistsdiv">
                                    <img
                                        src={multidisciplined}
                                        alt="multidisciplinedImage pic"
                                        className="img-fluid DataScientistsIcon"
                                    /><span>Design machine learning and expert systems.</span>
                                </div>
                            </Col>

                        </Row>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default DataScientists;