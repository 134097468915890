import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import AdditionalInformationImage from "../../Assets/HomePageImages/Additional_Info.svg";
function AdditionalInformation() {
    return (
        <section>
            <Container fluid='md' className="AdditionalInformation-section" id="AdditionalInformation">
                <Row style={{ justifyContent: "center" }}>
               
                <Col md={12}  sm={10} xs={10} className="AdditionalInformationcontentdiv">
                
                <Row>
                <Col md={8} sm={12} xs={12} className="AdditionalInformationContext">
                <img
                                            src={AdditionalInformationImage}
                                            alt="AdditionalInformation pic"
                                            className="img-fluid AdditionalInformationImage"
                                        />
                                        <div>
                                        <h2 className="AdditionalInformationtitle">Schedule A No-Cost Consultation With A Talent Specialist</h2>
                                        <h5>Call Us <a href="tel:+1 (XXX) XXX-XXXX">+1(855)978-6816</a></h5>  
                                       
                                        </div>

                </Col>
                <Col md={4} sm={12} xs={12} className="AdditionalInformationdiv">
                                    <Col  style={{ marginTop: "3%", lineHeight: "0.7" }}> <a
                                            href="/"
                                            target="_blank"
                                            rel="noreferrer"
                                            className="AdditionalInformationLink"

                                        >
                                         Book an Appointment
                                        </a></Col>
                </Col>
                </Row>
                
                </Col>
                </Row>
            </Container >
        </section >
    );
}

export default AdditionalInformation;