import React from "react";
import { Container, Row, Col } from "react-bootstrap";
function Specializations() {
    return (
        <section>
            <Container fluid className="Specializations-section" id="Specializations">
                <Row>
                    <Col md={12} className="Specializations px-md-0" >
                        <Container fluid='md'>
                        <h3 className="Specializationstitle">Specializations</h3>
                        <Row style={{ justifyContent: "center" }}>
                            <Col md={12} className="px-md-0">
                                <p>The HR tech stack is the most complex in an enterprise. It yields
                                    tremendous value when its data streams are managed well and
                                    analyzed by experts to extract the human value. </p>
                                <p>It requires people who can inspire trust in data management, data
                                    privacy, and ethical use of information.  </p>
                                <p>With no-cost consulting services to discover your specific needs,
                                    we match you to qualified experts who can make your people
                                    data work for you. </p>
                                <Row style={{ justifyContent: "center" }}>
                                    <Col md={12} className="px-md-0">
                                        <Row >
                                            <Col md={4} sm={12} xs={12} className="Specializationscard">
                                                <p>People Analytics Leader</p>
                                                <p>People Analyst</p>
                                                <p>HRIS Manager</p>
                                                <p>People Operations Analyst</p>
                                                <p>People Analytics Consultant</p>
                                            </Col>
                                            <Col></Col>
                                            <Col md={4} sm={12} xs={12} className="Specializationscard" style={{padding:0}}>
                                                <p>Behavioral Analyst</p>
                                                <p>Research Analyst</p>
                                                <p>Leadership Analyst</p>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>

                        </Row>

                        </Container>
                    </Col>
                </Row>
            </Container>
        </section >
    );
}

export default Specializations;
