import React from "react";
import { Container, Row, Col } from "react-bootstrap";
//import home from "../../Assets/HomePageImages/Banner.svg";
import DayforceIntegrationsTabImage from "../../../../Assets/HomePageImages/Workday/Integrations.svg";
import Architects from "../../../../Assets/HomePageImages/Workday/Specialists.svg";
import Temporary from "../../../../Assets/HomePageImages/Workday/Enterprise.svg";
import multidisciplined from "../../../../Assets/HomePageImages/Workday/Workday.svg";



function Integrations() {
    return (
        <section>
           <Container fluid='md' className="FeaturedSpecializationTab-section" id="FeaturedSpecializationTab">
                <Row className="Hor-Center">
                    <Col md={12} style={{padding:0}}>
                        <Row className="justify-content-between">
                            <Col md={7} className="FeaturedSpecializationTabConent">
                                
                                <p className="FeaturedSpecializationTabdesc">Workday offers many methods to connect your HCM application to internal and external data streams. </p>
                                
                                <div className="FeaturedSpecializationTabdiv">
                                    <img
                                        src={Architects}
                                        alt="ArchitectsImage pic"
                                        className="img-fluid FeaturedSpecializationTabIcon"
                                    /><span>Specialists to manage your Workday Services APIs that connect with other standard-based platforms and middleware. </span>
                                </div>
                                <div className="FeaturedSpecializationTabdiv">
                                    <img
                                        src={Temporary}
                                        alt="TemporaryImage pic"
                                        className="img-fluid FeaturedSpecializationTabIcon"
                                    /><span>Enterprise Interface Builder (EIB) specialists to create and maintain your data flow in the cloud to keep your organizational structure, employee information, and third-party data transfers running smoothly. </span>
                                </div>
                                <div className="FeaturedSpecializationTabdiv">
                                    <img
                                        src={multidisciplined}
                                        alt="multidisciplinedImage pic"
                                        className="img-fluid FeaturedSpecializationTabIcon"/>
                                    <span>Workday Studio developers to build and maintain your custom integration.</span>
                                </div>

                            </Col>
                            <Col md={5} >
                                <div className="FeaturedSpecializationTabImagediv">
                                    <img
                                        src={DayforceIntegrationsTabImage}
                                        alt="FeaturedSpecializationTabImage pic"
                                        className="img-fluid FeaturedSpecializationTabImg"
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default Integrations;