import React from "react";
import { Container, Row, Col } from "react-bootstrap";
//import home from "../../Assets/HomePageImages/Banner.svg";
import DayforceBenefitsTabImage from "../../../../Assets/Ceridian/Dayforce_Benefits_Image.svg";
import Architects from "../../../../Assets/HomePageImages/salesforce/Rollout planning.svg";
import Temporary from "../../../../Assets/HomePageImages/salesforce/User administration.svg";
import multidisciplined from "../../../../Assets/HomePageImages/salesforce/Lightning components.svg";
import VideoConference from "../../../../Assets/HomePageImages/salesforce/Video conferencing.svg";
import ProductivityAction from "../../../../Assets/HomePageImages/salesforce/Productivity action.svg";



function ChatterAdministration() {
    return (
        <section>
            <Container fluid='md' className="FeaturedSpecializationTab-section" id="FeaturedSpecializationTab">
                <Row className="Hor-Center">
                    <Col md={12} style={{padding:0}}>
                        <Row  className="justify-content-between">
                            <Col md={7} className="FeaturedSpecializationTabConent">
                                
                                <p className="FeaturedSpecializationTabdesc">Hire a qualified administrator to manage your Chatter rollout and support. </p>

                                <div className="FeaturedSpecializationTabdiv">
                                    <img
                                        src={Architects}
                                        alt="ArchitectsImage pic"
                                        className="img-fluid FeaturedSpecializationTabIcon"
                                    /><span>Rollout planning and support. </span>
                                </div>
                                <div className="FeaturedSpecializationTabdiv">
                                    <img
                                        src={Temporary}
                                        alt="TemporaryImage pic"
                                        className="img-fluid FeaturedSpecializationTabIcon"
                                    /><span>User administration and support. </span>
                                </div>
                                <div className="FeaturedSpecializationTabdiv">
                                    <img
                                        src={multidisciplined}
                                        alt="multidisciplinedImage pic"
                                        className="img-fluid FeaturedSpecializationTabIcon"
                                    /><span>Lightning components, Visualforce pages, and canvas apps management. </span>
                                </div>
                                <div className="FeaturedSpecializationTabdiv">
                                    <img
                                        src={VideoConference}
                                        alt="multidisciplinedImage pic"
                                        className="img-fluid FeaturedSpecializationTabIcon"
                                    /><span>Video conferencing troubleshooting and support. </span>
                                </div>
                                <div className="FeaturedSpecializationTabdiv">
                                    <img
                                        src={ProductivityAction}
                                        alt="multidisciplinedImage pic"
                                        className="img-fluid FeaturedSpecializationTabIcon"
                                    /><span>Productivity action troubleshooting and support.</span>
                                </div>


                            </Col>
                            <Col md={5} >
                                <div className="FeaturedSpecializationTabImagediv">
                                    <img
                                        src={DayforceBenefitsTabImage}
                                        alt="FeaturedSpecializationTabImage pic"
                                        className="img-fluid FeaturedSpecializationTabImg"
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default ChatterAdministration;