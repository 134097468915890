import React from "react";
import { Container, Row, Col } from "react-bootstrap";
//import home from "../../Assets/HomePageImages/Banner.svg";
import DayforceTalentManagementTabImage from "../../../../Assets/Ceridian/Dayforce_Talent_Management_Image.svg";
import Architects from "../../../../Assets/HomePageImages/oracle/Recruiting and.svg";
import Temporary from "../../../../Assets/HomePageImages/oracle/Opportunity Marketplace.svg";
import multidisciplined from "../../../../Assets/HomePageImages/oracle/PeopleSoft.svg";



function TalentManagement() {
    return (
        <section>
            <Container fluid='md' className="FeaturedSpecializationTab-section" id="FeaturedSpecializationTab">
                <Row className="Hor-Center">
                    <Col md={12} style={{padding:0}}>
                        <Row className="justify-content-center">
                            <Col md={7} className="FeaturedSpecializationTabConent">
                               
                                <p className="FeaturedSpecializationTabdesc">Maintaining smooth-running, well-integrated talent management shouldn’t be a heavy burden. </p>
                                
                                <div className="FeaturedSpecializationTabdiv">
                                    <img
                                        src={Architects}
                                        alt="ArchitectsImage pic"
                                        className="img-fluid FeaturedSpecializationTabIcon"
                                    /><span>Recruiting and onboarding specialists to enhance your candidate experience. </span>
                                </div>
                                <div className="FeaturedSpecializationTabdiv">
                                    <img
                                        src={Temporary}
                                        alt="TemporaryImage pic"
                                        className="img-fluid FeaturedSpecializationTabIcon"
                                    /><span>Opportunity Marketplace specialists to help you create a dynamic, innovative culture. </span>
                                </div>
                                <div className="FeaturedSpecializationTabdiv">
                                    <img
                                        src={multidisciplined}
                                        alt="multidisciplinedImage pic"
                                        className="img-fluid FeaturedSpecializationTabIcon"
                                    /><span>PeopleSoft project leaders and consultants. </span>
                                </div>

                            </Col>
                            <Col md={5} >
                                <div className="FeaturedSpecializationTabImagediv">
                                    <img
                                        src={DayforceTalentManagementTabImage}
                                        alt="FeaturedSpecializationTabImage pic"
                                        className="img-fluid FeaturedSpecializationTabImg"
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default TalentManagement;