import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import CloudEngineersImage from "../../../Assets/HomePageImages/CloudTechnology/Cloud Engineers.svg";
import Architects from "../../../Assets/HomePageImages/icons/Architects.svg";
import Temporary from "../../../Assets/HomePageImages/icons/Temporary_talent_to_fill_a_gap.svg";
import multidisciplined from "../../../Assets/HomePageImages/icons/A_multi_disciplined.svg";


function CloudEngineers() {
    return (
        <section>
            <Container fluid="md" className="CloudEngineers-section" id="CloudEngineers">
                <Row className="Hor-Center py-3">
                    <Col md={12}  className='px-md-0'>
                        <Row style={{ alignItems: "center" }}>

                            <Col md={6} className="CloudEngineersConent px-md-0 order-md-first order-sm-last order-last" >
                                <h3 className="CloudEngineerstitle">Cloud Engineers </h3>
                                <p className="CloudEngineersdesc">A cloud engineer will assess your technology and help you develop a plan for moving to the cloud. </p>
                                <div className="CloudEngineersdiv">
                                    <img
                                        src={Architects}
                                        alt="ArchitectsImage pic"
                                        className="img-fluid CloudEngineersIcon"
                                    /><span>Manage migration and maintenance. </span>
                                </div>
                                <div className="CloudEngineersdiv">
                                    <img
                                        src={Temporary}
                                        alt="TemporaryImage pic"
                                        className="img-fluid CloudEngineersIcon"
                                    /><span>Manage vendors, including infrastructure providers. </span>
                                </div>
                                <div className="CloudEngineersdiv" >
                                    <img
                                        src={multidisciplined}
                                        alt="multidisciplinedImage pic"
                                        className="img-fluid CloudEngineersIcon"
                                    /><span>Coordinate migration with management and IT teams. </span>
                                </div>



                            </Col >
                            <Col md={6} className='px-md-0 order-md-last order-sm-last order-first'>
                                <div className="CloudEngineersImagediv">
                                    <img
                                        src={CloudEngineersImage}
                                        alt="CloudEngineersImage pic"
                                        className="img-fluid CloudEngineersImg"
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default CloudEngineers;