import React from "react";
import { Container, Row, Col } from "react-bootstrap";
//import home from "../../../Assets/HomePageImages/Banner.svg";
import DevOpsImage from "../../../Assets/HomePageImages/DigitalTransformation/DevOps.svg";
import Architects from "../../../Assets/HomePageImages/icons/Architects.svg";
import Temporary from "../../../Assets/HomePageImages/icons/Temporary_talent_to_fill_a_gap.svg";
import multidisciplined from "../../../Assets/HomePageImages/icons/A_multi_disciplined.svg";


function DevOps() {
    return (
        <section>
            <Container fluid='md' className="DevOps-section" id="DevOps">
                <Row className="Hor-Center">
                    <Col md={12} className="px-md-0">
                        <Row style={{ alignItems: "center" }}>

                            <Col md={6} className="DevOpsConent px-md-0 order-md-first order-sm-last order-last">
                                <h3 className="DevOpstitle">DevOps</h3>
                                <p className="DevOpsdesc">Create a well-oiled development process with DevOps expertise that brings technology operators and developers together to make the right decisions. </p>
                                <div className="DevOpsdiv">
                                    <img
                                        src={Architects}
                                        alt="ArchitectsImage pic"
                                        className="img-fluid DevOpsIcon"
                                    /><span>Get access to the most in-demand development expertise.</span>
                                </div>
                                <div className="DevOpsdiv">
                                    <img
                                        src={Temporary}
                                        alt="TemporaryImage pic"
                                        className="img-fluid DevOpsIcon"
                                    /><span>Accelerate your development process with the right talent. </span>
                                </div>
                                <div className="DevOpsdiv">
                                    <img
                                        src={multidisciplined}
                                        alt="multidisciplinedImage pic"
                                        className="img-fluid DevOpsIcon"
                                    /><span>Hire the cloud, low-code, and new-age skills you need to be competitive.</span>
                                </div>

                            </Col>
                            <Col md={6}className="px-md-0 order-md-last order-sm-last order-first">
                                <div className="DevOpsImagediv">
                                    <img
                                        src={DevOpsImage}
                                        alt="DevOpsImage pic"
                                        className="img-fluid DevOpsImg"
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default DevOps;