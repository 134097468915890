import React, { useState } from 'react'
import Form from 'react-bootstrap/Form';

const FieldInput = (props) => {


  const[focused,setFocused]=useState('')
  const handleFocus=(e)=>{
setFocused(true)
  }
  return (
    <>
    <Form.Group className="mb-3" controlId="formBasicEmail">
    <Form.Label>{props.label}</Form.Label>
    <Form.Control className='Inputvalues' name={props.name} type={props.fieldtype} required onBlur={handleFocus} focused={focused.toString()}
      onChange={props.handleChange} pattern={props.Pattern}
    //  onChange = {e => props.setUsername(e.target.value)}
     />
    <Form.Text className="text-danger erm">
      {props.errormessage}
    </Form.Text>
  </Form.Group>
  
  </>
  )
}

export default FieldInput
