import React from "react";
import { Container } from "react-bootstrap";


function Footer() {
  // let date = new Date();
  // let year = date.getFullYear();
  return (
    <Container fluid style={{ background: "#222225" }} className="footer">
      <span>2022 &copy; Phenom Staff. All Rights Reserved</span>
      <span>Privacy Policy</span>
      <span>Site Map</span>
      <span>Disclaimer</span>
      <span>Cookie Policy</span>
    </Container>
  );
}

export default Footer;
