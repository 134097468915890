import React from "react";
import { Container, Row, Col } from "react-bootstrap";
//import home from "../../Assets/HomePageImages/Banner.svg";
import FeaturedSpecializationTabImage from "../../../../Assets/Ceridian/Dayforce_HR_Image.svg";
import Architects from "../../../../Assets/HomePageImages/salesforce/CRM deployment.svg";
import Temporary from "../../../../Assets/HomePageImages/salesforce/Platform app.svg";
import multidisciplined from "../../../../Assets/HomePageImages/salesforce/Security specializations.svg";
import teammember from "../../../../Assets/HomePageImages/salesforce/Workflow specialists.svg";

function SalesforceCRM() {
    return (
        <section>
           <Container fluid='md' className="FeaturedSpecializationTab-section" id="FeaturedSpecializationTab">
                <Row className="Hor-Center">
                    <Col md={12} style={{padding:0}}>
                        <Row>
                            <Col md={7} className="FeaturedSpecializationTabConent">
                                
                                <p className="FeaturedSpecializationTabdesc">Leverage your CRM for HR to improve service speed and quality. </p>
                                
                                <div className="FeaturedSpecializationTabdiv">
                                    <img
                                        src={Architects}
                                        alt="ArchitectsImage pic"
                                        className="img-fluid FeaturedSpecializationTabIcon"
                                    /><span>CRM deployment and configuration.</span>
                                </div>
                                <div className="FeaturedSpecializationTabdiv">
                                    <img
                                        src={Temporary}
                                        alt="TemporaryImage pic"
                                        className="img-fluid FeaturedSpecializationTabIcon"
                                    /><span>Platform app builders to improve HR capabilities. </span>
                                </div>
                                <div className="FeaturedSpecializationTabdiv">
                                    <img
                                        src={multidisciplined}
                                        alt="multidisciplinedImage pic"
                                        className="img-fluid FeaturedSpecializationTabIcon"
                                    /><span>Security specializations. </span>
                                </div>
                                <div className="FeaturedSpecializationTabdiv">
                                    <img
                                        src={teammember}
                                        alt="teammemberImage pic"
                                        className="img-fluid FeaturedSpecializationTabIcon"
                                    /><span>Workflow specialists.</span>
                                </div>
                            </Col>
                            <Col md={5}>
                                <div className="FeaturedSpecializationTabImagediv">
                                    <img
                                        src={FeaturedSpecializationTabImage}
                                        alt="FeaturedSpecializationTabImage pic"
                                        className="img-fluid FeaturedSpecializationTabImg"
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default SalesforceCRM;