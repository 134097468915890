import React from "react";
import { Container, Row, Col } from "react-bootstrap";
//import home from "../../../Assets/HomePageImages/Banner.svg";
import DataManagersImage from "../../../Assets/HomePageImages/EnterpriseAnalytics/Data Mangers.svg";
import Architects from "../../../Assets/HomePageImages/icons/Architects.svg";
import Temporary from "../../../Assets/HomePageImages/icons/Temporary_talent_to_fill_a_gap.svg";
import multidisciplined from "../../../Assets/HomePageImages/icons/A_multi_disciplined.svg";
import teammember from "../../../Assets/HomePageImages/icons/A_new_team_member.svg";




function DataManagers() {
    return (
        <section>
            <Container fluid='md' className="DataManagers-section" id="DataManagers">
                        <Row style={{ alignItems: "center" }}>
                            <Col md={6} >
                                <div className="DataManagersImagediv">
                                    <img
                                        src={DataManagersImage}
                                        alt="DataManagersImage pic"
                                        className="img-fluid DataManagersImg"
                                    />
                                </div>
                            </Col>
                            <Col md={6} className="DataManagersConent">
                                <h3 className="DataManagerstitle">Data Managers</h3>
                                <p className="DataManagersdesc">Chief data officers, analytics managers, and systems and platform managers. </p>
                                <div className="DataManagersdiv">
                                    <img
                                        src={Architects}
                                        alt="ArchitectsImage pic"
                                        className="img-fluid DataManagersIcon"
                                    /><span>Develop systems to support research and analytics teams.</span>
                                </div>
                                <div className="DataManagersdiv">
                                    <img
                                        src={Temporary}
                                        alt="TemporaryImage pic"
                                        className="img-fluid DataManagersIcon"
                                    /><span>Manage governance, data quality, and data availability.</span>
                                </div>
                                <div className="DataManagersdiv">
                                    <img
                                        src={multidisciplined}
                                        alt="multidisciplinedImage pic"
                                        className="img-fluid DataManagersIcon"
                                    /><span>Manage data operations and technology strategy.</span>
                                </div>
                                <div className="DataManagersdiv">
                                    <img
                                        src={teammember}
                                        alt="teammemberImage pic"
                                        className="img-fluid DataManagersIcon"
                                    /><span>Manage analytics programs.</span>
                                </div>
                            </Col>

                        </Row>
            </Container>
        </section>
    );
}

export default DataManagers;