import React from "react";
import { Container, Row, Col } from "react-bootstrap";
//import home from "../../../Assets/HomePageImages/Banner.svg";
import CloudConsultantsImage from "../../../Assets/HomePageImages/CloudTechnology/Cloud Consultants.svg";
import Architects from "../../../Assets/HomePageImages/icons/Architects.svg";
import Temporary from "../../../Assets/HomePageImages/icons/Temporary_talent_to_fill_a_gap.svg";
import multidisciplined from "../../../Assets/HomePageImages/icons/A_multi_disciplined.svg";
import teammember from "../../../Assets/HomePageImages/icons/A_new_team_member.svg";
import Technology from "../../../Assets/HomePageImages/icons/On_call_Technology.svg";


function CloudConsultants() {
    return (
        <section>
            <Container fluid="md" className="CloudConsultants-section" id="CloudConsultants">
                <Row className="Hor-Center  py-5">
                    <Col md={12}className="px-md-0" >
                        <Row style={{ alignItems: "center" }}>
                            <Col md={6} className="CloudConsultantsConent px-md-0 order-md-first order-sm-last order-last">
                                <h3 className="CloudConsultantstitle">Cloud Consultants </h3>
                                <p className="CloudConsultantsdesc">A cloud consultant can have many varied roles. We help you choose your consultants based on your needs.</p>
                                <p className="CloudConsultantsdesc">The purposes of cloud consulting are to enable you to:</p>
                                <div className="CloudConsultantsdiv">
                                    <img
                                        src={Architects}
                                        alt="ArchitectsImage pic"
                                        className="img-fluid CloudConsultantsIcon"
                                    /><span>Analyze your business to help you choose the right solutions. </span>
                                </div>
                                <div className="CloudConsultantsdiv">
                                    <img
                                        src={Temporary}
                                        alt="TemporaryImage pic"
                                        className="img-fluid CloudConsultantsIcon"
                                    /><span>Implement a cloud solution and adapt it to your specific requirements. </span>
                                </div>
                                <div className="CloudConsultantsdiv">
                                    <img
                                        src={multidisciplined}
                                        alt="multidisciplinedImage pic"
                                        className="img-fluid CloudConsultantsIcon"
                                    /><span>Design migration policies and how you will use cloud services. </span>
                                </div>
                                <div className="CloudConsultantsdiv">
                                    <img
                                        src={teammember}
                                        alt="teammemberImage pic"
                                        className="img-fluid CloudConsultantsIcon"
                                    /><span>Assist you in designing your cloud architecture.</span>
                                </div>
                                <div className="CloudConsultantsdiv">
                                    <img
                                        src={Technology}
                                        alt="TechnologyImage pic"
                                        className="img-fluid CloudConsultantsIcon"
                                    /><span>Maintaining and facilitating the use of services.</span>
                                </div>


                            </Col>
                            <Col md={6} className="px-md-0 order-md-last order-sm-last order-first">
                                <div className="CloudConsultantsImagediv">
                                    <img
                                        src={CloudConsultantsImage}
                                        alt="CloudConsultantsImage pic"
                                        className="img-fluid CloudConsultantsImg"
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default CloudConsultants;