import React from "react";
import { Container, Row, Col } from "react-bootstrap";
//import home from "../../Assets/HomePageImages/Banner.svg";
import DayforceIntegrationsTabImage from "../../../../Assets/Ceridian/Dayforce_Integrations_Image.svg";
import Architects from "../../../../Assets/HomePageImages/ceradian/Connect to providers.svg";
import Temporary from "../../../../Assets/HomePageImages/ceradian/Mold your data.svg";
import multidisciplined from "../../../../Assets/HomePageImages/ceradian/Maintain data.svg";




function DayforceIntegrations() {
    return (
        <section>
            <Container fluid='md' className="FeaturedSpecializationTab-section" id="FeaturedSpecializationTab">
                <Row className="Hor-Center">
                    <Col md={12} style={{padding:0}}>
                        <Row className="justify-content-center">
                            <Col md={7} className="FeaturedSpecializationTabConent">
                               
                                <p className="FeaturedSpecializationTabdesc">The Dayforce Connector gives you a REST API to store your data and retrieve it on demand. You must set Connector up, which requires a Dayforce Developer Account. </p>
                                <p className="FeaturedSpecializationTabdesc">SourceForge, the open-source provider, has 46 connectors for Dayforce. </p>
                                <p className="FeaturedSpecializationTabdesc">All you need is the right talent. </p>
                                <p className="FeaturedSpecializationTabdesc">Turn to us for Dayforce developers and integration specialists who can connect to any source you want to use. </p>
                               
                                <div className="FeaturedSpecializationTabdiv">
                                    <img
                                        src={Architects}
                                        alt="ArchitectsImage pic"
                                        className="img-fluid FeaturedSpecializationTabIcon"
                                    /><span>Connect to providers like Salesforce, Microsoft Dynamics 365, learning platforms, or any microapp.  </span>
                                </div>
                                <div className="FeaturedSpecializationTabdiv">
                                    <img
                                        src={Temporary}
                                        alt="TemporaryImage pic"
                                        className="img-fluid FeaturedSpecializationTabIcon"
                                    /><span>Mold your data streams into a cohesive, organization-wide plan. </span>
                                </div>
                                <div className="FeaturedSpecializationTabdiv">
                                    <img
                                        src={multidisciplined}
                                        alt="multidisciplinedImage pic"
                                        className="img-fluid FeaturedSpecializationTabIcon"
                                    /><span>Maintain data security and privacy. </span>
                                </div>

                            </Col>
                            <Col md={5} >
                                <div className="FeaturedSpecializationTabImagediv">
                                    <img
                                        src={DayforceIntegrationsTabImage}
                                        alt="FeaturedSpecializationTabImage pic"
                                        className="img-fluid FeaturedSpecializationTabImg"
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default DayforceIntegrations;