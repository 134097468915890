import React from "react";
import './EnterpriseAnalytics.css';

import { Container, Row, Col } from "react-bootstrap";

import BannerImage from "../../../Assets/HomePageImages/EnterpriseAnalytics/EnterpriseAnalyticsBannerImage.svg";
import ClientSide from "../../../Assets/ceridianBannerIcons/Client_Side.svg";
import Support from "../../../Assets/ceridianBannerIcons/Support_and_Maintenance.svg";
import Reporting from "../../../Assets/ceridianBannerIcons/Reporting_and_Analytics.svg";
import Dayforce from "../../../Assets/ceridianBannerIcons/Dayforce_Optimization.svg";
import Employersandjobseakers from "../../Home/EmployersandJobSeakers";
import Jobseekers from "./JobSeekers";
import Employers from "./Employers";
import DataScientists from "./DataScientists";
import DataAnalysts from "./DataAnalysts";
import DataManagers from "./DataManagers";
import DataEngineers from "./DataEngineers";
import Platforms from "./Platforms";
import Specializations from "./Specializations";
import Blogs from "../../Home/Blogs";
// import Tabs from "./Accordian";

import SpacilizedTalent from "../../Home/MoreQuestions"
import AdditionalInformation from "../../Home/AdditionalInformation";

const data = [
    
    {
        id: 1, name: <div><div className="Navtab"></div><div className="Navtab-Content">For Employers</div></div>, content: () => <Employers />, state: "active"
    },{
        id: 2, name: <div><div className="Navtab"></div><div className="Navtab-Content">For Consultants</div></div>, content: () => <Jobseekers />, state: "inactive"
    }
    
];
 
function EnterpriseAnalytics() {
    return (
        <section>
            <Container fluid className="home-section  FSPage" id="EnterpriseAnalytics">

                <Container fluid className="home-content">
               
                            <Col md={12} className="BannerBlock px-md-0">
                          
                            <Container fluid='md'>
                                <Row  className="BannerConent align-items-center py-md-5" >
                                    <Col md={6} sm={12} xs={12} className="BannerContext pt-4 px-md-0">
                                        <h1 className="Bannertitle">Data Analytics Talent and Staffing</h1>
                                        <p className="Bannerdesc">Transforming your data into business intelligence</p>


                                        <div className="BannerIcondiv">
                                            <span className="BannerIcon">
                                                <img
                                                    src={ClientSide}
                                                    alt="ArchitectsImage pic"
                                                    className="img-fluid EmployersIcon"
                                                />
                                            </span>
                                            <span className="Bannericondesc">Data Scientists</span>
                                        </div>
                                        <div className="BannerIcondiv">
                                            <span className="BannerIcon">
                                                <img
                                                    src={Support}
                                                    alt="TemporaryImage pic"
                                                    className="img-fluid EmployersIcon"
                                                />

                                            </span><span className="Bannericondesc">Data engineers</span>
                                        </div>
                                        <div className="BannerIcondiv">
                                            <span className="BannerIcon">
                                                <img
                                                    src={Reporting}
                                                    alt="multidisciplinedImage pic"
                                                    className="img-fluid EmployersIcon"
                                                />
                                            </span><span className="Bannericondesc">Data Managers</span>
                                        </div>
                                        <div className="BannerIcondiv">
                                            <span className="BannerIcon">
                                                <img
                                                    src={Dayforce}
                                                    alt="teammemberImage pic"
                                                    className="img-fluid EmployersIcon"
                                                /></span><span className="Bannericondesc">Data Analysts</span>
                                        </div>
                                        <div className="BannerIcondiv">
                                            <span className="BannerIcon">
                                                <img
                                                    src={Dayforce}
                                                    alt="teammemberImage pic"
                                                    className="img-fluid EmployersIcon"
                                                /></span><span className="Bannericondesc">Industry and Function Specialists</span>
                                        </div>


                                        <Col style={{ marginTop: "3%", lineHeight: "0.7" }}>
                                            <Row>
                                            <Col md={6} sm={12} xs={12}>
                                                <a
                                                    href="/"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    className="BannerLink"

                                                >
                                                    Start Here
                                                </a>
                                            </Col>

                                            <Col md={6} sm={6} xs={6}>
                                                <a
                                                    href="/"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    className="BannerLink"

                                                >
                                                    Join Our Network
                                                </a>
                                            </Col>
                                        </Row></Col>


                                    </Col>
                                    <Col md={6} sm={12} xs={12} className="BannerImage px-md-0">
                                    <div className="svg-box">
                                            <img
                                                src={BannerImage}
                                                alt="home pic"
                                                className="img-fluid BannerImage1"
                                            />
                                            <React.Fragment className="circle"><circle fill="#EDF0F7" cx="40" cy="40" r="40" />
                                            <p>
                                                <path fill="#FFFFFF" d="M40,13.9c1,1.3,1.9,2.5,2.8,3.7c3,4.1,5.7,8.3,8.1,12.7c2,3.6,3.8,7.3,5,11.3c0.6,1.9,1.1,3.8,1.2,5.8
                                                      c0.4,4.7-1,9-4,12.6c-2.8,3.3-6.4,5.4-10.8,5.9c-6.8,0.8-12.3-1.6-16.4-7.1c-1.8-2.4-2.8-5.2-3.1-8.2c-0.2-2.3,0-4.5,0.5-6.7
                                                     c0.8-3.4,2.1-6.6,3.7-9.7c3.3-6.7,7.4-13,11.9-19C39.3,14.8,39.6,14.4,40,13.9z M32.3,34.5C32.3,34.5,32.3,34.5,32.3,34.5
                                                     c-0.1,0.1-0.1,0.1-0.2,0.2c-1.4,2.6-2.7,5.4-3.5,8.2c-0.5,1.8-1,3.6-1,5.4c0,2.6,0.8,5,2.1,7.3c0.8,1.3,1.9,2.2,3.4,2.6
                                                      c1.4,0.4,2.7-0.1,3.4-1.2c0.3-0.5,0.4-0.9,0.3-1.5c-0.1-0.6-0.4-1.1-0.8-1.6c-1.4-1.8-2.6-3.8-3.4-6c-1.4-3.8-1.4-7.7-0.7-11.6
                                                      C32,35.7,32.1,35.1,32.3,34.5z" />
                                            </p>
                                            <div className="circle delay1"></div>
                                            <div className="circle delay2"></div>
                                            <div className="circle delay3"></div>
                                            </React.Fragment> 
                                        </div>
                                    </Col>

                                </Row>
                                </Container>
                            </Col>
                            
                    <Employersandjobseakers data={data}/>   
                    <DataScientists />
                    <DataEngineers />
                    <DataManagers />
                    <DataAnalysts />
                    <Specializations />
                    <Platforms />
                    <Blogs />
                    <SpacilizedTalent />
                    <AdditionalInformation />
                </Container>
            </Container>

        </section >
    );

}


export default EnterpriseAnalytics;
