import React from "react";
import { Container, Row, Col } from "react-bootstrap";
//import home from "../../Assets/HomePageImages/Banner.svg";
import DayforceTalentManagementTabImage from "../../../../Assets/Ceridian/Dayforce_Talent_Management_Image.svg";
import Architects from "../../../../Assets/HomePageImages/ceradian/Recruiting and onboarding.svg";
import Temporary from "../../../../Assets/HomePageImages/ceradian/Compensation specialists.svg";
import multidisciplined from "../../../../Assets/HomePageImages/ceradian/Succession and.svg";




function DayforceTalentManagement() {
    return (
        <section>
            <Container fluid="md" className="FeaturedSpecializationTab-section" id="FeaturedSpecializationTab">
                <Row className="Hor-Center">
                    <Col md={12} style={{padding:0}} >
                        <Row >
                            <Col md={7} className="FeaturedSpecializationTabConent">
                                
                                <p className="FeaturedSpecializationTabdesc">Maintaining smooth-running, well-integrated talent management shouldn’t be a heavy burden. </p>
                                <p className="FeaturedSpecializationTabdesc">When you suddenly have an administrative gap, you don’t need to scramble to find the right person to step in. </p>
                                <p className="FeaturedSpecializationTabdesc">Our specialists have the skills and certifications to support your people from the first recruiting contact to retirement and beyond: </p>
                               
                                <div className="FeaturedSpecializationTabdiv">
                                    <img
                                        src={Architects}
                                        alt="ArchitectsImage pic"
                                        className="img-fluid FeaturedSpecializationTabIcon"
                                    /><span>Recruiting and onboarding specialists to enhance your candidate experience. </span>
                                </div>
                                <div className="FeaturedSpecializationTabdiv">
                                    <img
                                        src={Temporary}
                                        alt="TemporaryImage pic"
                                        className="img-fluid FeaturedSpecializationTabIcon"
                                    /><span>Compensation specialists to help make manage pay equity and mitigate flight risk. </span>
                                </div>
                                <div className="FeaturedSpecializationTabdiv">
                                    <img
                                        src={multidisciplined}
                                        alt="multidisciplinedImage pic"
                                        className="img-fluid FeaturedSpecializationTabIcon"
                                    /><span>Succession and development consultants who can adapt your requirements to ensure business continuity and help you retain your potential leaders.</span>
                                </div>

                            </Col>
                            <Col md={5} >
                                <div className="FeaturedSpecializationTabImagediv">
                                    <img
                                        src={DayforceTalentManagementTabImage}
                                        alt="FeaturedSpecializationTabImage pic"
                                        className="img-fluid FeaturedSpecializationTabImg"
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default DayforceTalentManagement;