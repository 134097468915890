import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import OurGuidingPrinciplesImage from "../../Assets/AboutUs/Our Guiding Principles.svg";



function OurGuidingPrinciples() {
    return (
        <section>
            <Container fluid="md" className="OurGuidingPrinciples-section" id="OurGuidingPrinciples">
                <Row className="Hor-Center">
                    <Col md={12} className='px-md-0'>
                        <Row style={{ alignItems: "center" }} >
                            <Col md={6} className='px-md-0' >
                                <div className="OurGuidingPrinciplesImagediv">
                                    <img
                                        src={OurGuidingPrinciplesImage}
                                        alt="OurGuidingPrinciplesImage pic"
                                        className="img-fluid OurGuidingPrinciplesImg"
                                    />
                                </div>
                            </Col>
                            <Col md={6} className="OurGuidingPrinciplesConent px-md-0">
                                <h3 className="OurGuidingPrinciplestitle">Our Guiding Principles </h3>
                                <p className="OurGuidingPrinciplesdesc">We strive to earn the trust of our customers, their people, and our partners by upholding the highest standards of governance and ethics. </p>
                                <p className="OurGuidingPrinciplesdesc">A diverse, inclusive, and equitable organization creates innovation, creativity, and productivity. We are responsible for creating an environment where people feel safe and valued. </p>
                                <p className="OurGuidingPrinciplesdesc">We believe that technology should be employed for the good of the people we touch, our communities, and our world. </p>

                            </Col>

                        </Row>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default OurGuidingPrinciples;