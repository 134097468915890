import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Activity1 from "../../Assets/ServicesAssets/Contractors and Freelancers.svg";
import Activity2 from "../../Assets/ServicesAssets/Direct Hire.svg";
import Activity3 from "../../Assets/ServicesAssets/Managed Project Teams.svg";
import Activity4 from "../../Assets/ServicesAssets/Temporary Staff.svg";



function RightFit() {
    
    return (
        <section>
            <Container fluid="md" className="RightFit-section" id="RightFit">
                <Row style={{ justifyContent: "center",padding:0 }}>
                    <Col md={12} className="RightFitConent" style={{ justifyContent: "center", padding:0 }}>
                        <h3 className="RightFittitle">The Right Fit</h3>
                        <p className="RightFitdesc">Your consultan will help you the most cost-effective employment type for your current needs.</p>
                        <p className="RightFitdesc">Beyond cost, you will want to consider the engagement duration, skill set requirements, and the degree of control that fits your situation.</p>
                        <p className="RightFitdesc">Let's work together to assess your needs and create a solution that fits your current and future needs</p>
                        <div className="RightFitLinkdiv">
                        <a
                            href="/"
                            target="_blank"
                            rel="noreferrer"
                            className="RightFitLink"
                        >
                            Try With a Consultant Now
                        </a></div>
                        <Row className="justify-content-between">

                            <Col md={5} sm={12} xs={12} className="RightFitItem">
                                <Col md={12} className="RightFitContent">
                                    <img
                                        src={Activity1}
                                        alt="home pic"
                                        className="img-fluid RightFitimg"
                                    />
                                    <Col md={12} className="RightFitData">
                                        <h4 className="RightFitTitle">Contractors and Freelancers</h4>
                                        <p className="RightFitcarddesc">Remote or onsite business and functional consultants, architects, administrators, engineers, and administrators to augment your team.</p>
                                        
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <div className="RightFitLink"> <a
                                                href="/"
                                                target="_blank"
                                                rel="noreferrer"
                                                className="RightFitLink"
                                            >
                                                Get Started Now
                                            </a>
                                            </div>
                                        </div>
                                    </Col>
                                </Col>
                            </Col>
                            <Col md={5} sm={12} xs={12} className="RightFitItem">
                                <Col md={12} className="RightFitContent">
                                    <img
                                        src={Activity2}
                                        alt="home pic"
                                        className="img-fluid RightFitimg"
                                    />
                                    <Col md={12} className="RightFitData" >
                                    <h4 className="RightFitTitle">Direct Hire</h4>
                                        <p className="RightFitcarddesc">let our rigorous vetting process help you find the right candidate. Get the right fit with verified skills for your technology teams.</p>
                                        
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <div className="RightFitLink"> <a
                                                href="/"
                                                target="_blank"
                                                rel="noreferrer"
                                                className="RightFitLink"
                                            >
                                                Get Started Now
                                            </a>
                                            </div>
                                        </div>
                                    </Col>
                                </Col>
                            </Col>
                            <Col md={5} sm={12} xs={12} className="RightFitItem">
                                <Col md={12} className="RightFitContent">
                                    <img
                                        src={Activity3}
                                        alt="home pic"
                                        className="img-fluid RightFitimg"
                                    />
                                    <Col md={12} className="RightFitData">
                                    <h4 className="RightFitTitle">Managed Project Teams</h4>
                                        <p className="RightFitcarddesc">A complete, expert-led staff for your entire project removes all your administration worries</p>
                                        
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <div className="RightFitLink"> <a
                                                href="/"
                                                target="_blank"
                                                rel="noreferrer"
                                                className="RightFitLink"
                                            >
                                                Get Started Now
                                            </a>
                                            </div>
                                        </div>
                                    </Col>
                                </Col>
                            </Col>
                            <Col md={5} sm={12} xs={12} className="RightFitItem">
                                <Col md={12} className="RightFitContent">
                                    <img
                                        src={Activity4}
                                        alt="home pic"
                                        className="img-fluid RightFitimg"
                                    />
                                    <Col md={12} className="RightFitData">
                                    <h4 className="RightFitTitle">Temporary Staff</h4>
                                        <p className="RightFitcarddesc">We help you find the people you need for your short-term, seasonal, or emergency staffing.</p>
                                        
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <div className="RightFitLink"> <a
                                                href="/"
                                                target="_blank"
                                                rel="noreferrer"
                                                className="RightFitLink"
                                            >
                                                Get Started Now
                                            </a>
                                            </div>
                                        </div>
                                    </Col>
                                </Col>
                            </Col>
                           


                        </Row>
                       
                    </Col>
                </Row>
                
            </Container>
           
        </section >
       
    );
}

export default RightFit;


