import React from "react";
import { Container, Row, Col } from "react-bootstrap";
//import home from "../../../Assets/HomePageImages/Banner.svg";
import CloudDevelopersImage from "../../../Assets/HomePageImages/CloudTechnology/Cloud Developers.svg";
import Architects from "../../../Assets/HomePageImages/icons/Architects.svg";
import Temporary from "../../../Assets/HomePageImages/icons/Temporary_talent_to_fill_a_gap.svg";
import multidisciplined from "../../../Assets/HomePageImages/icons/A_multi_disciplined.svg";
import teammember from "../../../Assets/HomePageImages/icons/A_new_team_member.svg";




function CloudDevelopers() {
    return (
        <section>
            <Container fluid='md' className="CloudDevelopers-section" id="CloudDevelopers">
                <Row className="Hor-Center  py-3">
                    <Col md={12} className="px-md-0">
                        <Row style={{ alignItems: "center" }}>
                            <Col md={6} className="px-md-0">
                                <div className="CloudDevelopersImagediv">
                                    <img
                                        src={CloudDevelopersImage}
                                        alt="CloudDevelopersImage pic"
                                        className="img-fluid CloudDevelopersImg"
                                    />
                                </div>
                            </Col>
                            <Col md={6} className="CloudDevelopersConent px-md-0" >
                                <h3 className="CloudDeveloperstitle">Cloud Developers</h3>
                                <p className="CloudDevelopersdesc">Developers will have skills specifically related to functioning in the cloud. </p>
                                <div className="CloudDevelopersdiv">
                                    <img
                                        src={Architects}
                                        alt="ArchitectsImage pic"
                                        className="img-fluid CloudDevelopersIcon"
                                    /><span>Create functions, applications, and databases that run in the cloud. </span>
                                </div>
                                <div className="CloudDevelopersdiv">
                                    <img
                                        src={Temporary}
                                        alt="TemporaryImage pic"
                                        className="img-fluid CloudDevelopersIcon"
                                    /><span>Manage application and infrastructure performance. </span>
                                </div>
                                <div className="CloudDevelopersdiv">
                                    <img
                                        src={multidisciplined}
                                        alt="multidisciplinedImage pic"
                                        className="img-fluid CloudDevelopersIcon"
                                    /><span>Develop new applications and methods for the cloud.</span>
                                </div>
                                <div className="CloudDevelopersdiv">
                                    <img
                                        src={teammember}
                                        alt="teammemberImage pic"
                                        className="img-fluid CloudDevelopersIcon"
                                    /><span>Manage infrastructure and security.</span>
                                </div>

                            </Col>

                        </Row>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default CloudDevelopers;