import React from "react";
import { Container, Row, Col } from "react-bootstrap";
function Specializations() {
    return (
        <section>
            <Container fluid className="Specializations-section" id="Specializations">
                <Container fluid='md'>
                <Row>
                    <Col md={12} className="Specializations">
                        <h3 className="Specializationstitle">Specializations</h3>
                        <Row style={{ justifyContent: "center" }}>
                            <Col md={12} style={{padding:0}}>
                                <p>Cloud deployments encompass the entire range of technology specializations. It will eventually touch every aspect of your tech stack. </p>
                                <p>With no-cost consulting services to discover your specific
                                    needs, we match you to qualified specialists who can work
                                    with your team, on-premises, or remotely. </p>
                                <Row style={{ justifyContent: "center" }}>
                                    <Col md={12} style={{padding:0}}>
                                        <Row >
                                            <Col md={4} sm={6} className="Specializationscard" style={{padding:0}}>
                                                <p>Agile Development</p>
                                                <p>Analytics</p>
                                                <p>Architecture</p>
                                                <p>Artificial Intelligence</p>
                                                <p>Blockchain</p>
                                                <p>Cloud Migration</p>
                                                <p>Cybersecurity</p>
                                                <p>Data Engineering</p>
                                            </Col>
                                            <Col></Col>
                                            <Col md={4} sm={6} className="Specializationscard" style={{padding:0}}>
                                                <p> Design</p>
                                                <p> DevOps</p>
                                                <p> Engineering</p>
                                                <p> Machine Learning</p>
                                                <p> Mobile</p>
                                                <p> Multi-Cloud</p>
                                                <p> UI/UX</p>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>

                        </Row>


                    </Col>
                </Row>
                </Container>
            </Container >
        </section >
    );
}

export default Specializations;
