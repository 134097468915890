import React from "react";
import { Container, Row, Col } from "react-bootstrap";

//import home from "../../../Assets/HomePageImages/Banner.svg";
import Ceridian from "../../../Assets/HomePageImages/vendors/Ceridian.svg";
import Cornerstone from "../../../Assets/HomePageImages/vendors/Cornerstone.svg";
import OracleHCM from "../../../Assets/HomePageImages/vendors/Oracle_HCM.svg";



function Platforms() {
    return (
        <section>
            <Container fluid='md' className="Platforms-section" id="Platforms">
                <Row>
                    <Col md={12} className="Platforms" style={{padding:0}}>
                        <h3 className="Platformstitle">Platforms</h3>
                        <Row style={{ justifyContent: "center" }}>
                            <Col md={12} style={{padding:0}}>
                                <Row >
                                    <Col md={4} sm={6} className="Platformscard" >
                                        <a href="https://aws.amazon.com/free/">
                                            <Col md={12} className="PlatformsContent">
                                                <img
                                                    src={Ceridian}
                                                    alt="home pic"
                                                    className="img-fluid Blogimg"
                                                />

                                            </Col>
                                        </a>

                                    </Col>
                                    <Col md={4} sm={6} className="Platformscard" >
                                        <a href="https://agio.com/services/intelligent-it/system-management/managed-azure/">
                                            <Col md={12} className="PlatformsContent">
                                                <img
                                                    src={Cornerstone}
                                                    alt="home pic"
                                                    className="img-fluid Blogimg"
                                                />

                                            </Col>
                                        </a>
                                    </Col>
                                    <Col md={4} sm={6} className="Platformscard">
                                        <a href="https://cloud.google.com/gcp/">
                                            <Col md={12} className="PlatformsContent">
                                                <img
                                                    src={OracleHCM}
                                                    alt="home pic"
                                                    className="img-fluid Blogimg"
                                                />

                                            </Col>
                                        </a>
                                    </Col>

                                </Row>
                            </Col>

                        </Row>


                    </Col>
                </Row>
            </Container>
        </section >
    );
}

export default Platforms;