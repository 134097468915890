import React from "react";
import { Container, Row, Col } from "react-bootstrap";
//import home from "../../Assets/HomePageImages/Banner.svg";
import FeaturedSpecializationTabImage from "../../../../Assets/cornerstone/TM Image.svg";
import Architects from "../../../../Assets/HomePageImages/icons/Architects.svg";
import Temporary from "../../../../Assets/HomePageImages/icons/Temporary_talent_to_fill_a_gap.svg";
import multidisciplined from "../../../../Assets/HomePageImages/icons/A_multi_disciplined.svg";




function TalentManagement() {
    return (
        <section>
            <Container fluid className="FeaturedSpecializationTab-section" id="FeaturedSpecializationTab">
                <Row className="Hor-Center">
                    <Col md={12} style={{padding:0}}>
                        <Row className="justify-content-center">
                            <Col md={7} className="FeaturedSpecializationTabConent">
                               
                                <p className="FeaturedSpecializationTabdesc">Maintaining smooth-running, well-integrated talent management shouldn’t be a heavy burden.  </p>
                                <p className="FeaturedSpecializationTabdesc">When you suddenly have an administrative gap, you shouldn’t need to scramble to find the right person to step in. </p>
                                <p className="FeaturedSpecializationTabdesc">Our specialists have the skills and certifications to support your people from the first recruiting contact to retirement and beyond: </p>

                                <div className="FeaturedSpecializationTabdiv">
                                    <img
                                        src={Architects}
                                        alt="ArchitectsImage pic"
                                        className="img-fluid FeaturedSpecializationTabIcon"
                                    /><span>Recruiting and onboarding specialists to enhance your candidate experience. </span>
                                </div>
                                <div className="FeaturedSpecializationTabdiv">
                                    <img
                                        src={Temporary}
                                        alt="TemporaryImage pic"
                                        className="img-fluid FeaturedSpecializationTabIcon"
                                    /><span>Compensation specialists to help make manage pay equity and flight risk. </span>
                                </div>
                                <div className="FeaturedSpecializationTabdiv">
                                    <img
                                        src={multidisciplined}
                                        alt="multidisciplinedImage pic"
                                        className="img-fluid FeaturedSpecializationTabIcon"
                                    /><span>Benefits specialists that help you make your enrollments a success. </span>
                                </div>

                            </Col>
                            <Col md={5} >
                                <div className="FeaturedSpecializationTabImagediv">
                                    <img
                                        src={FeaturedSpecializationTabImage}
                                        alt="FeaturedSpecializationTabImage pic"
                                        className="img-fluid FeaturedSpecializationTabImg"
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default TalentManagement;