import React from "react";
import { Container, Row, Col } from "react-bootstrap";
//import home from "../../../Assets/HomePageImages/Banner.svg";
import ManagersAndBehavioralAnalystsImage from "../../../Assets/HomePageImages/PeopleAnalytics/Managers and Behavioral Analysts.svg";
import Architects from "../../../Assets/HomePageImages/icons/Architects.svg";
import Temporary from "../../../Assets/HomePageImages/icons/Temporary_talent_to_fill_a_gap.svg";
import multidisciplined from "../../../Assets/HomePageImages/icons/A_multi_disciplined.svg";




function ManagersAndBehavioralAnalysts() {
    return (
        <section>
            <Container fluid='md' className="ManagersAndBehavioralAnalysts-section" id="ManagersAndBehavioralAnalysts">
                <Row className="Hor-Center">
                    <Col md={12} className="px-md-0">
                        <Row style={{ alignItems: "center" }}>

                            <Col md={6} className="ManagersAndBehavioralAnalystsConent px-md-0 order-md-first order-sm-last order-last">
                                <h3 className="ManagersAndBehavioralAnalyststitle">Managers and Behavioral Analysts </h3>
                                <p className="ManagersAndBehavioralAnalystsdesc">Leaders and influencers who guide analytical teams to measure and influence the impact of human capital. </p>

                                <div className="ManagersAndBehavioralAnalystsdiv">
                                    <img
                                        src={Architects}
                                        alt="ArchitectsImage pic"
                                        className="img-fluid ManagersAndBehavioralAnalystsIcon"
                                    /><span>Lead teams to address business problems related to human behavior.</span>
                                </div>
                                <div className="ManagersAndBehavioralAnalystsdiv">
                                    <img
                                        src={Temporary}
                                        alt="TemporaryImage pic"
                                        className="img-fluid ManagersAndBehavioralAnalystsIcon"
                                    /><span>Design interventions based on data research. </span>
                                </div>
                                <div className="ManagersAndBehavioralAnalystsdiv">
                                    <img
                                        src={multidisciplined}
                                        alt="multidisciplinedImage pic"
                                        className="img-fluid ManagersAndBehavioralAnalystsIcon"
                                    /><span>Working through management, develop and foster a data-driven culture</span>
                                </div>



                            </Col>
                            <Col md={6} className="px-md-0 order-md-last order-sm-last order-first">
                                <div className="ManagersAndBehavioralAnalystsImagediv">
                                    <img
                                        src={ManagersAndBehavioralAnalystsImage}
                                        alt="ManagersAndBehavioralAnalystsImage pic"
                                        className="img-fluid ManagersAndBehavioralAnalystsImg"
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default ManagersAndBehavioralAnalysts;