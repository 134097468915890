import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import BannerImage from "../../Assets/ServicesAssets/ServiceBannerImage.svg";
import Processwork from "./Processwork";
import DeliversValue from "./DeliversValue";
import TrustedClients from "./TrustedBy";
import RightFit from "./RightFit";
import MoreQuestions from "./MoreQuestions";
import VenderSpecilizations from "./ServiceCarousel"
import logoImage from "../../Assets/HomePageImages/Transparent_PhenomStaff_Logo.svg";
import AdditionalInformation from "../Home/AdditionalInformation";


function Home() {
    return (
        <section>
            <Container fluid className="home-section" id="services">
                <Container fluid className="home-content">
                    <Row>               
                        <Col md={12} className="BannerBlock px-md-0">
                               <Container fluid='md'>
                            <Row className="justify-content-between ">
                                <Col md={12} className="BannerConent px-md-0">
                                <span className="BGlogodiv">
                                                <img
                                                    src={logoImage}
                                                    alt="ArchitectsImage pic"
                                                    className="img-fluid BGlogo"
                                                /> </span>
                                                <Row className="align-items-center">
                                    <Col md={6} sm={12} xs={12} className="BannerContext px-md-0">
                                        <h1 className="Bannertitle">The Right Talent at the Right Time</h1>
                                        <p className="Bannerdesc">For any need-from a few hours to forever</p>  
                                        <Col style={{ marginTop: "3%", lineHeight: "0.7" }}> 
                                        <a href="/"
                                            target="blank"
                                            rel="noreferrer"
                                            className="BannerLink">
                                           Book an Appointment
                                        </a></Col>


                                    </Col>
                                    <Col md={6} sm={12} xs={12} className="BannerImage">
                                        <img
                                            src={BannerImage}
                                            alt="home pic"
                                            className="img-fluid BannerImage1"
                                        />
                                    </Col>
                                    </Row>
                                </Col>
                            </Row>
                                </Container>
                            </Col>
                       
                    </Row>
                    <RightFit />
                    <Processwork />
                    <DeliversValue />
                    <TrustedClients />
                    <MoreQuestions />
                    <VenderSpecilizations />                   
                    <AdditionalInformation />
                </Container>
            </Container>

        </section >
    );
}


export default Home;
