import React from "react";
import { Container, Row, Col } from "react-bootstrap";
//import home from "../../../Assets/HomePageImages/Banner.svg";
import AnalystsAndVisualizationSpecialistsImage from "../../../Assets/HomePageImages/PeopleAnalytics/AnalystsAndVisualizationSpecialists.svg";
import Architects from "../../../Assets/HomePageImages/icons/Architects.svg";
import Temporary from "../../../Assets/HomePageImages/icons/Temporary_talent_to_fill_a_gap.svg";
import multidisciplined from "../../../Assets/HomePageImages/icons/A_multi_disciplined.svg";




function AnalystsAndVisualizationSpecialists() {
    return (
        <section>
            <Container fluid='md' className="AnalystsAndVisualizationSpecialists-section" id="AnalystsAndVisualizationSpecialists">
                <Row className="Hor-Center">
                    <Col md={12} className="px-md-0">
                        <Row style={{ alignItems: "center" }}>
                            <Col md={6} className="px-md-0">
                                <div className="AnalystsAndVisualizationSpecialistsImagediv">
                                    <img
                                        src={AnalystsAndVisualizationSpecialistsImage}
                                        alt="AnalystsAndVisualizationSpecialistsImage pic"
                                        className="img-fluid AnalystsAndVisualizationSpecialistsImg"
                                    />
                                </div>
                            </Col>
                            <Col md={6} className="AnalystsAndVisualizationSpecialistsConent px-md-0">
                                <h3 className="AnalystsAndVisualizationSpecialiststitle">Analysts and Visualization Specialists</h3>
                                <p className="AnalystsAndVisualizationSpecialistsdesc">Working with scientists and engineers, analyze people data to deliver insights to leaders and practitioners. </p>
                                <div className="AnalystsAndVisualizationSpecialistsdiv">
                                    <img
                                        src={Architects}
                                        alt="ArchitectsImage pic"
                                        className="img-fluid AnalystsAndVisualizationSpecialistsIcon"
                                    /><span>Develop and distribute reports and analyses. </span>
                                </div>
                                <div className="AnalystsAndVisualizationSpecialistsdiv">
                                    <img
                                        src={Temporary}
                                        alt="TemporaryImage pic"
                                        className="img-fluid AnalystsAndVisualizationSpecialistsIcon"
                                    /><span>Create dashboards and visualization and inspire and motivate action. </span>
                                </div>
                                <div className="AnalystsAndVisualizationSpecialistsdiv">
                                    <img
                                        src={multidisciplined}
                                        alt="multidisciplinedImage pic"
                                        className="img-fluid AnalystsAndVisualizationSpecialistsIcon"
                                    /><span>Maintain reporting platforms and data tools. </span>
                                </div>


                            </Col>

                        </Row>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default AnalystsAndVisualizationSpecialists;