import React from "react";
import { Container, Row, Col } from "react-bootstrap";
//import home from "../../Assets/HomePageImages/Banner.svg";
import DayforceReportingandAnalyticsTabImage from "../../../../Assets/HomePageImages/servicenow/Enterprise Onboarding and Transitions image.svg";
import Architects from "../../../../Assets/HomePageImages/servicenow/process automation.svg";
import Temporary from "../../../../Assets/HomePageImages/servicenow/employee portals.svg";
import multidisciplined from "../../../../Assets/HomePageImages/servicenow/case and.svg";



function HRServiceDelivery() {
    return (
        <section>
            <Container fluid='md' className="FeaturedSpecializationTab-section" id="FeaturedSpecializationTab">
                <Row className="Hor-Center">
                    <Col md={12} style={{padding:0}}>
                        <Row className="justify-content-between">
                            <Col md={7} className="FeaturedSpecializationTabConent">
                                
                                <p className="FeaturedSpecializationTabdesc">We offer client-side consulting services to help you make the most of ServiceNow’s ability to mold complex processes into a simplified experience. </p>
                                <p className="FeaturedSpecializationTabdesc">Onboarding and transitions </p>

                                <div className="FeaturedSpecializationTabdiv">
                                    <img
                                        src={Architects}
                                        alt="ArchitectsImage pic"
                                        className="img-fluid FeaturedSpecializationTabIcon"
                                    /><span>Process automation </span>
                                </div>
                                <div className="FeaturedSpecializationTabdiv">
                                    <img
                                        src={Temporary}
                                        alt="TemporaryImage pic"
                                        className="img-fluid FeaturedSpecializationTabIcon"
                                    /><span>employee portals </span>
                                </div>
                                <div className="FeaturedSpecializationTabdiv">
                                    <img
                                        src={multidisciplined}
                                        alt="multidisciplinedImage pic"
                                        className="img-fluid FeaturedSpecializationTabIcon"
                                    /><span>case and knowledge management </span>
                                </div>



                            </Col>
                            <Col md={5} >
                                <div className="FeaturedSpecializationTabImagediv">
                                    <img
                                        src={DayforceReportingandAnalyticsTabImage}
                                        alt="FeaturedSpecializationTabImage pic"
                                        className="img-fluid FeaturedSpecializationTabImg"
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default HRServiceDelivery;