import * as React from "react";

import { Routes, Route } from 'react-router-dom'


import './App.css';
import './style.css';
import './About-Us.css';
import './Services.css';
import './ContactUs.css'
import GoToTop from './GoToTop.js'


// import './Tabs.css';
import Navbar from "./Components/Navbar";

import About from "./Components/About/About";
import Services from "./Components/Services/Services";
// import ContactUs from "./Components/ContactUs/ContactUs"
import ContactUspage from "./Components/ContactUs/ContactUspage"
import Home from "./Components/Home/Home";
import Footer1 from "./Components/Footer1";
import CeridianPage from "./Components/VendorSpecilizationPages/Ceridian/Ceridian"
import CornerstonePage from "./Components/VendorSpecilizationPages/Cornerstone/Cornerstone"
import OraclePage from "./Components/VendorSpecilizationPages/Oracle/Oracle"
import SalesforcePage from "./Components/VendorSpecilizationPages/Salesforce/Salesforce"
import ServicenowPage from "./Components/VendorSpecilizationPages/Servicenow/Servicenow"
import SumtotalPage from "./Components/VendorSpecilizationPages/Sumtotal/Sumtotal"
import UKGPage from "./Components/VendorSpecilizationPages/UKG/UKG"
import WorkdayPage from "./Components/VendorSpecilizationPages/Workday/Workday"
import Cloud from "./Components/FeaturedSpecilizationPages/Cloud/Cloud"
import Cybersecurity from "./Components/FeaturedSpecilizationPages/Cybersecurity/Cybersecurity"
import DataScience from "./Components/FeaturedSpecilizationPages/DataScience/DataScience"
import DigitalTransformation from "./Components/FeaturedSpecilizationPages/DigitalTransformation/DigitalTransformation"
import EnterpriseAnalytics from "./Components/FeaturedSpecilizationPages/EnterpriseAnalytics/EnterpriseAnalytics"
import PeopleAnalytics from "./Components/FeaturedSpecilizationPages/PeopleAnalytics/PeopleAnalytics"




//import { Routes, Route } from 'react-router-dom'


function App() {
  return (
    <div className="App">

      <Navbar />

      <GoToTop />
      <Routes >


        <Route path="/" element={<Home/>} />
        <Route path="/about-us" element={<About/>} />
        <Route path="/ContactUs" element={<ContactUspage/>} />
        {/* <Route path="/ContactUs" element={<ContactUs/>} /> */}
        <Route path="/services" element={<Services />} />
        <Route path="/Ceridian" element={<CeridianPage />} />
        <Route path="/Cornerstone" element={<CornerstonePage />} />
        <Route path="/Oracle" element={<OraclePage />} />
        <Route path="/Salesforce" element={<SalesforcePage />} />
        <Route path="/Servicenow" element={<ServicenowPage />} />
        <Route path="/Sumtotal" element={<SumtotalPage />} />
        <Route path="/UKG" element={<UKGPage />} />
        <Route path="/Workday" element={<WorkdayPage />} />
        <Route path="/Cloud" element={<Cloud />} />
        <Route path="/Cybersecurity" element={<Cybersecurity />} />
        <Route path="/DataScience" element={<DataScience />} />
        <Route path="/DigitalTransformation" element={<DigitalTransformation />} />
        <Route path="/EnterpriseAnalytics" element={<EnterpriseAnalytics />} />
        <Route path="/PeopleAnalytics" element={<PeopleAnalytics />} />
      </Routes>
      <Footer1 />

    </div>
  );
};



export default App;
