import React from "react";
import { Container, Row, Col } from "react-bootstrap";
//import home from "../../Assets/HomePageImages/Banner.svg";
import DayforcePayrollImage from "../../../../Assets/cornerstone/R abd A Image.svg";
import Architects from "../../../../Assets/HomePageImages/icons/Architects.svg";
import Temporary from "../../../../Assets/HomePageImages/icons/Temporary_talent_to_fill_a_gap.svg";
import multidisciplined from "../../../../Assets/HomePageImages/icons/A_multi_disciplined.svg";
import teammember from "../../../../Assets/HomePageImages/icons/A_new_team_member.svg";



function ReportingandAnalytics() {
    return (
        <section>
            <Container fluid='md' className="FeaturedSpecializationTab-section" id="FeaturedSpecializationTab">
                <Row className="Hor-Center">
                    <Col md={12} style={{padding:0}}>
                        <Row className="justify-content-center">
                            <Col md={7} className="FeaturedSpecializationTabConent">
                               
                                <p className="FeaturedSpecializationTabdesc">Cornerstone provides many reports and analytical insights, but when you need a deeper dive into people analytics or require custom reports and dashboards, we provide professionals to take your reporting and analytics to a higher level. </p>
                               
                                <div className="FeaturedSpecializationTabdiv">
                                    <img
                                        src={Architects}
                                        alt="ArchitectsImage pic"
                                        className="img-fluid FeaturedSpecializationTabIcon"
                                    /><span>Data scientists, engineers, and analysts to build new analytical models to solve your specific business issues. </span>
                                </div>
                                <div className="FeaturedSpecializationTabdiv">
                                    <img
                                        src={Temporary}
                                        alt="TemporaryImage pic"
                                        className="img-fluid FeaturedSpecializationTabIcon"
                                    /><span>Data governance consultants to help you get control of your data. </span>
                                </div>
                                <div className="FeaturedSpecializationTabdiv">
                                    <img
                                        src={multidisciplined}
                                        alt="multidisciplinedImage pic"
                                        className="img-fluid FeaturedSpecializationTabIcon"
                                    /><span>Data visualization experts who present your data in a way that your people understand. </span>
                                </div>
                                <div className="FeaturedSpecializationTabdiv">
                                    <img
                                        src={teammember}
                                        alt="teammemberImage pic"
                                        className="img-fluid FeaturedSpecializationTabIcon"
                                    /><span>Data managers who can oversee part or all of your data and reporting. </span>
                                </div>



                            </Col>
                            <Col md={5} >
                                <div className="FeaturedSpecializationTabImagediv">
                                    <img
                                        src={DayforcePayrollImage}
                                        alt="FeaturedSpecializationTabImage pic"
                                        className="img-fluid FeaturedSpecializationTabImg"/>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container >
        </section >
    );
}

export default ReportingandAnalytics;