import React from "react";
import { Container, Row, Col } from "react-bootstrap";
//import home from "../../../Assets/HomePageImages/Banner.svg";
import CybersecurityImage from "../../../Assets/HomePageImages/DigitalTransformation/Cybersecurity.svg";
import Architects from "../../../Assets/HomePageImages/icons/Architects.svg";
import Temporary from "../../../Assets/HomePageImages/icons/Temporary_talent_to_fill_a_gap.svg";
import multidisciplined from "../../../Assets/HomePageImages/icons/A_multi_disciplined.svg";



function Cybersecurity() {
    return (
        <section>
            <Container fluid='md' className="Cybersecurity-section" id="Cybersecurity">
                <Row className="Hor-Center">
                    <Col md={12} className="px-md-0">
                        <Row style={{ alignItems: "center" }}>
                            <Col md={6} className="px-md-0">
                                <div className="CybersecurityImagediv">
                                    <img
                                        src={CybersecurityImage}
                                        alt="CybersecurityImage pic"
                                        className="img-fluid CybersecurityImg"
                                    />
                                </div>
                            </Col>
                            <Col md={6} className="CybersecurityConent px-md-0">
                                <h3 className="Cybersecuritytitle">Cybersecurity </h3>
                                <p className="Cybersecuritydesc">Advanced data expertise to solve your most pressing security challenges: </p>

                                <div className="Cybersecuritydiv">
                                    <img
                                        src={Architects}
                                        alt="ArchitectsImage pic"
                                        className="img-fluid CybersecurityIcon"
                                    /><span>Design and build security systems and safeguards.</span>
                                </div>
                                <div className="Cybersecuritydiv">
                                    <img
                                        src={Temporary}
                                        alt="TemporaryImage pic"
                                        className="img-fluid CybersecurityIcon"
                                    /><span>Design and implement secure network solutions.</span>
                                </div>
                                <div className="Cybersecuritydiv ">
                                    <img
                                        src={multidisciplined}
                                        alt="multidisciplinedImage pic"
                                        className="img-fluid CybersecurityIcon"
                                    /><span>Identify threats and develop protection measures.</span>
                                </div>
                            </Col>

                        </Row>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default Cybersecurity;