import React from "react";
import { Container, Row, Col } from "react-bootstrap";
//import home from "../../Assets/HomePageImages/Banner.svg";
import DayforceIntegrationsTabImage from "../../../../Assets/Ceridian/Dayforce_Integrations_Image.svg";
import Architects from "../../../../Assets/HomePageImages/icons/Architects.svg";
import Temporary from "../../../../Assets/HomePageImages/icons/Temporary_talent_to_fill_a_gap.svg";
import multidisciplined from "../../../../Assets/HomePageImages/icons/A_multi_disciplined.svg";




function Integrations() {
    return (
        <section>
            <Container fluid='md' className="FeaturedSpecializationTab-section" id="FeaturedSpecializationTab">
                <Row className="Hor-Center">
                    <Col md={12} style={{padding:0}}>
                        <Row className="justify-content-center">
                            <Col md={7} className="FeaturedSpecializationTabConent">
                                
                                <p className="FeaturedSpecializationTabdesc">Cornerstone provides many plug-and-play connectors with Edge Import, a scalable self-service data load platform. It also maintains the Edge Marketplace with partner-built integrations.  </p>
                                <p className="FeaturedSpecializationTabdesc">But if you need to build your own connector, we have ready talent that can manage Edge Import loads and APIs for smooth data flow. </p>
                                <p className="FeaturedSpecializationTabdesc">No matter what platform, application, or microapp you need to connect, we can help you find the right talent to optimize your Cornerstone integrations </p>

                               
                                <div className="FeaturedSpecializationTabdiv">
                                    <img
                                        src={Architects}
                                        alt="ArchitectsImage pic"
                                        className="img-fluid FeaturedSpecializationTabIcon"
                                    /><span>Experts to help you optimize your import loads. </span>
                                </div>
                                <div className="FeaturedSpecializationTabdiv">
                                    <img
                                        src={Temporary}
                                        alt="TemporaryImage pic"
                                        className="img-fluid FeaturedSpecializationTabIcon"
                                    /><span>Content integration experts to give you access to any online learning source. </span>
                                </div>
                                <div className="FeaturedSpecializationTabdiv">
                                    <img
                                        src={multidisciplined}
                                        alt="multidisciplinedImage pic"
                                        className="img-fluid FeaturedSpecializationTabIcon"
                                    /><span>API experts who can help you optimize your data streams.</span>
                                </div>

                            </Col>
                            <Col md={5} >
                                <div className="FeaturedSpecializationTabImagediv">
                                    <img
                                        src={DayforceIntegrationsTabImage}
                                        alt="FeaturedSpecializationTabImage pic"
                                        className="img-fluid FeaturedSpecializationTabImg"
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default Integrations;