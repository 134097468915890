import React from "react";
import { Container, Row, Col } from "react-bootstrap";
//import home from "../../../Assets/HomePageImages/Banner.svg";
import UserExperienceImage from "../../../Assets/HomePageImages/DigitalTransformation/User Expereince.svg";
import Architects from "../../../Assets/HomePageImages/icons/Architects.svg";
import Temporary from "../../../Assets/HomePageImages/icons/Temporary_talent_to_fill_a_gap.svg";
import multidisciplined from "../../../Assets/HomePageImages/icons/A_multi_disciplined.svg";


function UserExperience() {
    return (
        <section>
            <Container fluid='md' className="UserExperience-section" id="UserExperience">
                <Row className="Hor-Center">
                    <Col md={12} className="px-md-0">
                        <Row style={{ alignItems: "center" }} className="justify-content-between">
                            <Col md={6} style={{padding:0}}>
                                <div className="UserExperienceImagediv">
                                    <img
                                        src={UserExperienceImage}
                                        alt="UserExperienceImage pic"
                                        className="img-fluid UserExperienceImg"
                                    />
                                </div>
                            </Col>
                            <Col md={6} className="UserExperienceConent px-md-0">
                                <h3 className="UserExperiencetitle">User Experience </h3>
                                <p className="UserExperiencedesc">People will no longer tolerate an awful user experience. If your employees, customers, and other users hate using it, it’s junk. </p>
                                <div className="UserExperiencediv">
                                    <img
                                        src={Architects}
                                        alt="ArchitectsImage pic"
                                        className="img-fluid UserExperienceIcon"
                                    /><span>Hire talent skilled in learning what people need and want in their work tools. </span>
                                </div>
                                <div className="UserExperiencediv">
                                    <img
                                        src={Temporary}
                                        alt="TemporaryImage pic"
                                        className="img-fluid UserExperienceIcon"
                                    /><span>Enjoy the long-term benefits of cloud usability and hybrid work environments. </span>
                                </div>
                                <div className="UserExperiencediv">
                                    <img
                                        src={multidisciplined}
                                        alt="multidisciplinedImage pic"
                                        className="img-fluid UserExperienceIcon"
                                    /><span>Make it easier to give your people the tools they need when they need them. </span>
                                </div>
                            </Col>

                        </Row>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default UserExperience;