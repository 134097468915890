import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import logoImage from "../../Assets/HomePageImages/Transparent_PhenomStaff_Logo.svg";



function MoreQuestions(prop) {
    return (
        <section>
            <Container fluid className="MoreQuestions-section" id="MoreQuestions">
                <Row>
                    <Col md={12} className="MoreQuestionsConent">
                    <span className="BGlogodiv">
                                                <img src={logoImage}
                                                    alt="ArchitectsImage pic"
                                                    className="img-fluid BGlogo"
                                                />
                                            </span>
                                            <Container fluid='md' >                 
                        <Row className="justify-content-between"
                        //  style={{ justifyContent: "center"}}
                        >
                            <Col md={12} sm={12} xs={12} style={{ padding: 0}}>
                                <Row className="justify-content-between" style={{ alignItems:"center"}}>
                                
                                    <Col md={4} sm={12} xs={12} style={{ padding: 0}}>
                                     <div className="MoreQuestionscard">
                                       <h4 className="MoreQuestionscardtitle fs-1">Find the specialized talent you need.</h4>
                                     </div>
                                </Col>
                                <Col md={5} sm={12} xs={12} style={{ padding: 0}}>
                                <div className="MoreQuestionsLinksdiv">
                                    <div className="MoreQuestionsLink"> 
                                       <a href="/" target="_blank" rel="noreferrer" className="">Call Now <span>&#x27F6;</span></a> 
                                    </div>
                                    <div className="MoreQuestionsLink"> 
                                    <a href="/" target="_blank" rel="noreferrer" className="">Book an Appointment<span>&#x27F6;</span></a>
                                    </div>
                                </div>
                                </Col> 
                                </Row>
                            </Col>

                        </Row>
                        </Container>  
                    </Col>
                </Row>
            </Container>
        </section >
    );
}

export default MoreQuestions;