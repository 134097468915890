import React from "react";
import { Container, Row, Col } from "react-bootstrap";
//import home from "../../../Assets/HomePageImages/Banner.svg";
import DataAnalystsImage from "../../../Assets/HomePageImages/EnterpriseAnalytics/Data Analysts.svg";
import Architects from "../../../Assets/HomePageImages/icons/Architects.svg";
import Temporary from "../../../Assets/HomePageImages/icons/Temporary_talent_to_fill_a_gap.svg";
import multidisciplined from "../../../Assets/HomePageImages/icons/A_multi_disciplined.svg";



function DataAnalysts() {
    return (
        <section>
            <Container fluid='md' className="DataAnalysts-section" id="DataAnalysts">
               
                  
                        <Row style={{ alignItems: "center" }} className="justifycontent-between">
                            <Col md={6} className="DataAnalystsConent px-md-0 order-md-first order-sm-last order-last">
                                <h3 className="DataAnalyststitle">Data Analysts</h3>
                                <p className="DataAnalystsdesc">Analysis, client services, visualizations, specialization. </p>
                                <div className="DataAnalystsdiv">
                                    <img
                                        src={Architects}
                                        alt="ArchitectsImage pic"
                                        className="img-fluid DataAnalystsIcon"
                                    /><span>Understand, analyze, and derive value from your data. </span>
                                </div>
                                <div className="DataAnalystsdiv">
                                    <img
                                        src={Temporary}
                                        alt="TemporaryImage pic"
                                        className="img-fluid DataAnalystsIcon"
                                    /><span>Communicate analysis and results to solve business challenges. </span>
                                </div>
                                <div className="DataAnalystsdiv">
                                    <img
                                        src={multidisciplined}
                                        alt="multidisciplinedImage pic"
                                        className="img-fluid DataAnalystsIcon"
                                    /><span>Create visualizations that motivate action.</span>
                                </div>
                            </Col>
                            <Col md={6} className="px-md-0 order-md-last order-sm-last order-first">
                                <div className="DataAnalystsImagediv px-md-0">
                                    <img
                                        src={DataAnalystsImage}
                                        alt="DataAnalystsImage pic"
                                        className="img-fluid DataAnalystsImg"
                                    />
                                </div>
                            </Col>
                        </Row>
                  
               
            </Container>
        </section>
    );
}

export default DataAnalysts;