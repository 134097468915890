import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import OurMissionImage from "../../Assets/AboutUs/Our Mission.svg";



function OurMission() {
    return (
        <section>
            <Container fluid="md" className="OurMission-section" id="OurMission">
                <Row className="Hor-Center">
                    <Col md={12} className="px-md-0">
                        <Row style={{ alignItems: "center" }}>
                            <Col md={6} className="OurMissionConent px-md-0 order-md-first order-sm-last order-last">
                                <h3 className="OurMissiontitle">Our Mission </h3>
                                <p className="OurMissiondesc">Our mission is to make the world of work a better place by connecting talented people with great companies where they can work together to innovate and thrive to create an impact on the financial, social, and well-being success of themselves and their organizations. </p>
                            </Col>
                            <Col md={6} className="px-md-0 order-md-last order-sm-last order-first">
                                <div className="OurMissionImagediv">
                                    <img
                                        src={OurMissionImage}
                                        alt="OurMissionImage pic"
                                        className="img-fluid OurMissionImg"
                                    />
                                </div>
                            </Col>


                        </Row>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default OurMission;