import React from "react";
import { Container, Row, Col } from "react-bootstrap";
function Specializations() {
    return (
        <section>
            <Container fluid className="Specializations-section" id="Specializations">
                <Row>
                    <Col md={12} className="Specializations px-md-0"  >
                        <h3 className="Specializationstitle">Specializations</h3>
                        <Container fluid='md'>
                        <Row style={{ justifyContent: "center",}} classname='px-md-0'>
                            <Col md={12} className='px-md-0'>
                                <p>Technology is advancing at an ever-increasing rate, and
                                    new technologies and methods create new vulnerabilities.
                                    Moreover, the threat of insider breaches remains high. </p>
                                <p>Whether your company is at fault or not, data losses and
                                    penalties can be severe. You need people who
                                    are constantly vigilant and focused on security and privacy. </p>
                                <p>With no-cost consulting services to discover your specific
                                    needs, we match you to qualified specialists who can work
                                    with your team, on-premises, or remotely.</p>
                                
                                        <Row  className="justify-content-around py-5" >
                                            <Col md={4} sm={6} className="Specializationscard px-md-0"  >
                                                <p>Chief Information Security Officer</p>
                                                <p>Cryptographer</p>
                                                <p>Cybersecurity Analyst</p>
                                                <p>Cybersecurity Specialist</p>
                                                <p>Forensic Computer Analyst</p>
                                                <p>Information Security Analyst</p>
                                            </Col>
                                            
                                            <Col md={4} sm={6} className="Specializationscard px-md-0">
                                                <p>Penetration Tester</p>
                                                <p>Security Architect</p>
                                                <p>Security Consultant</p>
                                                <p>Security Engineer</p>
                                                <p>Security Manager</p>
                                                <p>Security Systems Administrator</p>
                                            </Col>
                                        </Row>
                                
                            </Col>

                        </Row>
                        </Container>

                    </Col>
                </Row>
            </Container>
        </section >
    );
}

export default Specializations;
