import React from "react";
import { Container, Row, Col } from "react-bootstrap";
//import home from "../../../Assets/HomePageImages/Banner.svg";
import CybersecurityManagerImage from "../../../Assets/HomePageImages/Cybersecurity/Cybersecurity Managers.svg";
import Architects from "../../../Assets/HomePageImages/icons/Architects.svg";
import Temporary from "../../../Assets/HomePageImages/icons/Temporary_talent_to_fill_a_gap.svg";
import multidisciplined from "../../../Assets/HomePageImages/icons/A_multi_disciplined.svg";


function CybersecurityManager() {
    return (
        <section>
            <Container fluid='md' className="CybersecurityManager-section" id="CybersecurityManager">
                <Row className="Hor-Center"px-md-0>
                    <Col md={12} className='px-md-0'>
                        <Row className="jsutify-content-between" style={{ alignItems: "center" }}>
                            <Col md={6} className="CybersecurityManagerConent px-md-0 order-md-first order-sm-last order-last"  >
                                <h3 className="CybersecurityManagertitle">Cybersecurity Managers </h3>
                                <p className="CybersecurityManagerdesc">Manage cybersecurity functions through risk management, analytical problem solving, and communication. </p>
                                <div className="CybersecurityManagerdiv">
                                    <img
                                        src={Architects}
                                        alt="ArchitectsImage pic"
                                        className="img-fluid CybersecurityManagerIcon"
                                    /><span>Maintain constant vigilance against external and internal threats. </span>
                                </div>
                                <div className="CybersecurityManagerdiv">
                                    <img
                                        src={Temporary}
                                        alt="TemporaryImage pic"
                                        className="img-fluid CybersecurityManagerIcon"
                                    /><span>Stay up-to-date on emerging threats and prevention measures. </span>
                                </div>
                                <div className="CybersecurityManagerdiv">
                                    <img
                                        src={multidisciplined}
                                        alt="multidisciplinedImage pic"
                                        className="img-fluid CybersecurityManagerIcon"
                                    /><span>Organize response to addressing and managing incidents. </span>
                                </div>



                            </Col>
                            <Col md={6}  className='px-md-0 order-md-last order-sm-last order-first' >
                                <div className="CybersecurityManagerImagediv">
                                    <img
                                        src={CybersecurityManagerImage}
                                        alt="CybersecurityManagerImage pic"
                                        className="img-fluid CybersecurityManagerImg"
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default CybersecurityManager;