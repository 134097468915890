import React from "react";
import { Container, Row, Col } from "react-bootstrap";
function Specializations() {
    return (
        <section>
            <Container fluid  className="Specializations-section" id="Specializations">
                <Row>
                    <Col md={12} className="Specializations" style={{padding:0}}>
                   <Container fluid='md'>
                        <h3 className="Specializationstitle">Specializations</h3>
                        <Row style={{ justifyContent: "center" }}>
                            <Col md={12} style={{padding:0}}>
                                <p>As the capabilities of analytics engines, machine learning,
                                    artificial intelligence, and IoT grows, analytics is becoming
                                    more specialized. </p>
                                <p>The metaverse is coming to business
                                    soon, so we expect even more. </p>
                                <p>With no-cost consulting services to discover your
                                    specific needs, we match you to qualified specialists
                                    who can work in with your team, on-premises or remotely.
                                </p>
                                <Row style={{ justifyContent: "center" }}>
                                    <Col md={12}style={{padding:0}}>
                                        <Row >
                                            <Col md={4} sm={6} className="Specializationscard" style={{padding:0}}>
                                                <p>Analytics Manager</p>
                                                <p>Business Intelligence Analyst</p>
                                                <p>Business Intelligence Developer</p>
                                                <p>Business Systems Analyst</p>
                                                <p>Client Services Coordinator</p>
                                                <p>Data Analyst</p>
                                                <p>Data Analytics Consultant</p>
                                                <p>Data Architect</p>
                                                <p>Data Engineer</p>
                                            </Col>
                                            <Col></Col>
                                            <Col md={4} sm={6} className="Specializationscard" style={{padding:0}}>
                                                <p>Data Security Analyst</p>
                                                <p>Data Specialist</p>
                                                <p>Database Manager</p>
                                                <p>It Systems Analyst</p>
                                                <p>Logistics Analyst</p>
                                                <p>Machine Learning Engineer</p>
                                                <p>Marketing Analyst</p>
                                                <p>Marketing Specialist</p>
                                                <p>Operations Analyst</p>
                                                <p>Project Manager</p>
                                                <p>Quantitative Analyst</p>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>

                        </Row>
                        </Container>

                    </Col>
                </Row>
            </Container>
        </section >
    );
}

export default Specializations;
