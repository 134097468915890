import React from "react";

import { Container, Row, Col,  } from "react-bootstrap";
import { FiPhoneCall } from "react-icons/fi"
import { AiOutlineCalendar } from "react-icons/ai"

import home from "../../Assets/ContactUs/Contact_US_BG.svg";
import BannerImage from "../../Assets/ContactUs/Contact_Us.svg";

// import validate from "validate.js";

import "react-datepicker/dist/react-datepicker.css";
// import ReactCountryFlag from "react-country-flag";
// import Select from "react-select";
// import countryList from "react-select-country-list";
// import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import ContactForm from "./ContactForm";

export default class App extends React.Component {
   


   
  
   
    render() {
        
        return (
            <Container fluid className='ContactUs' id="contactus">
                <Container fluid>
                    <Row>
                        <Col md={12} className="BannerBlock px-0">
                            <img
                                src={home}
                                alt="home pic"
                                className="img-fluid BannerBg"
                            />

                            <Col md={12} className="BannerConent px-0">
                                <Container fluid='md'>
                                <Row className="justify-content-between align-items-center">
                                <Col md={6} sm={12} xs={12} className="BannerContext px-md-0">
                                    <h1 className="Bannertitle">Contact Us</h1>
                                    <p className="Bannerdesc">We're on a mission to connect talented people and phenominal companies, whoever and whereever they are.</p>
                                    
                                    <Col style={{ marginTop:"5%", lineHeight: "0.7" }}> <a
                                        href="/"
                                        target="_blank"
                                        rel="noreferrer"
                                        className="BannerLink"
                                    >
                                        Book An Appointment
                                    </a></Col>


                                </Col>
                                <Col md={6} sm={12} xs={12} className="BannerImage px-md-0">
                                    <img
                                        src={BannerImage}
                                        alt="home pic"
                                        className="img-fluid BannerImage1"
                                    />
                                </Col>
                                </Row>
                                </Container>
                            </Col>
                        </Col>
                        
                        <Col md={12} className="ContactUsdata" style={{padding:0}}>
                            <Container className="justify-content-center">
                            <Row>
                                <Col className="BannerContext" md={6} sm={6} style={{padding:0}}>
                                    <h4 className="ContactUstitle">Phenom Staff</h4>
                                    <p className="ContactUsdesc">1333 Corporate Dr Ste 212</p>
                                    <p className="ContactUsdesc">Irving, TX 75038 USA</p>
                                    <p className="ContactUsdesc"><a href="tel:+1 (855) 978-6816">+1 (855) 978-6816</a></p>
                                    <p className="ContactUsdesc"><a href="mailto:info@phenomstaff.com">info@phenomstaff.com</a></p>
                                    <div>
                                        <h4 className="ContactUstitle">How can we help you today?</h4>
                                        <div className="consultant">
                                            <p className="ContactUsdesc">I want to talk with a consultant about my staffing needs.</p>
                                            <div><span className="ContactUsLink"><a href="/"><AiOutlineCalendar /> Book an Appointment</a></span><span className="ContactUsLink"><a href="/"><FiPhoneCall /> Call Now</a></span></div>
                                        </div>
                                        <div className="consultant">
                                            <p className="ContactUsdesc">I want to talk about my best next move.</p>
                                            <div><span className="ContactUsLink"><a href="/"><AiOutlineCalendar /> Book an Appointment</a></span><span className="ContactUsLink"><a href="/"><FiPhoneCall />  Call Now</a></span></div>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={6} sm={6} className="FormDiv" >
                                    <div className="ContactUsform" onChange={this.completeForm}>
                                        <h4  className="ContactUstitle">
                                            Contact Us
                                        </h4>
                                        <p  className="ContactUsdesc">
                                            For personalized attention, please submit the form to help us guide your inquiry
                                        </p>
 {/* **************************************************************** Form start *******************************************************************/}
<ContactForm/>

 {/* **************************************************************** Form End *********************************************************************/}

                                    </div>
                                </Col>
                            </Row>
                            </Container>
                        </Col>
                    </Row>

                </Container>
            </Container >

        );
    }
}
