import React from "react";
import { Container, Row, Col } from "react-bootstrap";
//import home from "../../../Assets/HomePageImages/Banner.svg";
// import EmployersImage from "../../../Assets/HomePageImages/Cybersecurity/For Employers.svg";
import Architects from "../../../Assets/HomePageImages/icons/Architects.svg";
import Temporary from "../../../Assets/HomePageImages/icons/Temporary_talent_to_fill_a_gap.svg";
import multidisciplined from "../../../Assets/HomePageImages/icons/A_multi_disciplined.svg";
import teammember from "../../../Assets/HomePageImages/icons/A_new_team_member.svg";
import Technology from "../../../Assets/HomePageImages/icons/On_call_Technology.svg";

import arroemark from "../../../Assets/HomePageImages/spiralArrow.svg"



function Employers() {
    return (
        <section>
            <Container fluid className="Employers-section Disk_bg" id="Employers"><div id="disc-flip"></div>
                <img
                    src={arroemark}
                    alt="arrowImage pic"
                    className="img-fluid arrow"
                />
                <Row className="Hor-Center">

                    <Col md={12} sm={12} xs={12}>
                        <h3 className="Employerstitle">For Employers</h3>
                        <div className="border"></div>
                        <Row >
                            <Col md={6} sm={6} xs={12} className="EmployersConent">
                              
                               <p className="Employersdesc">Not every business question requires a data scientist, but when they do, you’ll want the best you can get. </p>
                                <p className="Employersdesc">There is no substitute for skilled talent, and you don’t have time and resources to sift through unq	ualified resumes. </p>
                                <p className="Employersdesc">Let us do the heavy lifting. Our rigorous assessment and selection process delivers talent who can perform on day one. </p>

                                </Col>
                            <Col md={6} sm={6} xs={12} className="EmployersConent">
                                <div className="Employersdiv">
                                    <img
                                        src={Architects}
                                        alt="ArchitectsImage pic"
                                        className="img-fluid EmployersIcon"
                                    /><span>Data Scientists </span>
                                </div>
                                <div className="Employersdiv">
                                    <img
                                        src={Temporary}
                                        alt="TemporaryImage pic"
                                        className="img-fluid EmployersIcon"
                                    /><span>Data Engineers</span>
                                </div>
                                <div className="Employersdiv">
                                    <img
                                        src={multidisciplined}
                                        alt="multidisciplinedImage pic"
                                        className="img-fluid EmployersIcon"
                                    /><span>Analysts </span>
                                </div>
                                <div className="Employersdiv">
                                    <img
                                        src={teammember}
                                        alt="teammemberImage pic"
                                        className="img-fluid EmployersIcon"
                                    /><span>Advanced Specializations</span>
                                </div>
                                <div className="Employersdiv">
                                    <img
                                        src={Technology}
                                        alt="TechnologyImage pic"
                                        className="img-fluid EmployersIcon"
                                    /><span>Industry Specialists </span>
                                </div>


                            </Col>
                            <div className="EmployeeLinks">
                                <div className="Link"> <a
                                    href="/"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="EmployersLink"
                                >
                                    Call Now
                                </a> </div><div className="Link"> <a
                                    href="/"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="EmployersLink"
                                >
                                    Book an Appointment
                                </a></div></div>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default Employers;