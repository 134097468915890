import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import Ceridian from "../../Assets/HomePageImages/vendors/Ceridian.svg";
import Cornerstone from "../../Assets/HomePageImages/vendors/Cornerstone.svg";
import OracleHCM from "../../Assets/HomePageImages/vendors/Oracle_HCM.svg";
import Salesforce from "../../Assets/HomePageImages/vendors/Salesforce.svg";
import Servicenow from "../../Assets/HomePageImages/vendors/Servicenow.svg";
import SumTotal from "../../Assets/HomePageImages/vendors/SumTotal.svg";
import UKG from "../../Assets/HomePageImages/vendors/UKG.svg";
import Workday from "../../Assets/HomePageImages/vendors/Workday.svg";
import RepeatGrid from "../../Assets/HomePageImages/RepeatGrid.svg"


function VenderSpecilizations() {
    return (
        <section>
            <Container fluid className="VenderSpecilizations-section" id="VenderSpecilizations">
                <Row>
                    <Col md={12} className="VenderSpecilizations">
                        <h3 className="VenderSpecilizationstitle">Vender Specializations</h3>
                        <Row className="justify-content-between"
                        style={{padding:0}}
                        >
                            <Col md={12} style={{padding:0}}>
                               < Container fluid='md'>
                                <Row className="Bring-front justify-content-between g-5">
                                    <Col md={3} sm={6} className="VenderSpecilizationscard">
                                        <Link to="/Ceridian">
                                            <Col md={12} className="VenderSpecilizationsContent">
                                                <img
                                                    src={Ceridian}
                                                    alt="home pic"
                                                    className="img-fluid Blogimg"/>
                                            </Col>
                                        </Link>

                                    </Col>
                                    <Col md={3} sm={6} className="VenderSpecilizationscard">
                                        <Link to="/Cornerstone">
                                            <Col md={12} className="VenderSpecilizationsContent">
                                                <img
                                                    src={Cornerstone}
                                                    alt="home pic"
                                                    className="img-fluid Blogimg"
                                                />

                                            </Col>
                                        </Link>
                                    </Col>
                                    <Col md={3} sm={6} className="VenderSpecilizationscard">
                                        <Link to="/Oracle">
                                            <Col md={12} className="VenderSpecilizationsContent">
                                                <img
                                                    src={OracleHCM}
                                                    alt="home pic"
                                                    className="img-fluid Blogimg"
                                                />

                                            </Col>
                                        </Link>
                                    </Col>
                                    <Col md={3} sm={6} className="VenderSpecilizationscard">
                                        <Link to="/Salesforce">
                                            <Col md={12} className="VenderSpecilizationsContent">
                                                <img
                                                    src={Salesforce}
                                                    alt="home pic"
                                                    className="img-fluid Blogimg"
                                                />

                                            </Col>
                                        </Link>
                                    </Col>
                                    <Col md={3} sm={6} className="VenderSpecilizationscard">
                                        <Link to="/Servicenow">
                                            <Col md={12} className="VenderSpecilizationsContent">
                                                <img
                                                    src={Servicenow}
                                                    alt="home pic"
                                                    className="img-fluid Blogimg"
                                                />
                                            </Col>
                                        </Link>
                                    </Col>
                                    <Col md={3} sm={6} className="VenderSpecilizationscard">
                                        <Link to="/Sumtotal">
                                            <Col md={12} className="VenderSpecilizationsContent">
                                                <img
                                                    src={SumTotal}
                                                    alt="home pic"
                                                    className="img-fluid VenderSpecilizationsimg"
                                                />

                                            </Col>
                                        </Link>
                                    </Col>
                                    <Col md={3} sm={6} className="VenderSpecilizationscard">
                                        <Link to="/UKG">
                                            <Col md={12} className="VenderSpecilizationsContent">
                                                <img
                                                    src={UKG}
                                                    alt="home pic"
                                                    className="img-fluid Blogimg"
                                                />

                                            </Col>
                                        </Link>
                                    </Col>
                                    <Col md={3} sm={6} className="VenderSpecilizationscard">
                                        <Link to="/Workday">
                                            <Col md={12} className="VenderSpecilizationsContent">
                                                <img
                                                    src={Workday}
                                                    alt="home pic"
                                                    className="img-fluid VenderSpecilizationsimg"
                                                />

                                            </Col>
                                        </Link>
                                    </Col>
                                </Row>
                                </Container>
                            </Col>

                        </Row>


                    </Col>
                </Row>
                <img
                                                        src={RepeatGrid}
                                                        alt="home pic"
                                                        className="img-fluid RepeatGrid right bottom"
                                                    />
            </Container>
        </section >
    );
}

export default VenderSpecilizations;