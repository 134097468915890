import React from "react";
import { Container, Row, Col } from "react-bootstrap";
//import home from "../../Assets/HomePageImages/Banner.svg";
import DayforceReportingandAnalyticsTabImage from "../../../../Assets/Ceridian/Dayforce_Reporting_and_Analytics_Image.svg";
import Architects from "../../../../Assets/HomePageImages/ceradian/Data scientists.svg";
import Temporary from "../../../../Assets/HomePageImages/ceradian/Data governance.svg";
import multidisciplined from "../../../../Assets/HomePageImages/ceradian/Data visualization.svg";
import DataManager from "../../../../Assets/HomePageImages/ceradian/Data managers.svg";

function DayforceReportingandAnalytics() {
    return (
        <section>
            <Container fluid="md" className="FeaturedSpecializationTab-section" id="FeaturedSpecializationTab">
                <Row className="Hor-Center">
                    <Col md={12} style={{padding:0}}>
                        <Row className="judtify-content-between">
                            <Col md={7} className="FeaturedSpecializationTabConent">
                                
                                <p className="FeaturedSpecializationTabdesc">Dayforce gives you dozens of standard reports and analytical models for your workforce. Because it is a single unified platform, all your reports are in one place. </p>
                                <p className="FeaturedSpecializationTabdesc">But creating your custom reports and analytics is up to you, so we provide professionals to take your reporting and analytics to a higher level. </p>

                                <div className="FeaturedSpecializationTabdiv">
                                    <img
                                        src={Architects}
                                        alt="ArchitectsImage pic"
                                        className="img-fluid FeaturedSpecializationTabIcon"/>
                                        <span>Data scientists, engineers, and analysts to build new analytical models to solve your specific business issues. </span>
                                </div>
                                <div className="FeaturedSpecializationTabdiv">
                                    <img
                                        src={Temporary}
                                        alt="TemporaryImage pic"
                                        className="img-fluid FeaturedSpecializationTabIcon"
                                    /><span>Data governance consultants to help you get control of your data. </span>
                                </div>
                                <div className="FeaturedSpecializationTabdiv">
                                    <img
                                        src={multidisciplined}
                                        alt="multidisciplinedImage pic"
                                        className="img-fluid FeaturedSpecializationTabIcon"
                                    /><span>Data visualization experts who present your data in a way that your people understand. </span>
                                </div>
                                <div className="FeaturedSpecializationTabdiv">
                                    <img
                                        src={DataManager}
                                        alt="multidisciplinedImage pic"
                                        className="img-fluid FeaturedSpecializationTabIcon"
                                    /><span>Data managers who can oversee part or all of your data and reporting. </span>
                                </div>


                            </Col>
                            <Col md={5} >
                                <div className="FeaturedSpecializationTabImagediv">
                                    <img
                                        src={DayforceReportingandAnalyticsTabImage}
                                        alt="FeaturedSpecializationTabImage pic"
                                        className="img-fluid FeaturedSpecializationTabImg"
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default DayforceReportingandAnalytics;