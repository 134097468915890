import React from "react";
import { Container, Row, Col } from "react-bootstrap";
//import home from "../../Assets/HomePageImages/Banner.svg";
import DayforceBenefitsTabImage from "../../../../Assets/cornerstone/Core HR image.svg";
import Architects from "../../../../Assets/HomePageImages/icons/Architects.svg";
import Temporary from "../../../../Assets/HomePageImages/icons/Temporary_talent_to_fill_a_gap.svg";
import multidisciplined from "../../../../Assets/HomePageImages/icons/A_multi_disciplined.svg";



function CoreHR() {
    return (
        <section>
            <Container fluid="md" className="FeaturedSpecializationTab-section" id="FeaturedSpecializationTab">
                <Row className="Hor-Center">
                    <Col md={12} style={{padding:0}}>
                        <Row className="justify-content-center">
                            <Col md={7} className="FeaturedSpecializationTabConent">
                               
                                <p className="FeaturedSpecializationTabdesc">When payroll issues arise, you don’t have time to wait. You need help now. </p>
                                <p className="FeaturedSpecializationTabdesc">Our Cornerstone services provide talent who can help you with your payroll, benefits, workforce management, and employee management deployment and support. </p>

                               
                                <div className="FeaturedSpecializationTabdiv">
                                    <img
                                        src={Architects}
                                        alt="ArchitectsImage pic"
                                        className="img-fluid FeaturedSpecializationTabIcon"
                                    /><span>Implementation advisory consultants to help you align Cornerstone to your specific requirements.  </span>
                                </div>
                                <div className="FeaturedSpecializationTabdiv">
                                    <img
                                        src={Temporary}
                                        alt="TemporaryImage pic"
                                        className="img-fluid FeaturedSpecializationTabIcon"
                                    /><span>On-call support when you need it. </span>
                                </div>
                                <div className="FeaturedSpecializationTabdiv">
                                    <img
                                        src={multidisciplined}
                                        alt="multidisciplinedImage pic"
                                        className="img-fluid FeaturedSpecializationTabIcon"
                                    /><span>Fill a temporary gap or offload all your administrative burdens. </span>
                                </div>


                            </Col>
                            <Col md={5} >
                                <div className="FeaturedSpecializationTabImagediv">
                                    <img
                                        src={DayforceBenefitsTabImage}
                                        alt="FeaturedSpecializationTabImage pic"
                                        className="img-fluid FeaturedSpecializationTabImg"
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default CoreHR;