import React from "react";
import { Container, Row, Col } from "react-bootstrap";
//import home from "../../Assets/HomePageImages/Banner.svg";
import DayforceIntegrationsTabImage from "../../../../Assets/HomePageImages/Sumtotal/SumTotal Integrations.svg";
import Architects from "../../../../Assets/HomePageImages/Sumtotal/Experts.svg";
import Temporary from "../../../../Assets/HomePageImages/Sumtotal/Content.svg";



function SumTotalIntegrations() {
    return (
        <section>
            <Container fluid='md' className="FeaturedSpecializationTab-section" id="FeaturedSpecializationTab">
                <Row className="Hor-Center">
                    <Col md={12} style={{padding:0}}>
                        <Row className="justify-content-between">
                            <Col md={7} className="FeaturedSpecializationTabConent">
                              
                                <p className="FeaturedSpecializationTabdesc">The SumTotal Marketplace has built-in connectors for content providers and other HCM and Learning platforms. </p>
                                <p className="FeaturedSpecializationTabdesc">However, if you need to build your own connector, we have ready talent that can manage your APIs for smooth data flow. </p>
                             
                                <div className="FeaturedSpecializationTabdiv">
                                    <img
                                        src={Architects}
                                        alt="ArchitectsImage pic"
                                        className="img-fluid FeaturedSpecializationTabIcon"
                                    /><span>Experts to help you optimize your data streams. </span>
                                </div>
                                <div className="FeaturedSpecializationTabdiv">
                                    <img
                                        src={Temporary}
                                        alt="TemporaryImage pic"
                                        className="img-fluid FeaturedSpecializationTabIcon"
                                    /><span>Content integration experts to give you access to any online learning source. </span>
                                </div>


                            </Col>
                            <Col md={5} >
                                <div className="FeaturedSpecializationTabImagediv">
                                    <img
                                        src={DayforceIntegrationsTabImage}
                                        alt="FeaturedSpecializationTabImage pic"
                                        className="img-fluid FeaturedSpecializationTabImg"
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default SumTotalIntegrations;