import React from "react";
import { Container, Row, Col } from "react-bootstrap";
function Specializations() {
    return (
        <section>
            <Container fluid className="Specializations-section" id="Specializations">
                
                <Container fluid="md">
                <Row >
                    <Col md={12} className="Specializations px-md-0" >
                        <h3 className="Specializationstitle">Specializations</h3>
                        <Row style={{ justifyContent: "center" }}>
                            <Col md={12} className='px-md-0'>
                                <p>Data science is becoming much more specialized as
                                    analytics capability grows.  </p>
                                <p>With no-cost consulting services to discover your
                                    specific needs, we match you to qualified specialists
                                    who can work in with your team, on-premises or remotely. </p>
                                <Row style={{ justifyContent: "center" }} className="justify-content-between">
                                    <Col md={12} style={{padding:0}}>
                                        <Row >
                                            <Col md={4} sm={6} xs={6} className="Specializationscard px-md-0">


                                                <p>Actuarial Scientists</p>
                                                <p>Applications Architects</p>
                                                <p>Business Analytics Practitioners</p>
                                                <p>Cloud Infrastructure Architects</p>
                                                <p>Data Architects</p>
                                                <p>Data Engineers</p>
                                                <p>Data Translators</p>
                                                <p>Digital Analytics Consultants</p>
                                                <p>Enterprise Architects</p>
                                            </Col>
                                            <Col></Col>
                                            <Col md={4} sm={6} xs={6} className="Specializationscard px-md-0" >
                                                <p>Machine Learning Engineers</p>
                                                <p>Machine Learning Scientists</p>
                                                <p>Mathematicians</p>
                                                <p>Quality Analysts</p>
                                                <p>Software Programming Analysts</p>
                                                <p>Spatial Data Scientists</p>
                                                <p>Statisticians</p>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>

                        </Row>


                    </Col>
                    </Row>
                    </Container>
                
            </Container>
        </section >
    );
}

export default Specializations;
