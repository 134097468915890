import React from "react";
import { Container, Row, Col } from "react-bootstrap";
//import home from "../../../Assets/HomePageImages/Banner.svg";
// import EmployersImage from "../../../Assets/HomePageImages/Cybersecurity/For Employers.svg";
import Architects from "../../../Assets/HomePageImages/icons/Architects.svg";
import Temporary from "../../../Assets/HomePageImages/icons/Temporary_talent_to_fill_a_gap.svg";
import multidisciplined from "../../../Assets/HomePageImages/icons/A_multi_disciplined.svg";
import teammember from "../../../Assets/HomePageImages/icons/A_new_team_member.svg";
import Technology from "../../../Assets/HomePageImages/icons/On_call_Technology.svg";

import arroemark from "../../../Assets/HomePageImages/spiralArrow.svg"



function Employers() {
    return (
        <section>
            <Container fluid className="Employers-section Disk_bg" id="Employers"><div id="disc-flip"></div>
                <img
                    src={arroemark}
                    alt="arrowImage pic"
                    className="img-fluid arrow"
                />
                <Row className="Hor-Center">

                    <Col md={12} sm={12} xs={12}>
                        <h3 className="Employerstitle">For Employers</h3>
                        <div className="border"></div>
                        <Row >
                            <Col md={6} sm={6} xs={12} className="EmployersConent">
                              <p className="Employersdesc">ServiceNow HR Service Delivery is a collection of tools that enable you to elevate the employee experience.</p>
                                <p className="Employersdesc">To implement, manage, and optimize its functions, you will need expert talent—people who know the software—to work with your team.</p>
                                <p className="Employersdesc">Our assessment and selection process assures you will have ready talent.</p>
                                <p className="Employersdesc">What skills do you value most? Let’s work together to create a profile of your ideal team member.</p>
                                <p className="Employersdesc">Talk with us about your needs:</p>
                                
                                </Col>
                            <Col md={6} sm={6} xs={12} className="EmployersConent">
                            <div className="Employersdiv">
                                    <img
                                        src={Architects}
                                        alt="ArchitectsImage pic"
                                        className="img-fluid EmployersIcon"
                                    /><span>Engage experts to augment your team.</span>
                                </div>
                                <div className="Employersdiv">
                                    <img
                                        src={Temporary}
                                        alt="TemporaryImage pic"
                                        className="img-fluid EmployersIcon"
                                    /><span>Hire a temporary specialist to fill a gap.</span>
                                </div>
                                <div className="Employersdiv">
                                    <img
                                        src={multidisciplined}
                                        alt="multidisciplinedImage pic"
                                        className="img-fluid EmployersIcon"
                                    /><span>Staff a multi-disciplined project team.</span>
                                </div>
                                <div className="Employersdiv">
                                    <img
                                        src={teammember}
                                        alt="teammemberImage pic"
                                        className="img-fluid EmployersIcon"
                                    /><span>Add an employee—direct hire or temp-to-hire.</span>
                                </div>
                                <div className="Employersdiv">
                                    <img
                                        src={Technology}
                                        alt="TechnologyImage pic"
                                        className="img-fluid EmployersIcon"
                                    /><span>Get relief from the ups and downs of service and maintenance with skilled administrators.</span>
                                </div>

                            </Col>
                            <div className="EmployeeLinks">
                                <div className="Link"> <a
                                    href="/"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="EmployersLink"
                                >
                                    Call Now
                                </a> </div><div className="Link"> <a
                                    href="/"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="EmployersLink"
                                >
                                    Book an Appointment
                                </a></div></div>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default Employers;