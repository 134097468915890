import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import WhoWeAreImage from "../../Assets/AboutUs/Who we are.svg";



function WhoWeAre() {
    return (
        <section>
            <Container fluid="md" className="WhoWeAre-section" id="WhoWeAre">
                <Row className="Hor-Center justify-content-between" >
                    <Col md={12} classname="px-md-0" >
                        <Row style={{ alignItems: "center" }}>
                            <Col md={6} className='px-md-0'>
                                <div className="WhoWeAreImagediv">
                                    <img src={WhoWeAreImage} alt="WhoWeAreImage pic" className="img-fluid WhoWeAreImg"/>
                                </div>
                            </Col>
                            <Col md={6} className="WhoWeAreConent px-md-0">
                                <h3 className="WhoWeAretitle">Who we Are</h3>
                                <p className="WhoWeAredesc">Phenom Staff is a group of technology veterans and entrepreneurs with decades of experience in implementation services,
                                    managed services and support, and optimization of enterprise applications. We believe that with talented people in the right roles, organizations
                                    can thrive and overcome any challenges they face.</p>
                                <p className="WhoWeAredesc">We founded our company to make that come true for as many talented people and proactive companies as possible. </p>
                            </Col>

                        </Row>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default WhoWeAre;