import React from "react";
import { Container, Row, Col } from "react-bootstrap";
//import home from "../../../Assets/HomePageImages/Banner.svg";
import DataEngineersImage from "../../../Assets/HomePageImages/EnterpriseAnalytics/Data Engineers.svg";
import Architects from "../../../Assets/HomePageImages/icons/Architects.svg";
import Temporary from "../../../Assets/HomePageImages/icons/Temporary_talent_to_fill_a_gap.svg";
import multidisciplined from "../../../Assets/HomePageImages/icons/A_multi_disciplined.svg";
import teammember from "../../../Assets/HomePageImages/icons/A_new_team_member.svg";
import Technology from "../../../Assets/HomePageImages/icons/On_call_Technology.svg";


function DataEngineers() {
    return (
        <section>
            <Container fluid='md' className="DataEngineers-section" id="DataEngineers">
            <Row className="Hor-Center">
                        <Col md={12} className="px-md-0">
                        <Row style={{ alignItems: "center" }} className='justify-content-between'>

                            <Col md={6} className="DataEngineersConent px-md-0 order-md-first order-sm-last order-last">
                                <h3 className="DataEngineerstitle">Data Engineers</h3>
                                <p className="DataEngineersdesc">Design, development, operation, and maintenance of business intelligence systems. </p>
                                <div className="DataEngineersdiv">
                                    <img
                                        src={Architects}
                                        alt="ArchitectsImage pic"
                                        className="img-fluid DataEngineersIcon"
                                    /><span>Create and maintain data architectures. </span>
                                </div>
                                <div className="DataEngineersdiv">
                                    <img
                                        src={Temporary}
                                        alt="TemporaryImage pic"
                                        className="img-fluid DataEngineersIcon"
                                    /><span>Build data systems that transform raw data into intelligence.</span>
                                </div>
                                <div className="DataEngineersdiv">
                                    <img
                                        src={multidisciplined}
                                        alt="multidisciplinedImage pic"
                                        className="img-fluid DataEngineersIcon"
                                    /><span>Improve data quality and availability.</span>
                                </div>
                                <div className="DataEngineersdiv">
                                    <img
                                        src={teammember}
                                        alt="teammemberImage pic"
                                        className="img-fluid DataEngineersIcon"
                                    /><span>Develop data processes.</span>
                                </div>
                                <div className="DataEngineersdiv">
                                    <img
                                        src={Technology}
                                        alt="TechnologyImage pic"
                                        className="img-fluid DataEngineersIcon"
                                    /><span>Execute governance procedures.</span>
                                </div>


                            </Col>
                            <Col md={6} className="px-md-0 order-md-last order-sm-last order-first" >
                                <div className="DataEngineersImagediv ">
                                    <img
                                        src={DataEngineersImage}
                                        alt="DataEngineersImage pic"
                                        className="img-fluid DataEngineersImg"
                                    />
                                </div>
                            </Col>
                        </Row>
                        </Col></Row>
            </Container>
        </section>
    );
}

export default DataEngineers;