import React from "react";
import { Container, Row, Col } from "react-bootstrap";
//import home from "../../Assets/HomePageImages/Banner.svg";
import DayforcePayrollImage from "../../../../Assets/Ceridian/Dayforce_Payroll_Image.svg";
import Architects from "../../../../Assets/HomePageImages/ceradian/Immediate support.svg";
import Temporary from "../../../../Assets/HomePageImages/ceradian/Get help with.svg";
import multidisciplined from "../../../../Assets/HomePageImages/ceradian/Reduce time.svg";
import teammember from "../../../../Assets/HomePageImages/ceradian/Leverage the experience.svg";



function DayforcPayroll() {
    return (
        <section>
            <Container fluid="md" className="FeaturedSpecializationTab-section" id="FeaturedSpecializationTab">
                <Row className="Hor-Center">
                    <Col md={12} style={{padding:0}}>
                        <Row className="justify-content-center">
                            <Col md={7} className="FeaturedSpecializationTabConent">
                                
                                <p className="FeaturedSpecializationTabdesc">When payroll issues arise, you don’t have time to wait. You need help immediately. </p>
                                <p className="FeaturedSpecializationTabdesc">Maintain control and independence of your payroll processing. Lean on our Dayforce Payroll specialists. </p>
                                
                                <div className="FeaturedSpecializationTabdiv">
                                    <img
                                        src={Architects}
                                        alt="ArchitectsImage pic"
                                        className="img-fluid FeaturedSpecializationTabIcon"
                                    /><span>Immediate support when you need it. </span>
                                </div>
                                <div className="FeaturedSpecializationTabdiv">
                                    <img
                                        src={Temporary}
                                        alt="TemporaryImage pic"
                                        className="img-fluid FeaturedSpecializationTabIcon"
                                    /><span>Get help with reconciliation and year-end audits. </span>
                                </div>
                                <div className="FeaturedSpecializationTabdiv">
                                    <img
                                        src={multidisciplined}
                                        alt="multidisciplinedImage pic"
                                        className="img-fluid FeaturedSpecializationTabIcon"
                                    /><span>Reduce time, effort, and exposure to penalties. </span>
                                </div>
                                <div className="FeaturedSpecializationTabdiv">
                                    <img
                                        src={teammember}
                                        alt="teammemberImage pic"
                                        className="img-fluid FeaturedSpecializationTabIcon"
                                    /><span>Avoid employee backlash and protect your reputation with on-time, accurate payroll. </span>
                                </div>
                                <div className="FeaturedSpecializationTabdiv">
                                    <img
                                        src={teammember}
                                        alt="teammemberImage pic"
                                        className="img-fluid FeaturedSpecializationTabIcon"
                                    /><span>Leverage the experience of our experienced and certified consultants. </span>
                                </div>



                            </Col>
                            <Col md={5} >
                                <div className="FeaturedSpecializationTabImagediv">
                                    <img
                                        src={DayforcePayrollImage}
                                        alt="FeaturedSpecializationTabImage pic"
                                        className="img-fluid FeaturedSpecializationTabImg"
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default DayforcPayroll;