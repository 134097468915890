import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProcessworkImage1 from "../../Assets/ServicesAssets/ProcessWorks01.svg";
import ProcessworkImage2 from "../../Assets/ServicesAssets/ProcessWorks02.svg";
import ProcessworkImage3 from "../../Assets/ServicesAssets/ProcessWorks03.svg";
import ProcessworkImage4 from "../../Assets/ServicesAssets/ProcessWorks04.svg";
import ProcessworkImage5 from "../../Assets/ServicesAssets/ProcessWorks05.svg";
import ProcessworkImage6 from "../../Assets/ServicesAssets/ProcessWorks06.svg";

function Processwork() {
    return (
        <section>
            <Container fluid='md' className="Processwork-section" id="Processwork">
                <Row className="Hor-Center" >
                    <Col md={12} className='px-2' >
                    <h3 className="Processworktitle">How the Process Works</h3>
                        <p className="Processworkdesc">Our staffing specialist will guide you through the process, giving you the information you need to make the right devision along the way.</p>
                 
                            <Row  className="justify-content-between align-items-center">
                            <Col md={5} className='px-md-0 '>
                                <div className="ProcessworkImagediv">
                                    <img
                                        src={ProcessworkImage1}
                                        alt="ProcessworkImage pic"
                                        className="img-fluid ProcessworkImg"
                                    />
                                </div>
                            </Col>
                            <Col md={5} className="ProcessworkConent ">
                                <h4 className="Processworkcardtitle">Meet with a staffing specialist</h4>
                                <p className="Processworkcarddesc">You work with an assigned specialists to define your precise needs.</p>
                                <p className="Processworkcarddesc">You define the job requirements and the number of people ypu need. If you have several roles to fill, the specialist will help you work out the requirements for each.</p>
                                
                            </Col>
                            </Row>

                            <Row  className="justify-content-between align-items-center">
                            <Col md={5} className="ProcessworkConent order-md-first order-sm-last order-last">
                                <h4 className="Processworkcardtitle">Create the job posting</h4>
                                <p className="Processworkcarddesc"> Depending on the employment type, your specialist helps you compose a job description or a project description for a contract employee or project team.</p>
                                
                            </Col>
                            <Col md={5} className="order-md-last order-sm-last order-first">
                                <div className="ProcessworkImagediv order-md-first order-sm-last order-last ">
                                    <img
                                        src={ProcessworkImage2}
                                        alt="ProcessworkImage pic"
                                        className="img-fluid ProcessworkImg"
                                    />
                                </div>
                            </Col>
                            </Row>


                            <Row  className="justify-content-between align-items-center">
                            <Col md={5} >
                                <div className="ProcessworkImagediv">
                                    <img
                                        src={ProcessworkImage3}
                                        alt="ProcessworkImage pic"
                                        className="img-fluid ProcessworkImg"
                                    />
                                </div>
                            </Col>
                            <Col md={5} className="ProcessworkConent">
                                <h4 className="Processworkcardtitle">Define the process and estimated cost</h4>
                                <p className="Processworkcarddesc">Your specialist provides complete information on how the process works and gives you a breakdown of the costs</p>
                                
                            </Col>
                            </Row>


                            
                            <Row  className="justify-content-between align-items-center">
                            <Col md={5} className="ProcessworkConent order-md-first order-sm-last order-last">
                                <h4 className="Processworkcardtitle">Assess and screen your shortlist</h4>
                                <p className="Processworkcarddesc">We assess and screen your applicants and provide a list of candidates who pass our rigorous screening.</p>
                                <p className="Processworkcarddesc">You choose whether to invite them into your interview process</p>
                                <p className="Processworkcarddesc">Our recruiting platform enables you to engage your candidates from initial contact until the translation to hiring.</p>
                                
                            </Col>
                            <Col md={5} className="order-md-last order-sm-last order-first">
                                <div className="ProcessworkImagediv" >
                                    <img
                                        src={ProcessworkImage4}
                                        alt="ProcessworkImage pic"
                                        className="img-fluid ProcessworkImg"
                                    />
                                </div>
                            </Col>
                            </Row>


                            <Row  className="justify-content-between align-items-center">
                            <Col md={5} className="">
                                <div className="ProcessworkImagediv">
                                    <img
                                        src={ProcessworkImage5}
                                        alt="ProcessworkImage pic"
                                        className="img-fluid ProcessworkImg"
                                    />
                                </div>
                            </Col>
                            <Col md={5} className="ProcessworkConent">
                                <h4 className="Processworkcardtitle">Select your candidate</h4>
                                <p className="Processworkcarddesc">You make your final selections and tender your offer. When your candidate accepts the offer, our team will be notified to start pre-employment processing</p>
                                <p className="Processworkcarddesc">If your candidate doesn't accept an offer, we move you on to the next candidate and continue the process untill you have the person you need.</p>
                                
                            </Col>
                            </Row>

                           <Row  className="justify-content-between align-items-center">
                            <Col md={5} className="ProcessworkConent order-md-first order-sm-last order-last">
                                <h4 className="Processworkcardtitle">Begin onboarding</h4>
                                <p className="Processworkcarddesc">We handle the pre-employment portion of onboarding, including reference checks, verifications, and payroll or accounts payable initiatives, You start pre-employment onboarding with the team.</p>
                                <p className="Processworkcarddesc">We notify you right away when the candidate is ready for day one.</p>
                                
                            </Col>
                            <Col md={5} className="order-md-last order-sm-last order-first">
                                <div className="ProcessworkImagediv">
                                    <img
                                        src={ProcessworkImage6}
                                        alt="ProcessworkImage pic"
                                        className="img-fluid ProcessworkImg"
                                    />
                                </div>
                            </Col>
                        </Row>


                        
                    </Col>
                </Row>
            </Container >
        </section >
    );
}

export default Processwork;