import React from "react";
import { Container, Row, Col } from "react-bootstrap";
//import home from "../../../Assets/HomePageImages/Banner.svg";
import DataEngineersImage from "../../../Assets/HomePageImages/DataScience/Data Engineers.svg";
import Architects from "../../../Assets/HomePageImages/icons/Architects.svg";
import Temporary from "../../../Assets/HomePageImages/icons/Temporary_talent_to_fill_a_gap.svg";
import multidisciplined from "../../../Assets/HomePageImages/icons/A_multi_disciplined.svg";
import teammember from "../../../Assets/HomePageImages/icons/A_new_team_member.svg";



function DataEngineers() {
    return (
        <section>
            <Container fluid="md" className="DataEngineers-section" id="DataEngineers">
                <Row className="Hor-Center">
                    <Col md={12} >
                        <Row style={{ alignItems: "center" }} className="justify-content-between">

                            <Col md={6} sm={12} xs={12}className="DataEngineersConent order-md-first order-sm-last order-last">
                                <h3 className="DataEngineerstitle">Data Engineers </h3>
                                <p className="DataEngineersdesc">Get your data in shape. </p>
                                <div className="DataEngineersdiv">
                                    <img
                                        src={Architects}
                                        alt="ArchitectsImage pic"
                                        className="img-fluid DataEngineersIcon"
                                    /><span>Create and maintain data architectures. </span>
                                </div>
                                <div className="DataEngineersdiv">
                                    <img
                                        src={Temporary}
                                        alt="TemporaryImage pic"
                                        className="img-fluid DataEngineersIcon"
                                    /><span>improve data quality and availability </span>
                                </div>
                                <div className="DataEngineersdiv">
                                    <img
                                        src={multidisciplined}
                                        alt="multidisciplinedImage pic"
                                        className="img-fluid DataEngineersIcon"
                                    /><span>Develop data processes. </span>
                                </div>
                                <div className="DataEngineersdiv">
                                    <img
                                        src={teammember}
                                        alt="teammemberImage pic"
                                        className="img-fluid DataEngineersIcon"
                                    /><span>Data management and governance. </span>
                                </div>
                            </Col>
                            <Col md={6} sm={12} xs={12} className='order-md-last order-sm-last order-first'>
                                <div className="DataEngineersImagediv">
                                    <img
                                        src={DataEngineersImage}
                                        alt="DataEngineersImage pic"
                                        className="img-fluid DataEngineersImg"
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default DataEngineers;