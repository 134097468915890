import React from "react";
import { Container, Row, Col } from "react-bootstrap";
//import home from "../../Assets/HomePageImages/Banner.svg";
import DayforceReportingandAnalyticsTabImage from "../../../../Assets/Ceridian/Dayforce_Reporting_and_Analytics_Image.svg";
import Architects from "../../../../Assets/HomePageImages/oracle/Data scientists.svg";
import Temporary from "../../../../Assets/HomePageImages/oracle/Engineers and data.svg";
import multidisciplined from "../../../../Assets/HomePageImages/oracle/Analyst who can deliver.svg";



function PeopleAnalytics() {
    return (
        <section>
            <Container fluid="md" className="FeaturedSpecializationTab-section" id="FeaturedSpecializationTab">
                <Row className="Hor-Center" style={{padding:0}}>
                    <Col md={12} className="justify-content-center">
                        <Row >
                            <Col md={7} className="FeaturedSpecializationTabConent">
                               
                                <p className="FeaturedSpecializationTabdesc">Leverage your Oracle reports and analytics to create a world-class employee experience. Our specialists can take you beyond standard reporting to create more value for your business. </p>

                                <div className="FeaturedSpecializationTabdiv">
                                    <img
                                        src={Architects}
                                        alt="ArchitectsImage pic"
                                        className="img-fluid FeaturedSpecializationTabIcon"
                                    /><span>Data scientists and modelers to help you explore new value in your data.</span>
                                </div>
                                <div className="FeaturedSpecializationTabdiv">
                                    <img
                                        src={Temporary}
                                        alt="TemporaryImage pic"
                                        className="img-fluid FeaturedSpecializationTabIcon"
                                    /><span>Engineers and data managers to give you control and governance of your HCM data.</span>
                                </div>
                                <div className="FeaturedSpecializationTabdiv">
                                    <img
                                        src={multidisciplined}
                                        alt="multidisciplinedImage pic"
                                        className="img-fluid FeaturedSpecializationTabIcon"
                                    /><span>Analyst who can deliver real business intelligence at the point of need. </span>
                                </div>
                            </Col>
                            <Col md={5} >
                                <div className="FeaturedSpecializationTabImagediv">
                                    <img
                                        src={DayforceReportingandAnalyticsTabImage}
                                        alt="FeaturedSpecializationTabImage pic"
                                        className="img-fluid FeaturedSpecializationTabImg"
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default PeopleAnalytics;