
import React, { Component } from 'react';
import { Container, Row, Col } from "react-bootstrap";

class Featuredspecializations extends Component {
    state = { tabs: null, activeTab: null }

    componentDidMount() {
        const { data } = this.props;
        const activeTab = data.find(tabData => {
            return tabData.state === "active";
        })
        this.setState({ tabs: data, activeTab: activeTab.id })
    }

    handleClick(event, currentTab) {
        event.preventDefault();
        const newtabs = this.state.tabs;
        newtabs.forEach((tab, index) => {
            newtabs[index].state = tab.id === currentTab.id ? "active" : "inactive";
        })
        this.setState({ tabs: newtabs, activeTab: currentTab.id })
    }
    renderFeaturedTabs() {

        const { tabs } = this.state;

        return (
            <Col md={12} className="FS-Tabs" >
                <ul className="nav nav-tabs col-md-10">
                    {tabs.map((tab, index) => {
                        return (
                            <li className="nav-item" key={index}>
                                <a href="/" className={`nav-link ${tab.state}`} onClick={event => this.handleClick(event, tab)}>{tab.name}</a>
                            </li>
                        )
                    })}
                </ul>
            </Col>
        )
    }
    render() {
        const { tabs, activeTab } = this.state;
        const tabToShow = activeTab - 1;
        return (
            <section>
                <Container fluid className="FearturedSpecializations-section" id="FearturedSpecializations">
                    <Row>
                        <Col md={12} className="FearturedSpecializationsConent">
                            <h3 className="FearturedSpecializationstitle">Featured Specializations</h3>
                            <p className="Featureddesc">Our Dayforce experts can augment your internal team during your
                                implementation and beyond. We are your go-to source for specialized
                                Ceridian talent. </p>
                            <p className="Featureddesc"> With no-cost consulting services to discover your specific needs,
                                we match you to qualified specialists who can work with your team,
                                on-premises or remotely. </p>
                            <p className="Featureddesc">If you need skills not listed, talk with one of our consultants.
                                to get started. </p>
                            {tabs && this.renderFeaturedTabs()}
                            {tabs && tabs[tabToShow].content()}


                        </Col>
                    </Row>
                </Container>
            </section >
        );
    }
}

export default Featuredspecializations;







