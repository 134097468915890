import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { AiFillStar } from 'react-icons/ai'
import Slider from "react-slick";
import AdditionalInformation from "../../Assets/HomePageImages/Cloud.png";





function ServicePagecarousel() {
    var settings = {
        dots: false,
        className: "center",
        centerMode: true,       
        centerPadding: "0px",
        slidesToShow: 3,
        speed: 1000,
        responsive: [{
            breakpoint: 1100,
            settings: {
                dots: false,
                className: "center",
                centerMode: true,       
                centerPadding: "0px",            
                speed: 1000,
              slidesToShow: 1,
              slidesToScroll: 1
             
            }
          }]
    };
    return (
        <section>
            <Container fluid className="Carousel-section" id="Carousel">
                <Row>
                <h3 className="Carouselstitle">Customer <span>Testimonials</span></h3>
                <Slider {...settings}>
                <div>
                  <Col  className="CarouselsItem">
       
                                        <Col md={12} className="CarouselsContent">
                                                <Col md={12} className="AdditionalInformationImage">
                                                    <div className="AdditionalInformationImageBG"></div>
                                                    <div className="AdditionalInformationicondiv">
                                                        <div className="AdditionalInformationicon">
                                                            <img
                                                                src={AdditionalInformation}
                                                                alt="home pic"
                                                                className="img-fluid Carouselsicon"
                                                            />
                                                        </div>
                                                    </div>
                                                </Col>

                                                <Col md={12} className="CarouselsData">
                                                    <h6 className="CarouselsTitle">Name One</h6>
                                                    <p className="star"><AiFillStar /><AiFillStar /><AiFillStar /><AiFillStar /><AiFillStar /></p>
                                                    <p className="Carouselsdesc"> Moving your infrastructure and applications to the AdditionalInformation
                                                        requires a unique set of skills.</p>
                                                        <p className="readmore"><a href="/Readmore">Read More</a></p>
                                                </Col>
                                            
                                        </Col>
                                    </Col>
        </div>
        <div>
        <Col  className="CarouselsItem">
                                        <Col md={12} className="CarouselsContent">

                                                <Col md={12} className="AdditionalInformationImage">
                                                <div className="AdditionalInformationImageBG"></div>
                                                    <div className="AdditionalInformationicondiv">
                                                        <div className="AdditionalInformationicon">
                                                            <img
                                                                src={AdditionalInformation}
                                                                alt="home pic"
                                                                className="img-fluid Carouselsicon"
                                                            />
                                                        </div>
                                                    </div>
                                                </Col>

                                                <Col md={12} className="CarouselsData">
                                                <h6 className="CarouselsTitle">Name Two</h6>
                                                <p className="star"><AiFillStar /><AiFillStar /><AiFillStar /><AiFillStar /><AiFillStar /></p>
                                                    <p className="Carouselsdesc"> Moving your infrastructure and applications to the AdditionalInformation
                                                        requires a unique set of skills.</p>
                                                        <p className="readmore"><a href="/Readmore">Read More</a></p>

                                                </Col>
                                        </Col>
                                    </Col>
        </div>
        <div>
        <Col  className="CarouselsItem">
                                        <Col md={12} className="CarouselsContent">
                                                <Col md={12} className="AdditionalInformationImage">
                                                <div className="AdditionalInformationImageBG"></div>
                                                    <div className="AdditionalInformationicondiv">
                                                        <div className="AdditionalInformationicon">
                                                            <img
                                                                src={AdditionalInformation}
                                                                alt="home pic"
                                                                className="img-fluid Carouselsicon"
                                                            />
                                                        </div>
                                                    </div>
                                                </Col>

                                                <Col md={12} className="CarouselsData">
                                                <h6 className="CarouselsTitle">Name Three</h6>
                                                <p className="star"><AiFillStar /><AiFillStar /><AiFillStar /><AiFillStar /><AiFillStar /></p>
                                                    <p className="Carouselsdesc"> Moving your infrastructure and applications to the AdditionalInformation
                                                        requires a unique set of skills.</p>
                                                        <p className="readmore"><a href="/Readmore">Read More</a></p>
                                                </Col>
                                        </Col>
                                    </Col>
        </div>
        <div>
        <Col  className="CarouselsItem">
                                        <Col md={12} className="CarouselsContent">
                                                <Col md={12} className="AdditionalInformationImage">
                                                <div className="AdditionalInformationImageBG"></div>
                                                    <div className="AdditionalInformationicondiv">
                                                        <div className="AdditionalInformationicon">
                                                            <img
                                                                src={AdditionalInformation}
                                                                alt="home pic"
                                                                className="img-fluid Carouselsicon"
                                                            />
                                                        </div>
                                                    </div>
                                                </Col>

                                                <Col md={12} className="CarouselsData">
                                                <h6 className="CarouselsTitle">Name Four</h6>
                                                <p className="star"><AiFillStar /><AiFillStar /><AiFillStar /><AiFillStar /><AiFillStar /></p>
                                                    <p className="Carouselsdesc"> Moving your infrastructure and applications to the AdditionalInformation
                                                        requires a unique set of skills.</p>
                                                        <p className="readmore"><a href="Readmore">Read More</a></p>
                                                </Col>
                                        </Col>
                                    </Col>
        </div>
        <div>
        <Col  className="CarouselsItem">
                                        <Col md={12} className="CarouselsContent">
                                                <Col md={12} className="AdditionalInformationImage">
                                                <div className="AdditionalInformationImageBG"></div>
                                                    <div className="AdditionalInformationicondiv">
                                                        <div className="AdditionalInformationicon">
                                                            <img
                                                                src={AdditionalInformation}
                                                                alt="home pic"
                                                                className="img-fluid Carouselsicon"
                                                            />
                                                        </div>
                                                    </div>
                                                </Col>

                                                <Col md={12} className="CarouselsData">
                                                <h6 className="CarouselsTitle">Name Five</h6>
                                                <p className="star"><AiFillStar /><AiFillStar /><AiFillStar /><AiFillStar /><AiFillStar /></p>
                                                    <p className="Carouselsdesc"> Moving your infrastructure and applications to the AdditionalInformation
                                                        requires a unique set of skills.</p>
                                                        <p className="readmore"><a href="/Readmore">Read More</a></p>
                                                </Col>
                                        </Col>
                                    </Col>
        </div>
        <div>
        <Col  className="CarouselsItem">
                                        <Col md={12} className="CarouselsContent">
                                                <Col md={12} className="AdditionalInformationImage">
                                                <div className="AdditionalInformationImageBG"></div>
                                                    <div className="AdditionalInformationicondiv">
                                                        <div className="AdditionalInformationicon">
                                                            <img
                                                                src={AdditionalInformation}
                                                                alt="home pic"
                                                                className="img-fluid Carouselsicon"
                                                            />
                                                        </div>
                                                    </div>
                                                </Col>

                                                <Col md={12} className="CarouselsData">
                                                <h6 className="CarouselsTitle">Name Six</h6>
                                                <p className="star"><AiFillStar /><AiFillStar /><AiFillStar /><AiFillStar /><AiFillStar /></p>
                                                    <p className="Carouselsdesc"> Moving your infrastructure and applications to the AdditionalInformation
                                                        requires a unique set of skills.</p>
                                                        <p className="readmore"><a href="/Readmore">Read More</a></p>
                                                </Col>
                                        </Col>
                                    </Col>
        </div>
      </Slider>
                </Row>
            </Container>
           
        </section >
    );
}

export default ServicePagecarousel;