import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Footer from "./Footer";

import {
  AiOutlineTwitter,

  AiFillYoutube
} from "react-icons/ai";
import {
  FaFacebookF,
} from "react-icons/fa";


import { IoMdMail } from "react-icons/io"
import { FaLinkedinIn } from "react-icons/fa";

import { Link } from "react-router-dom";
import { IoLocationSharp } from "react-icons/io5";
import { MdCall } from "react-icons/md";
import { BiAt } from "react-icons/bi";


function Home2() {
  return (
    <Container fluid className="home-about-section block" id="about">
      <Container fluid="md" className="">
        <Row className="black justify-content-around" style={{padding:0}}>

          <Col md={4} sm={4} xs={4} className="home-about-description">
            <h5>
              Work with us
            </h5>
            <p className="home-about-body align">
              Sevices
            </p><p>
              Jobs
            </p>

          </Col>
          <Col md={4} sm={4} xs={4} className="home-about-description">
            <h5 >
              Who We are
            </h5>
            <p className="home-about-body align">
              <Link to="/about-us">About Us</Link>
            </p><p>
              Blogs
            </p><p>
              Resources
            </p><p>
              Privacy Policy
            </p><p>
              Terms of Use
            </p>
          </Col>
          <Col md={4} sm={4} xs={4} className="home-about-description">
            <h5 >
              Contact Us
            </h5>
            <p className="home-about-body align">
              <IoLocationSharp /> Phenom Staff <br/>
              1333 Corporate Dr, # 212 <br/>
              Irving, TX - 75038, USA
            </p><p>
              <MdCall /> +1(855)978-6816
            </p><p>
              <BiAt /> info@phenomstaff.com
            </p>
          </Col>
          </Row>
          <Row className="justify-content-center">
          <Col md={12} className="home-about-social">
            <hr />
            <ul className="home-about-social-links">

              <li className="social-icons">
                <a
                  href="/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaFacebookF />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiOutlineTwitter />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillYoutube />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <IoMdMail />
                </a>
              </li>

            </ul>
            <hr />
          </Col>
          </Row>
      </Container>
      <Footer />
    </Container>
  );
}
export default Home2;
