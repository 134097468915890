import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Architects from "../../Assets/HomePageImages/icons/Architects.svg";
import Temporary from "../../Assets/HomePageImages/icons/Temporary_talent_to_fill_a_gap.svg";
import multidisciplined from "../../Assets/HomePageImages/icons/A_multi_disciplined.svg";
import teammember from "../../Assets/HomePageImages/icons/A_new_team_member.svg";
import Technology from "../../Assets/HomePageImages/icons/On_call_Technology.svg";
import arroemark from "../../Assets/HomePageImages/spiralArrow.svg"


function Employers() {
    return (
        <section>
            <Container fluid className="Employers-section pad-null stud plate-height Disk_bg" id="Employers"  >
            <div id="disc-flip"></div>
            <img src={arroemark} alt="arrowImage pic" className="img-fluid arrow"/>
                <Row className="Hor-Center pad-10-30">
                    <Col md={12} sm={12} xs={12}>
                    <h3 className="Employerstitle">For Employers</h3>
                        <div className="border"></div>
                        <Row >
                        
                            <Col md={6} sm={6} xs={12} className="EmployersConent">                                
                                <p className="Employersdesc">Sure, we can take your order and fill it - but that is not why we founded Phenom Staff </p>
                                <p className="Employersdesc">We're on a mission to connect you to the right talent for your technology needs.</p>
                                <p className="Employersdesc">We start with a conversation about your company and your business, team, and talent needs.</p>
                                <p className="Employersdesc">Then, we work together to create a profile of your ideal team member.</p>
                                <p className="Employersdesc">Then we employ a rigorous assessment and selection process to ensure you will have the right fit</p>
                               
                               
                            </Col>
                            <Col md={6}  sm={6} xs={12} className="EmployersConent" >
                            <p className="Employersdesc">Whatever your needs, we can help you find that fits.</p>
                                <div className="Employersdiv">
                                    <img
                                        src={Architects}
                                        alt="ArchitectsImage pic"
                                        className="img-fluid EmployersIcon"
                                    /><span>Architects, administrators, engineers, business, and functional consultants to augment your team.</span>
                                </div>
                                <div className="Employersdiv">
                                    <img
                                        src={Temporary}
                                        alt="TemporaryImage pic"
                                        className="img-fluid EmployersIcon"
                                    /><span>Tempoporary talent to fill a gap </span>
                                </div>
                                <div className="Employersdiv">
                                    <img
                                        src={multidisciplined}
                                        alt="multidisciplinedImage pic"
                                        className="img-fluid EmployersIcon"
                                    /><span>A multi-disciplined project team.</span>
                                </div>
                                <div className="Employersdiv">
                                    <img
                                        src={teammember}
                                        alt="teammemberImage pic"
                                        className="img-fluid EmployersIcon"
                                    /><span>A new team member-contract hire a full-time employee</span>
                                </div>
                                <div className="Employersdiv">
                                    <img
                                        src={Technology}
                                        alt="TechnologyImage pic"
                                        className="img-fluid EmployersIcon"
                                    /><span>On-call Technology consultants</span>
                                </div>

                                
                            </Col>
                            <div className="EmployeeLinks">
                                    <div className="Link"> <a
                                        href="/"
                                        target="_blank"
                                        rel="noreferrer"
                                        className="EmployersLink">
                                        Call Now
                                    </a> </div><div className="Link"> <a
                                        href="/"
                                        target="_blank"
                                        rel="noreferrer"
                                        className="EmployersLink">
                                        Book an Appointment
                                    </a></div></div>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default Employers;