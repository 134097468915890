import React, { useState } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import { NavLink } from "react-router-dom";
import Companylogo from "../Assets/HomePageImages/Logo.svg"
import NavDropdown from 'react-bootstrap/NavDropdown';



function NavBar() {
  const [expand, updateExpanded] = useState(false);
  const [navColour, updateNavbar] = useState(false);

  function scrollHandler() {
    if (window.scrollY >= 20) {
      updateNavbar(true);
    } else {
      updateNavbar(false);
    }
  }

  window.addEventListener("scroll", scrollHandler);

  window.addEventListener("scroll", scrollHandler);

  return (
    <Navbar
      expanded={expand}
      fixed="top"
      expand="md"
      className={navColour ? "sticky" : "navbar"}
    >
      <Container fluid ='md'
      className="NavSection" >
        <Navbar.Brand href="/" className="d-flex">
          <img src={Companylogo} className="img-fluid logo" alt="brand" style={{ maxWidth: "250px" }} />
        </Navbar.Brand>
        
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          onClick={() => {
            updateExpanded(expand ? false : "expanded");
          }}>
          <span></span>
          <span></span>
          <span></span>
        </Navbar.Toggle>
        <Navbar.Collapse id="responsive-navbar-nav">
          
          
          <Nav className="ms-auto" defaultActiveKey="#home">

            <Nav.Item className="Nav-Item">
              <NavLink

                className="Nav-Link"
                to="Services"
                onClick={() => updateExpanded(false)}
              >

                Services
              </NavLink>
            </Nav.Item>

            <Nav.Item className="Nav-Item">
              <NavLink

                className="Nav-Link"
                to="/resume"
                onClick={() => updateExpanded(false)}>
                Jobs
              </NavLink>
            </Nav.Item>

            <Nav.Item className="Nav-Item">
              <NavLink

                to="about-us"
                className="Nav-Link"
                onClick={() => updateExpanded(false)}
              >
                About Us
              </NavLink>
            </Nav.Item>

            <Nav.Item className="Nav-Item">

              <NavDropdown  className="" title="Resources" id="basic-nav-dropdown">
              <NavDropdown.Item href="https://blog.phenomstaff.com/" className=" text-left text-md-left text-sm-center"> <span className="Nav-Link"> Blogs </span></NavDropdown.Item>
              <NavDropdown.Item href="https://resource.phenomstaff.com/"  className=" text-left text-md-left text-sm-center"> <span className="Nav-Link">Resources </span></NavDropdown.Item>
            </NavDropdown>
            </Nav.Item>

            <Nav.Item className="Nav-Item" style={{ paddingLeft: "10px" }}>
              <NavLink

                className="Nav-Link"
                to="/ContactUs"
                onClick={() => updateExpanded(false)}
              >
                Contact Us
              </NavLink>
            </Nav.Item>

          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}








export default NavBar;
