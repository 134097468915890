import React from "react";
import { Container, Row, Col } from "react-bootstrap";
//import home from "../../Assets/HomePageImages/Banner.svg";
import DayforceTalentManagementTabImage from "../../../../Assets/HomePageImages/UKG/UKG Dimensions.svg";
import Architects from "../../../../Assets/HomePageImages/UKG/Implementation.svg";
import Temporary from "../../../../Assets/HomePageImages/UKG/Administrators.svg";



function UKGDimensions() {
    return (
        <section>
            <Container fluid='md' className="FeaturedSpecializationTab-section" id="FeaturedSpecializationTab">
                <Row className="Hor-Center">
                    <Col md={12} style={{padding:0}}>
                        <Row className="justify-content-between">
                            <Col md={7} className="FeaturedSpecializationTabConent">
                                
                                <p className="FeaturedSpecializationTabdesc">The smooth, user-friendly functioning of UKG Pro’s suite of talent functions requires a robust internal engine with many moving parts. </p>
                                <p className="FeaturedSpecializationTabdesc">You need the best talent you can get.</p>
                               
                                <div className="FeaturedSpecializationTabdiv">
                                    <img
                                        src={Architects}
                                        alt="ArchitectsImage pic"
                                        className="img-fluid FeaturedSpecializationTabIcon"
                                    /><span>Implementation advisors who understand the importance of the employee work experience. </span>
                                </div>
                                <div className="FeaturedSpecializationTabdiv">
                                    <img
                                        src={Temporary}
                                        alt="TemporaryImage pic"
                                        className="img-fluid FeaturedSpecializationTabIcon"
                                    /><span>Administrators and support specialists who can help you optimize your AI-powered talent engine. </span>
                                </div>
                            </Col>
                            <Col md={5} >
                                <div className="FeaturedSpecializationTabImagediv">
                                    <img
                                        src={DayforceTalentManagementTabImage}
                                        alt="FeaturedSpecializationTabImage pic"
                                        className="img-fluid FeaturedSpecializationTabImg"
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default UKGDimensions;