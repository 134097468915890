import React from "react";
import { Container, Row, Col } from "react-bootstrap";
//import home from "../../../Assets/HomePageImages/Banner.svg";
import SAEImage from "../../../Assets/HomePageImages/Cybersecurity/Security Architect and Engineers.svg";
import Architects from "../../../Assets/HomePageImages/icons/Architects.svg";
import Temporary from "../../../Assets/HomePageImages/icons/Temporary_talent_to_fill_a_gap.svg";
import multidisciplined from "../../../Assets/HomePageImages/icons/A_multi_disciplined.svg";
import teammember from "../../../Assets/HomePageImages/icons/A_new_team_member.svg";
import Technology from "../../../Assets/HomePageImages/icons/On_call_Technology.svg";


function SAE() {
    return (
        <section>
            <Container fluid='md' className="SAE-section" id="SAE">
                <Row className="Hor-Center ">
                    <Col md={12}  className='px-md-0'>
                        <Row className="justify-content-between" style={{ alignItems: "center" }}>
                            <Col md={6} className='px-md-0'>
                                <div className="SAEImagediv">
                                    <img
                                        src={SAEImage}
                                        alt="SAEImage pic"
                                        className="img-fluid SAEImg"
                                    />
                                </div>
                            </Col>
                            <Col md={6} className="SAEConent px-md-0">
                                <h3 className="SAEtitle">Security Architects and Engineers </h3>
                                <p className="SAEdesc">Advanced data expertise to solve your most pressing security challenges: </p>
                                <div className="SAEdiv">
                                    <img
                                        src={Architects}
                                        alt="ArchitectsImage pic"
                                        className="img-fluid SAEIcon"
                                    /><span>Conduct risk assessments and design countermeasures. </span>
                                </div>
                                <div className="SAEdiv">
                                    <img
                                        src={Temporary}
                                        alt="TemporaryImage pic"
                                        className="img-fluid SAEIcon"
                                    /><span>Design and build security systems and safeguards. </span>
                                </div>
                                <div className="SAEdiv">
                                    <img
                                        src={multidisciplined}
                                        alt="multidisciplinedImage pic"
                                        className="img-fluid SAEIcon"
                                    /><span>Design and implement secure network solutions. </span>
                                </div>
                                <div className="SAEdiv">
                                    <img
                                        src={teammember}
                                        alt="teammemberImage pic"
                                        className="img-fluid SAEIcon"
                                    /><span>A new team member-contract hire a full-time employee</span>
                                </div>
                                <div className="SAEdiv">
                                    <img
                                        src={Technology}
                                        alt="TechnologyImage pic"
                                        className="img-fluid SAEIcon"
                                    /><span>Identify threats and develop protection measures. </span>
                                </div>


                            </Col>

                        </Row>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default SAE;