import React from "react";
import { Container, Row, Col } from "react-bootstrap";
//import home from "../../../Assets/HomePageImages/Banner.svg";
import CybersecurityAnalyticsImage from "../../../Assets/HomePageImages/Cybersecurity/Cybersecurity Analysts.svg";
import Architects from "../../../Assets/HomePageImages/icons/Architects.svg";
import Temporary from "../../../Assets/HomePageImages/icons/Temporary_talent_to_fill_a_gap.svg";
import multidisciplined from "../../../Assets/HomePageImages/icons/A_multi_disciplined.svg";



function CybersecurityAnalytics() {
    return (
        <section>
            <Container fluid="md" className="CybersecurityAnalytics-section" id="CybersecurityAnalytics">
                <Row className="Hor-Center">
                    <Col md={12}  className='px-md-0'>
                        <Row className="justify-content-between" style={{ alignItems: "center" ,padding:0}}>
                            <Col md={6}  className='px-md-0'>
                                <div className="CybersecurityAnalyticsImagediv">
                                    <img
                                        src={CybersecurityAnalyticsImage}
                                        alt="CybersecurityAnalyticsImage pic"
                                        className="img-fluid CybersecurityAnalyticsImg"
                                    />
                                </div>
                            </Col>
                            <Col md={6} className="CybersecurityAnalyticsConent px-md-0">
                                <h3 className="CybersecurityAnalyticstitle">Cybersecurity Analysts </h3>
                                <p className="CybersecurityAnalyticsdesc">Protect your hardware, software, and networks from cybercriminals, constantly aware of emerging threats. </p>

                                <div className="CybersecurityAnalyticsdiv">
                                    <img
                                        src={Architects}
                                        alt="ArchitectsImage pic"
                                        className="img-fluid CybersecurityAnalyticsIcon"
                                    /><span>Configure security tools, such as virus software, authentication methods, and vulnerability management. </span>
                                </div>
                                <div className="CybersecurityAnalyticsdiv">
                                    <img
                                        src={Temporary}
                                        alt="TemporaryImage pic"
                                        className="img-fluid CybersecurityAnalyticsIcon"
                                    /><span>Use reporting tools to maintain constant vigilance and report unusual activities. </span>
                                </div>
                                <div className="CybersecurityAnalyticsdiv">
                                    <img
                                        src={multidisciplined}
                                        alt="multidisciplinedImage pic"
                                        className="img-fluid CybersecurityAnalyticsIcon"
                                    /><span>Proactively evaluate and test for weaknesses. </span>
                                </div>

                            </Col>

                        </Row>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default CybersecurityAnalytics;