import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import BlogImage1 from "../../Assets/HomePageImages/Financial_Services.svg";
import BlogImage2 from "../../Assets/HomePageImages/Information_Technology.svg";
import BlogImage3 from "../../Assets/HomePageImages/BPO.svg";
import BlogImage4 from "../../Assets/HomePageImages/Retail.svg";
import BlogImage5 from "../../Assets/HomePageImages/Manufacturing.svg";
import BlogImage6 from "../../Assets/HomePageImages/Life_Science.svg";
import BlogImage7 from "../../Assets/HomePageImages/Insurance.svg";
import BlogImage8 from "../../Assets/HomePageImages/Construction.svg";
import BlogImage9 from "../../Assets/HomePageImages/Media.svg";

function IndustriesServed() {
    return (
        <section>
            <Container fluid className="IndustriesServed-section" id="IndustriesServed">
                <Row>
                    <Col md={12} className="IndustriesServedConent">
                        <Container fluid='md'>
                        <h3 className="IndustriesServedtitle">Industries Served</h3>
                        <Row className="justify-content-between" style={{padding:"0px"}}>
                            <Col md={2} sm={4} className="Card">
                                <Col md={12} className="CardContent">
                                    <img
                                        src={BlogImage1}
                                        alt="home pic"
                                        className="img-fluid Cardimg"/>
                                    <Col md={12} className="CardTitle">
                                        <h5>Financial Services</h5>
                                    </Col>
                                </Col>

                            </Col>
                            <Col md={2} sm={4} className="Card">
                                <Col md={12} className="CardContent">
                                    <img
                                        src={BlogImage2}
                                        alt="home pic"
                                        className="img-fluid Cardimg"
                                    />
                                    <Col md={12} className="CardTitle">
                                        <h5>Information Technology</h5>
                                    </Col>
                                </Col>
                            </Col>
                            <Col md={2} sm={4} className="Card">
                                <Col md={12} className="CardContent">
                                    <img
                                        src={BlogImage3}
                                        alt="home pic"
                                        className="img-fluid Cardimg"
                                    />
                                    <Col md={12} className="CardTitle">
                                        <h5>BPO & Shared Services</h5>
                                    </Col>
                                </Col>
                            </Col>
                            <Col md={2} sm={4} className="Card">
                                <Col md={12} className="CardContent">
                                    <img
                                        src={BlogImage4}
                                        alt="home pic"
                                        className="img-fluid Cardimg"
                                    />
                                    <Col md={12} className="CardTitle">
                                        <h5>Retail</h5>
                                    </Col>
                                </Col>
                            </Col>
                            <Col md={2} sm={4} className="Card">
                                <Col md={12} className="CardContent">
                                    <img
                                        src={BlogImage5}
                                        alt="home pic"
                                        className="img-fluid Cardimg"
                                    />
                                    <Col md={12} className="CardTitle">
                                        <h5>Manufacturing</h5>
                                    </Col>
                                </Col>
                            </Col>
                            <Col md={2} sm={4} className="Card" >
                                <Col md={12} className="CardContent">
                                    <img
                                        src={BlogImage6}
                                        alt="home pic"
                                        className="img-fluid Cardimg"
                                    />
                                    <Col md={12} className="CardTitle">
                                        <h5>Life Sciences</h5>
                                    </Col>
                                </Col>
                            </Col>
                            <Col md={2} sm={4} className="Card">
                                <Col md={12} className="CardContent">
                                    <img
                                        src={BlogImage7}
                                        alt="home pic"
                                        className="img-fluid Cardimg"
                                    />
                                    <Col md={12} className="CardTitle">
                                        <h5>Insurance</h5>
                                    </Col>
                                </Col>
                            </Col>
                            <Col md={2} sm={4} className="Card">
                                <Col md={12} className="CardContent">
                                    <img
                                        src={BlogImage8}
                                        alt="home pic"
                                        className="img-fluid Cardimg"
                                    />
                                    <Col md={12} className="CardTitle">
                                        <h5>Engineering & Construction</h5>
                                    </Col>
                                </Col>
                            </Col>
                            <Col md={2} sm={4} className="Card">
                                <Col md={12} className="CardContent">
                                    <img
                                        src={BlogImage9}
                                        alt="home pic"
                                        className="img-fluid Cardimg"
                                    />
                                    <Col md={12} className="CardTitle">
                                        <h5>Media & Entertainment</h5>
                                    </Col>
                                </Col>
                            </Col>
                        </Row>
                        </Container>
                        <p>Don't see your bussiness here? We're fast learners, and<br/> we love building new networks.Call us today</p>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <div className="Link"> <a
                                href="/"
                                target="_blank"
                                rel="noreferrer"
                                className="EmployersLink"
                            >
                                Call Now
                            </a> </div>
                        </div>

                    </Col>
                  
                </Row>
            </Container>
        </section >
    );
}

export default IndustriesServed;