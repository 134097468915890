import React from "react";
import './Cornerstone.css';

import './CornerstoneTabs.css';
import { Container, Row, Col } from "react-bootstrap";
import home from "../../../Assets/cornerstone/Cornerstone_BG.svg";
import BannerImage from "../../../Assets/cornerstone/Cornerstone_Image.svg";
import ClientSide from "../../../Assets/ceridianBannerIcons/Client_Side.svg";
import Support from "../../../Assets/ceridianBannerIcons/Support_and_Maintenance.svg";
import Reporting from "../../../Assets/ceridianBannerIcons/Reporting_and_Analytics.svg";
import Dayforce from "../../../Assets/ceridianBannerIcons/Dayforce_Optimization.svg";
import Employersandjobseakers from "../../Home/EmployersandJobSeakers";
import Jobseekers from "./JobSeekers";
import Employers from "./Employers";
import IndustriesServed from "../../Home/IndustriesServed";
import Featured from "../../Home/Featured";
import FearturedSpecializations from "./FeaturedSpecializations";
import Blogs from "../../Home/Blogs";

import MoreQuestions from "../../Home/MoreQuestions";
import AdditionalInformation from "../../Home/AdditionalInformation";


import LearningandDevelopment from "./Content/LearningandDevelopment.js";
import CoreHR from "./Content/CoreHR.js"
import TalentManagement from "./Content/TalentManagement.js";
import Integrations from "./Content/Integrations.js";
import ReportingandAnalytics from "./Content/ReportingandAnalytics.js";


import NavTab1 from "../../../Assets/Ceridian/Dayforce_HR.svg";
import NavTab2 from "../../../Assets/Ceridian/Dayforce_Payroll.svg";
import NavTab3 from "../../../Assets/Ceridian/Dayforce_Benefits.svg";
import NavTab4 from "../../../Assets/Ceridian/Dayforce_Talent_Management.svg";
import NavTab5 from "../../../Assets/Ceridian/Dayforce_Reporting_and_Analytics.svg";

const data1 = [
    
    {
        id: 1, name: <div><div className="Navtab"></div><div className="Navtab-Content">For Employers</div></div>, content: () => <Employers />, state: "active"
    },{
        id: 2, name: <div><div className="Navtab"></div><div className="Navtab-Content">For Consultants</div></div>, content: () => <Jobseekers />, state: "inactive"
    }
    
];


const data = [
    {
        id: 1, name: <div><div className="Navtab"><img
            src={NavTab1}
            alt="home pic"
            className="img-fluid "
        /></div><div className="Navtab-Content">Learning and Development</div></div>, content: () => <LearningandDevelopment />, state: "active"
    },
    {
        id: 2, name: <div><div className="Navtab"><img
            src={NavTab2}
            alt="home pic"
            className="img-fluid "
        /></div><div className="Navtab-Content">Core HR</div></div>, content: () => <CoreHR />, state: "inactive"
    },
    {
        id: 3, name: <div><div className="Navtab"><img
            src={NavTab3}
            alt="home pic"
            className="img-fluid "
        /></div><div className="Navtab-Content">Talent Management</div></div>, content: () => <TalentManagement />, state: "inactive"
    },
    {
        id: 4, name: <div><div className="Navtab"><img
            src={NavTab4}
            alt="home pic"
            className="img-fluid "
        /></div><div className="Navtab-Content">Integrations</div></div>, content: () => <Integrations />, state: "inactive"
    },
    {
        id: 5, name: <div><div className="Navtab"><img
            src={NavTab5}
            alt="home pic"
            className="img-fluid "
        /></div><div className="Navtab-Content">Reporting and Analytics</div></div>, content: () => <ReportingandAnalytics />, state: "inactive"
    }
];

function Cornerstone() {
    return (
        <section>
            <Container fluid className="home-section" id="Cornerstone">

                <Container fluid className="home-content">
                    <Row>
                        <Col md={12} className="px-0">
                            <Col md={12} className="BannerBlock px-0">
                                <img
                                    src={home}
                                    alt="home pic"
                                    className="img-fluid BannerBg"/>

                                <Col md={12} className="BannerConent px-0">
                                    <Container fluid='md'>
                                    <Row className="justify-content-center align-items-center">
                                    <Col md={6} sm={12} xs={12} className="BannerContext px-md-0">
                                        <h1 className="Bannertitle">Cornerstone OnDemand Talent & Staffing</h1>
                                        <p className="Bannerdesc">Optamizing the value of Your Technology: </p>


                                        <div className="BannerIcondiv">
                                            <span className="BannerIcon">
                                                <img
                                                    src={ClientSide}
                                                    alt="ArchitectsImage pic"
                                                    className="img-fluid EmployersIcon"
                                                />
                                            </span>
                                            <span className="Bannericondesc">Client-Side implementation Advisory</span>
                                        </div>
                                        <div className="BannerIcondiv">
                                            <span className="BannerIcon">
                                                <img
                                                    src={Support}
                                                    alt="TemporaryImage pic"
                                                    className="img-fluid EmployersIcon"
                                                />

                                            </span><span className="Bannericondesc">Phased and Ongoing Projects</span>
                                        </div>
                                        <div className="BannerIcondiv">
                                            <span className="BannerIcon">
                                                <img
                                                    src={Reporting}
                                                    alt="multidisciplinedImage pic"
                                                    className="img-fluid EmployersIcon"
                                                />
                                            </span><span className="Bannericondesc">Post-Implentation Support</span>
                                        </div>
                                        <div className="BannerIcondiv">
                                            <span className="BannerIcon">
                                                <img
                                                    src={Dayforce}
                                                    alt="teammemberImage pic"
                                                    className="img-fluid EmployersIcon"
                                                /></span><span className="Bannericondesc">Optimization</span>
                                        </div>                     <div className="BannerIcondiv">
                                            <span className="BannerIcon">
                                                <img
                                                    src={Dayforce}
                                                    alt="teammemberImage pic"
                                                    className="img-fluid EmployersIcon"
                                                /></span><span className="Bannericondesc">Road Mapping</span>
                                        </div>



                                        <Col style={{ marginTop: "3%", lineHeight: "0.7" }}> <a
                                            href="/"
                                            target="_blank"
                                            rel="noreferrer"
                                            className="BannerLink"

                                        >
                                            Join Our Network
                                        </a></Col>


                                    </Col>
                                    <Col md={6} sm={12} xs={12} className="BannerImage px-md-0">
                                        <img
                                            src={BannerImage}
                                            alt="home pic"
                                            className="img-fluid BannerImage1"
                                        />
                                    </Col>
                                    </Row>
                                    </Container>
                                </Col>
                            </Col>
                        </Col>
                    </Row>
                    <Employersandjobseakers data={data1}/> 
                    <IndustriesServed />
                    <FearturedSpecializations data={data} />
                    <Featured />
                    <Blogs />
                    <MoreQuestions />
                    <AdditionalInformation />
                </Container>
            </Container>

        </section >
    );
}

export default Cornerstone;
