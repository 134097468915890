import React, { useState, useMemo } from 'react'
import FieldInput from './FieldInput'
import { Button } from 'react-bootstrap'
import Form from 'react-bootstrap/Form';
// import ReactCountryFlag from "react-country-flag";
// import CountryDropdown from 'country-dropdown-with-flags-for-react';
import PhoneInput,{ isValidPhoneNumber} from "react-phone-input-2";
import Select from 'react-select'
import countryList from 'react-select-country-list'
import axios from "axios"



const ContactForm = () => {

  
  
  const [myData, setMyData] = useState([]);
  const [isError,setIsError]=useState("");

  const options = useMemo(() => countryList().getData(), []);
  const [num,setNum]=useState("")
  const [ctry,setCtry]=useState("")

  const [values,setValues] = useState({
    firstName:"",
    lastName:"",
    emailId:"",
    companyName:"",
    jobTitle:"",
    postalcode:"",
    comments:"",
  });

  const [validation, setValidation] = useState({
    firstName:"",
    lastName:"",
    emailId:"",
    companyName:"",
    jobTitle:"",
    postalcode:"",
    comments:"",
  })

const inputs=[
    {id:1,
    label:"First Name" ,
    name:"firstName",
    errormessage:"Must require more than 2 charecters and numbers not allowed",
    fieldtype:"text",
    Pattern:"[^0-9]*^[A-Za-z][a-zA-Z_]{2,16}$"
  },
  {id:2,
    label:"Last Name" ,
    name:"lastName",
    errormessage:"Must require more than 2 charecters and numbers not allowed",
    fieldtype:"text",
    Pattern:"[^0-9]*^[A-Za-z][a-zA-Z_]{2,16}$"
  },

  {id:3,
    label:"Business Email" ,
    name:"emailId",
    errormessage:"Must be a Valid email",
    fieldtype:"email",
    Pattern:"[a-z0-9]+@[a-z]+[.][a-z]{2,3}$"
  },

  {id:4,
    label:"Company Name" ,
    name:"companyName",
    errormessage:"Must require more than 2 charecters and numbers not allowed",
    fieldtype:"text",
    Pattern:"[^0-9]*^[A-Za-z][a-zA-Z_]{2,16}$"
  },

  {id:5,
    label:"Job Title",
    name:"jobTitle",
    errormessage:"Must require more than 2 charecters",
    fieldtype:"text",
    Pattern:"[^0-9]*^[A-Za-z][a-zA-Z_]{2,16}$"
  }]


 








  const handleValidate = (value) => {
    const isValid = isValidPhoneNumber(value);
    console.log({ isValid })
    return isValid
  }

// const xhr=new XMLHttpRequest;
const url= 'https://api.hsforms.com/submissions/v3/integration/secure/submit/1751195/fa4ae966-52e2-4cfe-9c67-c7a36e040a5f'
// const toKen ="Bearer pat-na1-ea70ca0e-92f3-4721-9da5-2c5514f88ebe"

const headers = {
  'Authorization' : "Bearer pat-na1-ea70ca0e-92f3-4721-9da5-2c5514f88ebe",
  // "origin": 'https://api.hsforms.com',
  'Content-Type': 'application/json',

}

 const leadData = {
    "fields": [
    {
      "name": "firstname",
      "value": values.firstName
    },
    {
      "name": "lastname",
      "value": values.lastName
    },
    {
      "name": "email",
      "value": values.emailId
    },
    {
      "name": "jobtitle",
      "value": values.jobTitle
    },
    {
      "name": "country_list_dropdown",
      "value": ctry
    },
    {
      "name": "phone",
      "value": num
    },
    {
      "name": "Zip",
      "value": values.postalcode
    },
   
    {
      "name": "comments_",
      "value": values.comments
    }]
  }


   const final_data = JSON.stringify(leadData)

   const postApiData=async () => {
    try{

      const res = await axios.post(url,headers
        
,final_data);
          }catch(error){
      setIsError(error.message)
        console.log("error data", error.message)
    }
  
  }
  const handleSubmit=((e)=>{
    e.preventDefault()
    // const data =new leadData(e.target)
  // console.log(Object.fromEntries(data.entries()))

  // console.log(values)
  postApiData()

  })
  //  xhr.open('POST', url);
  //  xhr.setRequestHeader('Content-Type', 'application/json');
  //  xhr.setRequestHeader("Authorization", toKen );


  //  xhr.onreadystatechange = function() {
  //   if(xhr.readyState == 4 && xhr.status == 200) { 
  //       alert(xhr.responseText); // Returns a 200 response if the submission is successful.
  //   } else if (xhr.readyState == 4 && xhr.status == 400){ 
  //       alert(xhr.responseText); // Returns a 400 error the submission is rejected.          
  //   } else if (xhr.readyState == 4 && xhr.status == 403){ 
  //       alert(xhr.responseText); // Returns a 403 error if the portal isn't allowed to post submissions.           
  //   } else if (xhr.readyState == 4 && xhr.status == 404){ 
  //       alert(xhr.responseText); //Returns a 404 error if the formGuid isn't found     
  //   }
  //  }

//  xhr.send(final_data)

  
  
  const handleChange=(e)=>{
  setValues({...values,[e.target.name]:e.target.value})
  }


  // const handlePhoneChange=(e)=>{
  //   setValues({...values,phoneNumber:e.target.value}  )
  // }


  const [countryName,setCountryName] = useState('')
  const handleCountryChange =(e)=>{
    setCountryName(e)
    if(countryName ){
      setValues({...values,country: e.value})
    }
    if(num){
      setValues({...values,phoneNumber:num})
    }
    console.log(e.label)
  }
  const[focused,setFocused]=useState('')
  const handleFocus=(e)=>{
setFocused(true)
  }

  // const [num,setnum] =useState('')
  // const handlePhoneChange =(e)=>{
  // setnum(e.target.value)
 

// }
 
  return (
    <Form onSubmit={handleSubmit}>
    {inputs.map((input)=>
    <FieldInput  key={input.id} {...input} value={values[input.name]} handleChange={handleChange}/>)}
    
    
    <Form.Group className="mb-3" controlId="formBasicEmail">
      <Form.Label>Country</Form.Label>
      <Select  className='Inputvalues' options={options} required name="countryName" value={ctry} onChange={setCtry} onBlur={handleFocus} focused={focused.toString()} />
      <Form.Text className="text-danger erm"> please select Country</Form.Text>
    </Form.Group>


    <Form.Group className="mb-3" controlId="formBasicEmail">
      <Form.Label>Phone Number</Form.Label>
      <PhoneInput country={"us"} className="marginBottom Inputvalues" name="phoneNumber" rules={{
           validate: (value) => handleValidate(value)
    }} required value={values.num} onChange={setNum}/>
      <Form.Text className="text-danger erm"> please select Country</Form.Text>
    </Form.Group>


    <Form.Group className="mb-3" controlId="formBasicEmail">
      <Form.Label>Postal Code</Form.Label>
      <Form.Control  name="postalCode" type="text"
        value={values.postalcode} 
    onChange = {e => setValues({...values,postalcode:e.target.value})}
     />
    </Form.Group>

    <Form.Group className="mb-3" controlId="formBasicEmail">
      <Form.Label>comments</Form.Label>
      <Form.Control  name="comments" type="text" as="textarea" rows={3}
        value={values.comments} 
    onChange = {e => setValues({...values,comments : e.target.value})}
     />
    </Form.Group>

    <Button variant="primary" type="submit"> Submit </Button>
    </Form>
  )
  }
export default ContactForm
