import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import DeliversValueImage1 from "../../Assets/ServicesAssets/Increase Your Flexibility.svg";
import DeliversValueImage2 from "../../Assets/ServicesAssets/Reduce Cost.svg";
import DeliversValueImage3 from "../../Assets/ServicesAssets/Hire Faster.svg";

function DeliversValue() {
    return (
        <section>
            <Container fluid="md" className="DeliversValue-section" id="DeliversValue">
                <Row className="Hor-Center">
                    <Col md={12} className="px-md-0">
                    <h3 className="DeliversValuetitle">How Phenom Staff Delivers Values to You</h3>
                        <p className="DeliversValuedesc">The cost of our services more than the direct costs of doing it yourself, but when you consider the cost of salaries and benefits for your internal team, the faster time to hire, and administrative services, your total value is much greater.</p>
                 
                            <Row className='justify-content-between'>
                            
                            <Col md={5} className="DeliversValueConent px-md-0 order-md-first order-sm-last order-last">
                                <div className="DeliversValueConentheading"><div className="BulletPoint"></div><h4 className="DeliversValuecardtitle">Increase Your Flexibility</h4></div><div className="borderbottom"></div>
                                <p className="DeliversValuecarddesc">Covering absences, leaves, and special projects can be challenging when you rely only on traditional hiring. Then, when you hire, you must train your new employee to get them up to speed. We provide ready talent with the skills to perform on day one.</p>
                                <p className="DeliversValuecarddesc">When you have a temporary workload increase or must ramp up to a new level quickly, you don’t need to burn your people out and lose them or crush your labor budget with overtime.</p>
                                <p className="DeliversValuecarddesc">You can work with us to get temporary or contract staff quickly. Then, when the crisis passes, you can end the contract instead of enduring the pain and risks of layoffs.</p>
                            </Col>
                            <Col md={5} className="px-md-0 order-md-last order-sm-last order-first">
                                <div className="DeliversValueImagediv ">
                                    <img
                                        src={DeliversValueImage1}
                                        alt="DeliversValueImage pic"
                                        className="img-fluid DeliversValueImg"
                                    />
                                </div>
                            </Col>
                            </Row>

                            <Row className='justify-content-between'>                           
                            <Col md={5} className="px-md-0 ">
                                <div className="DeliversValueImagediv">
                                    <img
                                        src={DeliversValueImage2}
                                        alt="DeliversValueImage pic"
                                        className="img-fluid DeliversValueImg"
                                    />
                                </div>
                            </Col>
                            <Col md={5} className="DeliversValueConent px-md-0 ">
                            <div className="DeliversValueConentheading"><div className="BulletPoint"></div><h4 className="DeliversValuecardtitle">Reduce Cost</h4></div><div className="borderbottom"></div>
                                <p className="DeliversValuecarddesc">Professional staffing can lower your recruiting costs because you are not paying salaries and benefits for your staff’s time to complete a hire.</p>
                                <p className="DeliversValuecarddesc">If you hire a contractor or freelancer, you don’t have payroll taxes, unemployment compensation, health benefits, or 401K plans. Your Accounts Payable team handles the transaction, so you have no payroll processing to do.</p>
                                <p className="DeliversValuecarddesc">Gig workers can be contractors or freelancers, and you can hire them for only a few hours or as long as you need them.</p>
                                <p className="DeliversValuecarddesc">The staffing firm will do the pre-employment screening as a part of our service. You don’t incur any of those costs.</p>
                                
                            </Col>
                           </Row>

                           <Row className='justify-content-between '>
                            <Col md={5} className="DeliversValueConent px-md-0 order-md-first order-sm-last order-last">
                            <div className="DeliversValueConentheading"><div className="BulletPoint"></div><h4 className="DeliversValuecardtitle">Hire Faster</h4></div><div className="borderbottom"></div>
                                <p className="DeliversValuecarddesc">In our 25+ years of leading, working with, and being part of technology teams, we have developed a wide network of specialists.</p>
                                <p className="DeliversValuecarddesc">That means we often already have the talent you need in our network. You don’t have to post jobs everywhere and wait forever for the right candidate to appear.</p>
                                <p className="DeliversValuecarddesc">And, if you do need a search, we do it for you, leveraging our knowledge of the industry and where the talent is.</p>
                                
                            </Col>
                            <Col md={5} className="px-md-0 order-md-last order-sm-last order-first" >
                                <div className="DeliversValueImagediv">
                                    <img
                                        src={DeliversValueImage3}
                                        alt="DeliversValueImage pic"
                                        className="img-fluid DeliversValueImg"
                                    />
                                </div>
                            </Col>
                        </Row>

                    </Col>
                </Row>
            </Container >
        </section >
    );
}

export default DeliversValue;