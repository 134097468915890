import React from "react";
import './Workday.css';
import './WorkdayTabs.css';
import { Container, Row, Col } from "react-bootstrap";
import home from "../../../Assets/HomePageImages/Workday/Workday_BG.svg";
import BannerImage from "../../../Assets/HomePageImages/Workday/Workday_Image.svg";
import ClientSide from "../../../Assets/ceridianBannerIcons/Client_Side.svg";
import Support from "../../../Assets/ceridianBannerIcons/Support_and_Maintenance.svg";
import Reporting from "../../../Assets/ceridianBannerIcons/Reporting_and_Analytics.svg";
import Dayforce from "../../../Assets/ceridianBannerIcons/Dayforce_Optimization.svg";
import Employersandjobseakers from "../../Home/EmployersandJobSeakers";
import Jobseekers from "./JobSeekers";
import Employers from "./Employers";
import IndustriesServed from "../../Home/IndustriesServed";
import Featured from "../../Home/Featured";
import FearturedSpecializations from "./FeaturedSpecializations";
import Blogs from "../../Home/Blogs";
// import Tabs from "./Accordian";
import MoreQuestions from "../../Home/MoreQuestions";
import AdditionalInformation from "../../Home/AdditionalInformation";



import WorkdayCoreHCM from "./Content/WorkdayCoreHCM.js";
import WorkdayTalentOptimization from "./Content/WorkdayTalentOptimization.js"
import PeopleAnalytics from "./Content/PeopleAnalytics.js";
import Integrations from "./Content/Integrations.js";

import NavTab1 from "../../../Assets/Ceridian/Dayforce_HR.svg";
import NavTab2 from "../../../Assets/Ceridian/Dayforce_Payroll.svg";
import NavTab3 from "../../../Assets/Ceridian/Dayforce_Benefits.svg";
import NavTab4 from "../../../Assets/Ceridian/Dayforce_Talent_Management.svg";

const data1 = [
    
    {
        id: 1, name: <div><div className="Navtab"></div><div className="Navtab-Content">For Employers</div></div>, content: () => <Employers />, state: "active"
    },{
        id: 2, name: <div><div className="Navtab"></div><div className="Navtab-Content">For Consultants</div></div>, content: () => <Jobseekers />, state: "inactive"
    }
    
];

const data = [
    {
        id: 1, name: <div><div className="Navtab"><img
            src={NavTab1}
            alt="home pic"
            className="img-fluid "
        /></div><div className="Navtab-Content">Workday Core HCM </div></div>, content: () => <WorkdayCoreHCM />, state: "active"
    },
    {
        id: 2, name: <div><div className="Navtab"><img
            src={NavTab2}
            alt="home pic"
            className="img-fluid "
        /></div><div className="Navtab-Content">Workday Talent Optimization </div></div>, content: () => <WorkdayTalentOptimization />, state: "inactive"
    },
    {
        id: 3, name: <div><div className="Navtab"><img
            src={NavTab3}
            alt="home pic"
            className="img-fluid "
        /></div><div className="Navtab-Content">People Analytics</div></div>, content: () => <PeopleAnalytics />, state: "inactive"
    },
    {
        id: 4, name: <div><div className="Navtab"><img
            src={NavTab4}
            alt="home pic"
            className="img-fluid "
        /></div><div className="Navtab-Content">Integrations</div></div>, content: () => <Integrations />, state: "inactive"
    }

];

function Workday() {
    return (
        <section>
            <Container fluid className="home-section" id="Workday">

                <Container fluid className="home-content">
                    <Row>
                        <Col md={12} className='px-0'>
                            <Col md={12} className="BannerBlock px-0">
                                <img
                                    src={home}
                                    alt="home pic"
                                    className="img-fluid BannerBg"
                                />

                                <Col md={12} className="BannerConent px-md-0">
                                <Container fluid='md'>
                                    <Row className='justify-content-center align-items-center'>
                                    <Col md={6} sm={12} xs={12} className="BannerContext px-md-0">
                                        <h1 className="Bannertitle">Workday Talent and Staffing </h1>
                                        <p className="Bannerdesc">Bringing you talent that will deliver the value and capability of Workday</p>


                                        <div className="BannerIcondiv">
                                            <span className="BannerIcon">
                                                <img
                                                    src={ClientSide}
                                                    alt="ArchitectsImage pic"
                                                    className="img-fluid EmployersIcon"
                                                />
                                            </span>
                                            <span className="Bannericondesc">Client-Side implementation Advisory</span>
                                        </div>
                                        <div className="BannerIcondiv">
                                            <span className="BannerIcon">
                                                <img
                                                    src={Support}
                                                    alt="TemporaryImage pic"
                                                    className="img-fluid EmployersIcon"
                                                />

                                            </span><span className="Bannericondesc">Optimization of your Workday Platform</span>
                                        </div>
                                        <div className="BannerIcondiv">
                                            <span className="BannerIcon">
                                                <img
                                                    src={Reporting}
                                                    alt="multidisciplinedImage pic"
                                                    className="img-fluid EmployersIcon"
                                                />
                                            </span><span className="Bannericondesc">Road Mapping to maximize the value of your technology investments</span>
                                        </div>
                                        <div className="BannerIcondiv">
                                            <span className="BannerIcon">
                                                <img
                                                    src={Dayforce}
                                                    alt="teammemberImage pic"
                                                    className="img-fluid EmployersIcon"
                                                /></span><span className="Bannericondesc">Administration and support specilaists</span>
                                        </div>


                                        <Col style={{ marginTop: "3%", lineHeight: "0.7" }}> <a
                                            href="/"
                                            target="_blank"
                                            rel="noreferrer"
                                            className="BannerLink"

                                        >
                                            Join Our Network
                                        </a></Col>


                                    </Col>
                                    <Col md={6} sm={12} xs={12} className="BannerImage px-md-0">
                                        <img
                                            src={BannerImage}
                                            alt="home pic"
                                            className="img-fluid BannerImage1"
                                        />
                                    </Col>
                                    </Row>
                                </Container>
                                </Col>
                            </Col>
                        </Col>
                    </Row>
                    <Employersandjobseakers data={data1}/> 
                    <IndustriesServed />
                    <FearturedSpecializations data={data} />
                    <Featured />
                    <Blogs />
                    <MoreQuestions />
                    <AdditionalInformation />
                </Container>
            </Container>

        </section >
    );
}

export default Workday;
