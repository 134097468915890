import React from "react";

import { Container, Row, Col } from "react-bootstrap";
import home from "../../Assets/AboutUs/About_Us_BG.svg";
import BannerImage from "../../Assets/AboutUs/About us.svg";
import HWA from "../../Assets/AboutUs/Who We Are Icon.svg";
import OMI from "../../Assets/AboutUs/Our Mission Icon.svg";
import OGP from "../../Assets/AboutUs/Our Guiding Principles Icon.svg";

import WhoWeAre from "./WhoWeAre";
import OurMission from "./OurMission";
import OurGuidingPrinciples from "./OurGuidingPrinciples";

// import Particle from "../Particle";

function About() {
    return (
        <Container fluid className='AboutPage' id="aboutus">
            <Container fluid>
                <Row className="justify-content-between">
                    <Col md={12} className="BannerBlock">
                        <img
                            src={home}
                            alt="home pic"
                            className="img-fluid BannerBg"
                        />

                        <Col md={12} className="BannerConent">
                           < Container fluid='md'>
                            <Row className="justify-content-between align-items-center">
                            <Col md={6} sm={12} xs={12} className="BannerContext px-md-0">
                                <h1 className="Bannertitle">Phenom Talent &amp; Staffing</h1>
                                <div className="BannerIcondiv">
                                    <span className="BannerIcon">
                                        <img
                                            src={HWA}
                                            alt="ArchitectsImage pic"
                                            className="img-fluid EmployersIcon"
                                        />
                                    </span>
                                    <span className="Bannericondesc"> Human Capital Management</span>
                                </div>
                                <div className="BannerIcondiv">
                                    <span className="BannerIcon">
                                        <img
                                            src={OMI}
                                            alt="TemporaryImage pic"
                                            className="img-fluid EmployersIcon"
                                        />

                                    </span><span className="Bannericondesc">Cloud Technology</span>
                                </div>
                                <div className="BannerIcondiv">
                                    <span className="BannerIcon">
                                        <img
                                            src={OGP}
                                            alt="multidisciplinedImage pic"
                                            className="img-fluid EmployersIcon"
                                        />
                                    </span><span className="Bannericondesc">Cybersecurity</span>
                                </div>
                                <Col style={{ marginTop: "5%", lineHeight: "0.7", padding:0}}> <a
                                    href="/"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="BannerLink"
                                >
                                    Join Our Network
                                </a></Col>


                            </Col>
                            <Col md={6} sm={12} xs={12} className="BannerImage px-md-0">
                                <img
                                    src={BannerImage}
                                    alt="home pic"
                                    className="img-fluid BannerImage1"
                                />
                            </Col>
                            </Row>
                            </Container>
                        </Col>
                    </Col>
                </Row>
                <WhoWeAre />
                <OurMission />
                <OurGuidingPrinciples />
            </Container>
        </Container >
    );
}
export default About;