import React from "react";
import { Container, Row, Col } from "react-bootstrap";
//import home from "../../Assets/HomePageImages/Banner.svg";
import DayforcePayrollImage from "../../../../Assets/Ceridian/Dayforce_Payroll_Image.svg";
import Architects from "../../../../Assets/HomePageImages/oracle/Client-side implementation.svg";
import Temporary from "../../../../Assets/HomePageImages/oracle/On-call post.svg";



function DayforcPayrollAndBenefits() {
    return (
        <section>
            <Container fluid='md' className="FeaturedSpecializationTab-section" id="FeaturedSpecializationTab">
                <Row className="Hor-Center">
                    <Col md={12} style={{padding:0}}>
                        <Row className="justify-content-center">
                            <Col md={7} className="FeaturedSpecializationTabConent">
                                
                                <p className="FeaturedSpecializationTabdesc">You can rely on our specialists for technical support and advice on optimizing the value of your core HCM technology. </p>
                                
                                <div className="FeaturedSpecializationTabdiv">
                                    <img
                                        src={Architects}
                                        alt="ArchitectsImage pic"
                                        className="img-fluid FeaturedSpecializationTabIcon"
                                    /><span>Client-side implementation consultants for aligning payroll and benefits with your organization’s needs. </span>
                                </div>
                                <div className="FeaturedSpecializationTabdiv">
                                    <img
                                        src={Temporary}
                                        alt="TemporaryImage pic"
                                        className="img-fluid FeaturedSpecializationTabIcon"
                                    /><span>On-call post-implementation support specialists for configuration, localization, maintenance, and auditing. </span>
                                </div>
                            </Col>
                            <Col md={5} >
                                <div className="FeaturedSpecializationTabImagediv">
                                    <img
                                        src={DayforcePayrollImage}
                                        alt="FeaturedSpecializationTabImage pic"
                                        className="img-fluid FeaturedSpecializationTabImg"
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default DayforcPayrollAndBenefits;