import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Activity1 from "../../Assets/HomePageImages/Accountant.jpg";
import Activity2 from "../../Assets/HomePageImages/ProjectCoordinator.jpg";
import Activity3 from "../../Assets/HomePageImages/SalesManager.jpg";
import { AiFillClockCircle } from "react-icons/ai";
import { IoLocationSharp } from "react-icons/io5";
import { FaMoneyBillWaveAlt } from "react-icons/fa";


function Featured() {
    return (
        <section>
            <Container fluid='md' className="Featured-section" id="Featured">
                <Row 
                // style={{ justifyContent: "center" }}
                >
                    <Col md={12} className="FeaturedConent">
                        <h3 className="Featuredtitle">Featured Jobs</h3>
                        <p className="Featureddesc">
                            Here are some opportunities with the best companies in the market today.<br></br>
                            Join our network and we can work together to find you What fits you</p>
                            </Col>
                        <Row className="justify-content-between" style={{padding:0}}>
                            
                            <Col md={3} sm={12} xs={12} 
                            style={{padding:0}}
                            >
                                <Col md={12} className="FeaturedContent">
                                    <img
                                        src={Activity1}
                                        alt="home pic"
                                        className="img-fluid Featuredimg"
                                    />
                                    <Col md={12} className="FeaturedData">
                                        <h6 className="FeaturedTitle">Accountant</h6>
                                        <Col md={12}>
                                            <IoLocationSharp /> <span>Location</span>
                                        </Col>
                                        <Col md={12}>
                                            <FaMoneyBillWaveAlt /> <span>Package</span>
                                        </Col>
                                        <Col md={12}>
                                            <AiFillClockCircle /> <span>Full time/Part time</span>
                                        </Col>
                                      
                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", }}>
                                            <div className="Link"> <a
                                                href="/"
                                                target="_blank"
                                                rel="noreferrer"
                                                className="EmployersLink"
                                            >
                                                View Details
                                            </a>
                                            </div>
                                        </div>
                                    </Col>
                                </Col>
                            </Col>
                            <Col md={3} sm={12} xs={12} 
                            style={{padding:0}}
                            >
                                <Col md={12} className="FeaturedContent">
                                    <img
                                        src={Activity2}
                                        alt="home pic"
                                        className="img-fluid Featuredimg"
                                    />
                                    <Col md={12} className="FeaturedData" >
                                        <h6 className="FeaturedTitle">Project Coordinator</h6>
                                        <Col md={12}>
                                            <IoLocationSharp /> <span>Location</span>
                                        </Col>
                                        <Col md={12}>
                                            <FaMoneyBillWaveAlt /> <span>Package</span>
                                        </Col>
                                        <Col md={12}>
                                            <AiFillClockCircle /> <span>Full time/Part time</span>
                                        </Col>
                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", }}>
                                            <div className="Link"> <a
                                                href="/"
                                                target="_blank"
                                                rel="noreferrer"
                                                className="EmployersLink"
                                            >
                                                View Details
                                            </a>
                                            </div>
                                        </div>
                                    </Col>
                                </Col>
                            </Col>
                            <Col md={3} sm={12} xs={12} 
                            style={{padding:0}}
                            >
                                <Col md={12} className="FeaturedContent">
                                    <img
                                        src={Activity3}
                                        alt="home pic"
                                        className="img-fluid Featuredimg"
                                    />
                                    <Col md={12} className="FeaturedData">
                                        <h6 className="FeaturedTitle">Sales Manager</h6>
                                        <Col md={12}>
                                            <IoLocationSharp /> <span>Location</span>
                                        </Col>
                                        <Col md={12}>
                                            <FaMoneyBillWaveAlt /> <span>Package</span>
                                        </Col>
                                        <Col md={12}>
                                            <AiFillClockCircle /> <span>Full time/Part time</span>
                                        </Col>
                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                            <div className="Link"> <a
                                                href="/"
                                                target="_blank"
                                                rel="noreferrer"
                                                className="EmployersLink"
                                            >
                                                View Details
                                            </a>
                                            </div>
                                        </div>
                                    </Col>
                                </Col>
                            </Col>


                        </Row>
                        <div className="FeaturedLinkdiv py-5"> <a
                            href="/"
                            target="_blank"
                            rel="noreferrer"
                            className="AllFeaturedLink"
                        >
                            View All Jobs
                        </a></div>
                </Row>
            </Container>
        </section >
    );
}

export default Featured;