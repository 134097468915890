import React from "react";
import './Oracle.css';
import './OracleTabs.css';
import { Container, Row, Col } from "react-bootstrap";
import home from "../../../Assets/HomePageImages/oracle/Oracle_BG.svg";
import BannerImage from "../../../Assets/HomePageImages/oracle/Oracle_Image.svg";
import ClientSide from "../../../Assets/ceridianBannerIcons/Client_Side.svg";
import Support from "../../../Assets/ceridianBannerIcons/Support_and_Maintenance.svg";
import Reporting from "../../../Assets/ceridianBannerIcons/Reporting_and_Analytics.svg";
import Employersandjobseakers from "../../Home/EmployersandJobSeakers";
import Jobseekers from "./JobSeekers";
import Employers from "./Employers";
import IndustriesServed from "../../Home/IndustriesServed";
import Featured from "../../Home/Featured";
import FearturedSpecializations from "./FeaturedSpecializations";
import Blogs from "../../Home/Blogs";
// import Tabs from "./Accordian";

import MoreQuestions from "../../Home/MoreQuestions";
import AdditionalInformation from "../../Home/AdditionalInformation";

import OraclePayrollbenefits from "./Content/OraclePayroll&benefits.js";
import TalentManagement from "./Content/TalentManagement.js"
import Integrations from "./Content/Integrations.js";
import PeopleAnalytics from "./Content/PeopleAnalytics.js";


import NavTab1 from "../../../Assets/HomePageImages/oracle/HCM_Payroll.svg";
import NavTab2 from "../../../Assets/HomePageImages/oracle/TM.svg";
import NavTab3 from "../../../Assets/HomePageImages/oracle/PA.svg";
import NavTab4 from "../../../Assets/HomePageImages/oracle/Integrations.svg";

const data1 = [
    
    {
        id: 1, name: <div><div className="Navtab"></div><div className="Navtab-Content">For Employers</div></div>, content: () => <Employers />, state: "active"
    },{
        id: 2, name: <div><div className="Navtab"></div><div className="Navtab-Content">For Consultants</div></div>, content: () => <Jobseekers />, state: "inactive"
    }
    
];
const data = [
    {
        id: 1, name: <div><div className="Navtab"><img
            src={NavTab1}
            alt="home pic"
            className="img-fluid "
        /></div><div className="Navtab-Content">Oracle HCM Payroll/Benefits</div></div>, content: () => <OraclePayrollbenefits />, state: "active"
    },
    {
        id: 2, name: <div><div className="Navtab"><img
            src={NavTab2}
            alt="home pic"
            className="img-fluid "
        /></div><div className="Navtab-Content">Talent Management</div></div>, content: () => <TalentManagement />, state: "inactive"
    }, {
        id: 3, name: <div><div className="Navtab"><img
            src={NavTab4}
            alt="home pic"
            className="img-fluid "
        /></div><div className="Navtab-Content">People Analytics</div></div>, content: () => <PeopleAnalytics />, state: "inactive"
    },
    {
        id: 4, name: <div><div className="Navtab"><img
            src={NavTab3}
            alt="home pic"
            className="img-fluid "
        /></div><div className="Navtab-Content">Integrations</div></div>, content: () => <Integrations />, state: "inactive"
    }

];

function Oracle() {
    return (
        <section>
            <Container fluid className="home-section" id="Oracle">

                <Container fluid className="home-content">
                    <Row>
                        <Col md={12} style={{padding:0}}>
                            <Col md={12} className="BannerBlock" style={{padding:0}}>
                                <img
                                    src={home}
                                    alt="home pic"
                                    className="img-fluid BannerBg"
                                />

                                <Col md={12} className="BannerConent px-md-0">
                                    <Container fluid="md ">
                                    <Row className="justify-content-center align-items-center ">
                                    <Col md={6} sm={12} xs={12} className="BannerContext px-md-0">
                                        <h1 className="Bannertitle">Oracle HCM Cloud Talent & Staffing</h1>
                                        <p className="Bannerdesc">Bringing your talent that will deliver the value and capability of Oracle HCM in the cloud</p>


                                        <div className="BannerIcondiv">
                                            <span className="BannerIcon">
                                                <img
                                                    src={ClientSide}
                                                    alt="ArchitectsImage pic"
                                                    className="img-fluid EmployersIcon"
                                                />
                                            </span>
                                            <span className="Bannericondesc">Client-Side implementation Advisory</span>
                                        </div>
                                        <div className="BannerIcondiv">
                                            <span className="BannerIcon">
                                                <img
                                                    src={Support}
                                                    alt="TemporaryImage pic"
                                                    className="img-fluid EmployersIcon"
                                                />

                                            </span><span className="Bannericondesc">Optimize Oracle for your business</span>
                                        </div>
                                        <div className="BannerIcondiv">
                                            <span className="BannerIcon">
                                                <img
                                                    src={Reporting}
                                                    alt="multidisciplinedImage pic"
                                                    className="img-fluid EmployersIcon"
                                                />
                                            </span><span className="Bannericondesc">Leverage Oracle HCM for the employee experience</span>
                                        </div>
                                        <Col style={{ marginTop: "3%", lineHeight: "0.7" }}> <a
                                            href="/"
                                            target="_blank"
                                            rel="noreferrer"
                                            className="BannerLink"

                                        >
                                            Join Our Network
                                        </a></Col>


                                    </Col>
                                    <Col md={6} sm={12} xs={12} className="BannerImage px-md-0">
                                        <img
                                            src={BannerImage}
                                            alt="home pic"
                                            className="img-fluid BannerImage1"
                                        />
                                    </Col>
                                    </Row>
                                    </Container>
                                </Col>
                            </Col>
                        </Col>
                    </Row>
                    <Employersandjobseakers data={data1}/> 
                    <IndustriesServed />
                    <FearturedSpecializations data={data} />
                    <Featured />
                    <Blogs />
                    <MoreQuestions />
                    <AdditionalInformation />
                </Container>
            </Container>

        </section >
    );
}

export default Oracle;
