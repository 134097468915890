import React from "react";
import { Container, Row, Col } from "react-bootstrap";
//import home from "../../Assets/HomePageImages/Banner.svg";
import DayforceBenefitsTabImage from "../../../../Assets/Ceridian/Dayforce_Benefits_Image.svg";
import Architects from "../../../../Assets/HomePageImages/ceradian/Fast response.svg";
import Temporary from "../../../../Assets/HomePageImages/ceradian/Address and.svg";
import MultiDisciplined from "../../../../Assets/HomePageImages/ceradian/We provide on-call.svg";
import WeProvide from "../../../../Assets/HomePageImages/ceradian/We provide on-call.svg";



function DayforceBenefits() {
    return (
        <section>
            <Container fluid='md' className="FeaturedSpecializationTab-section" id="FeaturedSpecializationTab">
                <Row className="Hor-Center">
                    <Col md={12} style={{padding:0}}>
                        <Row className="justify-content-between">
                            <Col md={7} className="FeaturedSpecializationTabConent">
                                
                                <p className="FeaturedSpecializationTabdesc">When your benefits configuration and processes need attention, you need to troubleshoot and resolve the issues before they impact your employees. </p>
                                <p className="FeaturedSpecializationTabdesc">Our Dayforce Benefits specialists will review your processes with you and recommend improvements to give your people a smooth, painless process. </p>
                              
                                <div className="FeaturedSpecializationTabdiv">
                                    <img
                                        src={Architects}
                                        alt="ArchitectsImage pic"
                                        className="img-fluid FeaturedSpecializationTabIcon"
                                    /><span>Fast response to support issues. </span>
                                </div>
                                <div className="FeaturedSpecializationTabdiv">
                                    <img
                                        src={Temporary}
                                        alt="TemporaryImage pic"
                                        className="img-fluid FeaturedSpecializationTabIcon"
                                    /><span>Address and resolve issues before they become a problem. </span>
                                </div>
                                <div className="FeaturedSpecializationTabdiv">
                                    <img
                                        src={MultiDisciplined}
                                        alt="multidisciplinedImage pic"
                                        className="img-fluid FeaturedSpecializationTabIcon"
                                    /><span>Review and test your entire workflow to help you prepare for open enrollment. </span>
                                </div>
                                <div className="FeaturedSpecializationTabdiv">
                                    <img
                                        src={WeProvide}
                                        alt="multidisciplinedImage pic"
                                        className="img-fluid FeaturedSpecializationTabIcon"
                                    /><span>We provide on-call experts who resolve problems quickly, so you can respond to issues without delay. </span>
                                </div>


                            </Col>
                            <Col md={5} >
                                <div className="FeaturedSpecializationTabImagediv">
                                    <img
                                        src={DayforceBenefitsTabImage}
                                        alt="FeaturedSpecializationTabImage pic"
                                        className="img-fluid FeaturedSpecializationTabImg"
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default DayforceBenefits;