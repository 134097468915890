import React from "react";
import { Container, Row, Col,} from "react-bootstrap";
import BannerImage from "../../Assets/HomePageImages/Phenom Talent and Staffing.svg";
import HCM from "../../Assets/HomePageImages/homeBannerIcons/Human_Capital_Manaegment.svg";
import CloudTechnology from "../../Assets/HomePageImages/homeBannerIcons/Cloud_Technology.svg";
import Cybersecuritylogo from "../../Assets/HomePageImages/homeBannerIcons/Cybersecurity_logo.svg";
import Analytics from "../../Assets/HomePageImages/homeBannerIcons/Analytics.svg";


import Employers from "./Employers";
import Jobseekers from "./JobSeekers";
import Employersandjobseakers from "./EmployersandJobSeakers"
import IndustriesServed from "./IndustriesServed";
import Featured from "./Featured";

import FearturedSpecializations from "./FeaturedSpecializations";
import Blogs from "./Blogs";
import VenderSpecilizations from "./VenderSpecilizations"
import SpacilizedTalent from "./MoreQuestions"
import AdditionalInformation from "./AdditionalInformation";

const data = [
    
    {
        id: 1, name: <div><div className="Navtab"></div><div className="Navtab-Content">For Employers</div></div>, content: () => <Employers />, state: "active"
    },{
        id: 2, name: <div><div className="Navtab"></div><div className="Navtab-Content">For Consultants</div></div>, content: () => <Jobseekers />, state: "inactive"
    }
    
];
function Home() {
    return (
        <section>
            <Container fluid className="home-section" id="home">

                <Container fluid className="home-content">
                    <Row>
                        {/* <Col md={12} className="BannerBlock"> */}
                            <Col md={12} className="BannerBlock px-0" >
                             
                            <div className="moon-1"></div>
                            <div className="moon-2"></div>
                            <div className="moon-3"></div>
                            <div className="moon-4"></div>
                           < Container fluid='md'>
                                <Row className="BannerConent align-items-center justify-content-md-between  justify-content-sm-center">
                                    <Col md={6} sm={12} xs={12} className="BannerContext px-md-0 py-sm-5" >
                                        <div className="pad-sm-xx">
                                        <h1 className="Bannertitle">Phenom Talent &amp; Staffing</h1>
                                        <p className="Bannerdesc">We connect talent and successful companies </p>

                                        <div className="BannerIcondiv">
                                            <span className="BannerIcon">
                                                <img
                                                    src={HCM}
                                                    alt="ArchitectsImage pic"
                                                    className="img-fluid"
                                                />
                                            </span>
                                            <span className="Bannericondesc"> Human Capital Management</span>
                                        </div>
                                        <div className="BannerIcondiv">
                                            <span className="BannerIcon">
                                                <img
                                                    src={CloudTechnology}
                                                    alt="TemporaryImage pic"
                                                    className="img-fluid"
                                                />

                                            </span><span className="Bannericondesc">Cloud Technology</span>
                                        </div>
                                        <div className="BannerIcondiv">
                                            <span className="BannerIcon">
                                                <img
                                                    src={Cybersecuritylogo}
                                                    alt="multidisciplinedImage pic"
                                                    className="img-fluid"
                                                />
                                            </span><span className="Bannericondesc">Cybersecurity</span>
                                        </div>
                                        <div className="BannerIcondiv">
                                            <span className="BannerIcon">
                                                <img
                                                    src={Analytics}
                                                    alt="teammemberImage pic"
                                                    className="img-fluid "
                                                /></span><span className="Bannericondesc">Analytics</span>
                                        </div>

                                        <Col style={{ marginTop: "3%", lineHeight: "0.7" }}> <a
                                            href="/"
                                            target="_blank"
                                            rel="noreferrer"
                                            className="BannerLink"

                                        >
                                            Join Our Network
                                        </a></Col>


                                        </div></Col>
                                    <Col md={6} sm={12} xs={12} className="BannerImage px-md-0" >
                                        <img
                                            src={BannerImage}
                                            alt="home pic"
                                            className="img-fluid BannerImage1 mx-auto w-sm-50"
                                        />
                                    </Col>

                                </Row>
                             </Container>
                            </Col>
                        {/* </Col> */}
                    </Row>
                    
                    <Employersandjobseakers data={data}/>
                    {/* <Jobseekers />
                    <Employers /> */}
                    <IndustriesServed />
                    <FearturedSpecializations />
                    <VenderSpecilizations />
                    <Featured />
                    <Blogs />
                    <SpacilizedTalent />
                    <AdditionalInformation />
                    {/* <Footer1 /> */}
                
            </Container>
            </Container>
        </section >
    );
}


export default Home;
