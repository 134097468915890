import React from "react";
import { Container, Row, Col } from "react-bootstrap";
//import home from "../../../Assets/HomePageImages/Banner.svg";
import CloudArchitectImage from "../../../Assets/HomePageImages/CloudTechnology/Cloud Architects.svg";
import Architects from "../../../Assets/HomePageImages/icons/Architects.svg";
import Temporary from "../../../Assets/HomePageImages/icons/Temporary_talent_to_fill_a_gap.svg";
import multidisciplined from "../../../Assets/HomePageImages/icons/A_multi_disciplined.svg";


function CloudArchitect() {
    return (
        <section>
            <Container fluid="md" className="CloudArchitect-section" id="CloudArchitect">
                <Row className="Hor-Center  py-3" >
                    <Col md={12} className='px-md-0' >
                        <Row style={{ alignItems: "center" }} className="justify-content-between">
                            <Col md={6} className='px-md-0' >
                                <div className="CloudArchitectImagediv">
                                    <img
                                        src={CloudArchitectImage}
                                        alt="CloudArchitectImage pic"
                                        className="img-fluid CloudArchitectImg"
                                    />
                                </div>
                            </Col>
                            <Col md={6} className="CloudArchitectConent px-md-0" >
                                <h3 className="CloudArchitecttitle">Cloud Architects </h3>
                                <p className="CloudArchitectdesc"> You may need a cloud architect to be your cloud champion, design your cloud infrastructure, or expand it. </p>
                                <div className="CloudArchitectdiv">
                                    <img
                                        src={Architects}
                                        alt="ArchitectsImage pic"
                                        className="img-fluid CloudArchitectIcon"
                                    /><span>Plan architecture and solutions. </span>
                                </div>
                                <div className="CloudArchitectdiv">
                                    <img
                                        src={Temporary}
                                        alt="TemporaryImage pic"
                                        className="img-fluid CloudArchitectIcon"
                                    /><span>Develop strategy, guidelines, and policies. </span>
                                </div>
                                <div className="CloudArchitectdiv">
                                    <img
                                        src={multidisciplined}
                                        alt="multidisciplinedImage pic"
                                        className="img-fluid CloudArchitectIcon"
                                    /><span>Estimate costs and manage on-budget delivery of cloud infrastructure and resources. </span>
                                </div>


                            </Col>

                        </Row>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default CloudArchitect;