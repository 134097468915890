import React from "react";
import { Container, Row, Col } from "react-bootstrap";
//import home from "../../Assets/HomePageImages/Banner.svg";
import DayforceReportingandAnalyticsTabImage from "../../../../Assets/HomePageImages/Workday/Workday Core HCM.svg";
import Architects from "../../../../Assets/HomePageImages/Workday/client side.svg";
import Temporary from "../../../../Assets/HomePageImages/Workday/post.svg";
// import multidisciplined from "../../../../Assets/HomePageImages/Workday/Consultants.svg";



function WorkdayTalentOptimization() {
    return (
        <section>
            <Container fluid='md' className="FeaturedSpecializationTab-section" id="FeaturedSpecializationTab">
                <Row className="Hor-Center">
                    <Col md={12} style={{padding:0}}>
                        <Row className="justify-content-between">
                            <Col md={7} className="FeaturedSpecializationTabConent">
                              
                                <p className="FeaturedSpecializationTabdesc">You can rely on our specialists for technical support and advice on optimizing the value of your core technology.</p>

                                <div className="FeaturedSpecializationTabdiv">
                                    <img
                                        src={Architects}
                                        alt="ArchitectsImage pic"
                                        className="img-fluid FeaturedSpecializationTabIcon"
                                    /><span>Client-side implementation consultants for aligning HR functions and business processes to your organization’s needs. </span>
                                </div>
                                <div className="FeaturedSpecializationTabdiv">
                                    <img
                                        src={Temporary}
                                        alt="TemporaryImage pic"
                                        className="img-fluid FeaturedSpecializationTabIcon"
                                    /><span>Post-implementation support specialists for configuration, localization, maintenance, and auditing. </span>
                                </div>

                            </Col>
                            <Col md={5} >
                                <div className="FeaturedSpecializationTabImagediv">
                                    <img
                                        src={DayforceReportingandAnalyticsTabImage}
                                        alt="FeaturedSpecializationTabImage pic"
                                        className="img-fluid FeaturedSpecializationTabImg"
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default WorkdayTalentOptimization;