import React from "react";
import { Container, Row, Col } from "react-bootstrap";
//import home from "../../../Assets/HomePageImages/Banner.svg";
import PeopleAnalyticsScientistsArchitectsAndEngineersImage from "../../../Assets/HomePageImages/PeopleAnalytics/People Analytics Scientists.svg";
import Architects from "../../../Assets/HomePageImages/icons/Architects.svg";
import Temporary from "../../../Assets/HomePageImages/icons/Temporary_talent_to_fill_a_gap.svg";
import multidisciplined from "../../../Assets/HomePageImages/icons/A_multi_disciplined.svg";



function PeopleAnalyticsScientistsArchitectsAndEngineers() {
    return (
        <section>
            <Container fluid='md' className="PeopleAnalyticsScientistsArchitectsAndEngineers-section" id="PeopleAnalyticsScientistsArchitectsAndEngineers">
                <Row className="Hor-Center">
                    <Col md={12} className="px-md-0">
                        <Row style={{ alignItems: "center" }}>
                            <Col md={6} className="px-md-0">
                                <div className="PeopleAnalyticsScientistsArchitectsAndEngineersImagediv">
                                    <img
                                        src={PeopleAnalyticsScientistsArchitectsAndEngineersImage}
                                        alt="PeopleAnalyticsScientistsArchitectsAndEngineersImage pic"
                                        className="img-fluid PeopleAnalyticsScientistsArchitectsAndEngineersImg"
                                    />
                                </div>
                            </Col>
                            <Col md={6} className="PeopleAnalyticsScientistsArchitectsAndEngineersConent px-md-0" >
                                <h3 className="PeopleAnalyticsScientistsArchitectsAndEngineerstitle">People Analytics Scientists, Architects, and Engineers</h3>
                                <p className="PeopleAnalyticsScientistsArchitectsAndEngineersdesc">Advanced data expertise to solve your most pressing workforce challenges: </p>

                                <div className="PeopleAnalyticsScientistsArchitectsAndEngineersdiv">
                                    <img
                                        src={Architects}
                                        alt="ArchitectsImage pic"
                                        className="img-fluid PeopleAnalyticsScientistsArchitectsAndEngineersIcon"
                                    /><span>Design and implement “zero trust” systems and procedures to protect data and privacy. </span>
                                </div>
                                <div className="PeopleAnalyticsScientistsArchitectsAndEngineersdiv">
                                    <img
                                        src={Temporary}
                                        alt="TemporaryImage pic"
                                        className="img-fluid PeopleAnalyticsScientistsArchitectsAndEngineersIcon"
                                    /><span>Integrate people and enterprise data for better insights. </span>
                                </div>
                                <div className="PeopleAnalyticsScientistsArchitectsAndEngineersdiv">
                                    <img
                                        src={multidisciplined}
                                        alt="multidisciplinedImage pic"
                                        className="img-fluid PeopleAnalyticsScientistsArchitectsAndEngineersIcon"
                                    /><span>Identify and explore new data sources. </span>
                                </div>
                            </Col>

                        </Row>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default PeopleAnalyticsScientistsArchitectsAndEngineers;