import React from "react";
import { Container, Row, Col } from "react-bootstrap";
//import home from "../../Assets/HomePageImages/Banner.svg";
import JobSeekersImage from "../../../Assets/HomePageImages/For Consultants.svg";
import arroemark from "../../../Assets/HomePageImages/spiralArrow.svg"
function JobSeekers() {
    return (
        <section>
            <Container fluid className="Jobseekers-section pad-null Disk_bg" id="JobSeekers"><div id="disc-flip"></div>
            <img
                                        src={arroemark}
                                        alt="arrowImage pic"
                                        className="img-fluid arrow"
                                    />
                <Row className="Hor-Center pad-10-30">
                    <Col md={12} sm={12} xs={12} >
                    <h3 className="Jobseekerstitle">For Consultants</h3>
                        <div className="border"></div>
                        <Row >
                       
                        <Col md={7} sm={7} xs={12} className="JobseekersConent">
                               
                                
                                <p className="Jobseekersdesc">Let us shine a light on your unique skill set so you can continue to grow</p>
                                <Col md={12} className="JobseekersEmail">
                                    <h6 > Find the right gig, right now</h6>
                                    <div > <a
                                        href="/"
                                        target="_blank"
                                        rel="noreferrer"
                                        className="JobseekersLink"
                                    >
                                        Join Our Network
                                    </a></div>
                                    <p className="Jobseekersdesc">Get Notified of New Opportunities</p>
                                    <form style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                        <label style={{ width: "65%" }} >
                                            <input type="email" placeholder="Enter your Email" name="name" style={{ width: "100%", height: "36px", padding: " 0 20px", borderRadius: "20px 0 0 20px", border: "2px solid #b7b7b7", borderRight: "0px" }} />
                                        </label>
                                        <input type="submit" value="Submit" style={{ width: "35%", height: "36px", background: "#e7432c",    backgroundImage: "linear-gradient(100deg, #f14242 0%, #ed9720 100%)", border: "none", color: "#ffffff", borderRadius: "0px 20px 20px 0px", lineHeight: 0 }} />
                                    </form>
                                </Col>
                            </Col>
                            <Col md={5} sm={5} xs={12} >
                                <div className="JobseekersImagediv">
                                    <img
                                        src={JobSeekersImage}
                                        alt="JobSeekersImage pic"
                                        className="img-fluid JobSeekersImg"
                                    />
                                </div>
                            </Col>
                           
                        </Row>
                    </Col>
                </Row>
            </Container >
        </section >
    );
}

export default JobSeekers;