import React from "react";
import { Container, Row, Col ,Card} from "react-bootstrap";
import { Link } from "react-router-dom";
import Cloud from "../../Assets/HomePageImages/Cloud.png";
import Cybersecurity from "../../Assets/HomePageImages/Cybersecurity2.png";
import DataScience from "../../Assets/HomePageImages/Data_Science.png";
import DigitalTransformation from "../../Assets/HomePageImages/Digital_Transformation.png";
import EnterpriseAnalytics from "../../Assets/HomePageImages/Enterprise_Analytics.png";
import PeopleAnalytics from "../../Assets/HomePageImages/People_Analytics.png";
import CloudIcon from "../../Assets/HomePageImages/Featured Specializations/Cloud_Icon.svg";
import CybersecurityIcon from "../../Assets/HomePageImages/Featured Specializations/Cybersecurity_Icon.svg";
import DataScienceIcon from "../../Assets/HomePageImages/Featured Specializations/Data_Science_Icon.svg";
import DigitalTransformationIcon from "../../Assets/HomePageImages/Featured Specializations/Digital_Transformation_Icon.svg";
import EnterpriseAnalyticsIcon from "../../Assets/HomePageImages/Featured Specializations/Enterprise_Analytics_Icon.svg";
import PeopleAnalyticsIcon from "../../Assets/HomePageImages/Featured Specializations/People_Analytics_Icon.svg";
import RepeatGrid from "../../Assets/HomePageImages/RepeatGrid.svg";


function FearturedSpecializations() {
    return (
        <section>
            <Container fluid className="FearturedSpecializations-section" id="FearturedSpecializations">
                <img src={RepeatGrid} alt="home pic" className="img-fluid RepeatGrid right" />
                <img src={RepeatGrid} alt="home pic" className="img-fluid RepeatGrid left bottom" />
                <Row >
                    <Col md={12} className="FearturedSpecializationsConent" >
                        <h3 className="FearturedSpecializationstitle">Featured Specializations</h3>
<Container fluid="md">
                        <Row className="Bring-front justify-content-around">
                            <Col md={12} style={{padding:"0"}}>
                                <Row >
                                    <Col md={4} sm={6} xs={12} className="FearturedSpecializationsItem ">
                                        <Col md={12} className="FearturedSpecializationsContent">
                                            <Link to="/Cloud" className="FearturedSpecializationPageLink">
                                                <Card>                                              
                                                    <Card.Img variant="top" src={Cloud}/>
                                                    
                                                    <Card.Body className="FearturedSpecializationsData">
                                                    <div className="Cloudicondiv">
                                                        <div className="Cloudicon">
                                                            <img
                                                                src={CloudIcon}
                                                                alt="home pic"
                                                                className="img-fluid FearturedSpecializationsicon"
                                                            />
                                                        </div>
                                                    </div>  
                                                        <Card.Title className="FearturedSpecializationsTitle">Cloud</Card.Title>
                                                        <Card.Text className="FearturedSpecializationsdesc">Moving your infrastructure and applications to the cloud requires a unique set of skills.</Card.Text>
                                                    </Card.Body>
                                                </Card>



                                                {/* <Col md={12} className="CloudImage">
                                                    <img
                                                        src={Cloud}
                                                        alt="home pic"
                                                        className="img-fluid FearturedSpecializationsimg"
                                                    />
                                                    <div className="Cloudicondiv">
                                                        <div className="Cloudicon">
                                                            <img
                                                                src={CloudIcon}
                                                                alt="home pic"
                                                                className="img-fluid FearturedSpecializationsicon"
                                                            />
                                                        </div>
                                                    </div>
                                                </Col>

                                                <Col md={12} className="FearturedSpecializationsData">
                                                    <h6 className="FearturedSpecializationsTitle">Cloud</h6>
                                                    <p className="FearturedSpecializationsdesc"> Moving your infrastructure and applications to the Cloud
                                                        requires a unique set of skills.</p>


                                                </Col> */}
                                            </Link>
                                        </Col>
                                    </Col>
                                    
                                     <Col md={4} sm={6} xs={12} className="FearturedSpecializationsItem">
                                        <Col md={12} className="FearturedSpecializationsContent">
                                            <Link to="/Cybersecurity" className="FearturedSpecializationPageLink">
                                            <Card>                                              
                                                    <Card.Img variant="top" src={Cybersecurity}/>
                                                    
                                                    <Card.Body className="FearturedSpecializationsData">
                                                    <div className="Cloudicondiv">
                                                        <div className="Cloudicon">
                                                            <img
                                                                src={CybersecurityIcon}
                                                                alt="home pic"
                                                                className="img-fluid FearturedSpecializationsicon"
                                                            />
                                                        </div>
                                                    </div>  
                                                        <Card.Title className="FearturedSpecializationsTitle">Cyber Security</Card.Title>
                                                        <Card.Text className="FearturedSpecializationsdesc">You need highly qualified people to protect your
                                                        infraastructure, devices, network, and data.</Card.Text>
                                                    </Card.Body>
                                                </Card>
                                                {/* <Col md={12} className="CloudImage">
                                                    <img
                                                        src={Cybersecurity}
                                                        alt="home pic"
                                                        className="img-fluid FearturedSpecializationsimg"
                                                    />
                                                    <div className="Cloudicondiv">
                                                        <div className="Cloudicon">
                                                            <img
                                                                src={CybersecurityIcon}
                                                                alt="home pic"
                                                                className="img-fluid FearturedSpecializationsicon"
                                                            />
                                                        </div>
                                                    </div>
                                                </Col> */}
                                                {/* <Col md={12} className="FearturedSpecializationsData">
                                                    <h6 className="FearturedSpecializationsTitle">Cybersecurity</h6>
                                                    <p className="FearturedSpecializationsdesc">You need highly qualified people to protect your
                                                        infraastructure, devices, network, and data.</p>


                                                </Col> */}
                                            </Link>
                                        </Col>
                                    </Col> 
                                    <Col md={4} sm={6} xs={12} className="FearturedSpecializationsItem">
                                        <Col md={12} className="FearturedSpecializationsContent">
                                            <Link to="/Datascience" className="FearturedSpecializationPageLink">
                                            <Card>                                              
                                                    <Card.Img variant="top" src={DataScience}/>
                                                    <Card.Body className="FearturedSpecializationsData">
                                                    <div className="Cloudicondiv">
                                                        <div className="Cloudicon">
                                                            <img
                                                                src={DataScienceIcon}
                                                                alt="home pic"
                                                                className="img-fluid FearturedSpecializationsicon"/>
                                                        </div>
                                                    </div>  
                                                        <Card.Title className="FearturedSpecializationsTitle">Data Science</Card.Title>
                                                        <Card.Text className="FearturedSpecializationsdesc">Not every business requires a data scientist,
                                                        but when you do,you'll want the best you can get.</Card.Text>
                                                    </Card.Body>
                                                </Card>
                                                {/* <Col md={12} className="CloudImage">
                                                    <img
                                                        src={DataScience}
                                                        alt="home pic"
                                                        className="img-fluid FearturedSpecializationsimg"
                                                    />
                                                    <div className="Cloudicondiv">
                                                        <div className="Cloudicon">
                                                            <img
                                                                src={DataScienceIcon}
                                                                alt="home pic"
                                                                className="img-fluid FearturedSpecializationsicon"
                                                            />
                                                        </div>
                                                    </div>
                                                </Col> */}
                                                {/* <Col md={12} className="FearturedSpecializationsData">
                                                    <h6 className="FearturedSpecializationsTitle">Data Science</h6>
                                                    <p className="FearturedSpecializationsdesc"> Not every business requires a data scientisr,
                                                        but when you do,you'll want the best you can get.</p>
                                                </Col> */}
                                            </Link>
                                        </Col>
                                    </Col>
                                    <Col md={4} sm={6} xs={12} className="FearturedSpecializationsItem">
                                        <Col md={12} className="FearturedSpecializationsContent">

                                            <Link to="/DigitalTransformation" className="FearturedSpecializationPageLink">
                                            <Card>                                              
                                                    <Card.Img variant="top" src={DigitalTransformation}/>
                                                    
                                                    <Card.Body className="FearturedSpecializationsData">
                                                    <div className="Cloudicondiv">
                                                        <div className="Cloudicon">
                                                            <img
                                                                src={DigitalTransformationIcon}
                                                                alt="home pic"
                                                                className="img-fluid FearturedSpecializationsicon"
                                                            />
                                                        </div>
                                                    </div>  
                                                        <Card.Title className="FearturedSpecializationsTitle">Digital Transformation</Card.Title>
                                                        <Card.Text className="FearturedSpecializationsdesc">Digital transformation isn't a strategic goal-it's the path you will follow to reach your goals as they evolve.</Card.Text>
                                                    </Card.Body>
                                                </Card>
                                                {/* <Col md={12} className="CloudImage">
                                                    <img
                                                        src={DigitalTransformation}
                                                        alt="home pic"
                                                        className="img-fluid FearturedSpecializationsimg"
                                                    />
                                                    <div className="Cloudicondiv">
                                                        <div className="Cloudicon">
                                                            <img
                                                                src={DigitalTransformationIcon}
                                                                alt="home pic"
                                                                className="img-fluid FearturedSpecializationsicon"
                                                            />
                                                        </div>
                                                    </div>
                                                </Col> */}
                                                {/* <Col md={12} className="FearturedSpecializationsData">
                                                    <h6 className="FearturedSpecializationsTitle">Digital Transformation</h6>
                                                    <p className="FearturedSpecializationsdesc">Digital transformation isn't a strategic goal-it's the path you will follow to reach your goals as they evolve. </p>

                                                </Col> */}
                                            </Link>
                                        </Col>
                                    </Col>
                                    <Col md={4} sm={6} xs={12} className="FearturedSpecializationsItem">
                                        <Col md={12} className="FearturedSpecializationsContent">

                                            <Link to="/EnterpriseAnalytics" className="FearturedSpecializationPageLink">
                                            <Card>                                              
                                                    <Card.Img variant="top" src={EnterpriseAnalytics}/>
                                                    
                                                    <Card.Body className="FearturedSpecializationsData">
                                                    <div className="Cloudicondiv">
                                                        <div className="Cloudicon">
                                                            <img
                                                                src={EnterpriseAnalyticsIcon}
                                                                alt="home pic"
                                                                className="img-fluid FearturedSpecializationsicon"
                                                            />
                                                        </div>
                                                    </div>  
                                                        <Card.Title className="FearturedSpecializationsTitle">Enterprise Analytics</Card.Title>
                                                        <Card.Text className="FearturedSpecializationsdesc">When you rely on data to make the right decisions for your business,you need the best
                                                        talent you can get.</Card.Text>
                                                    </Card.Body>
                                                </Card>
                                                {/* <Col md={12} className="CloudImage">
                                                    <img
                                                        src={EnterpriseAnalytics}
                                                        alt="home pic"
                                                        className="img-fluid FearturedSpecializationsimg"
                                                    />
                                                    <div className="Cloudicondiv">
                                                        <div className="Cloudicon">
                                                            <img
                                                                src={EnterpriseAnalyticsIcon}
                                                                alt="home pic"
                                                                className="img-fluid FearturedSpecializationsicon"
                                                            />
                                                        </div>
                                                    </div>
                                                </Col> */}
                                                {/* <Col md={12} className="FearturedSpecializationsData">
                                                    <h6 className="FearturedSpecializationsTitle">Enterprise Analytics</h6>
                                                    <p className="FearturedSpecializationsdesc">When you rely on data to make the right decisions for your business,you need the best
                                                        talent you can get.</p>


                                                </Col> */}
                                            </Link>

                                        </Col>
                                    </Col>
                                    <Col md={4} sm={6} xs={12} className="FearturedSpecializationsItem">
                                        <Col md={12} className="FearturedSpecializationsContent">

                                            <Link to="/PeopleAnalytics" className="FearturedSpecializationPageLink">
                                            <Card>                                              
                                                    <Card.Img variant="top" src={PeopleAnalytics}/>
                                                    
                                                    <Card.Body className="FearturedSpecializationsData">
                                                    <div className="Cloudicondiv">
                                                        <div className="Cloudicon">
                                                            <img
                                                                src={PeopleAnalyticsIcon}
                                                                alt="home pic"
                                                                className="img-fluid FearturedSpecializationsicon"
                                                            />
                                                        </div>
                                                    </div>  
                                                        <Card.Title className="FearturedSpecializationsTitle">Human Capital Management</Card.Title>
                                                        <Card.Text className="FearturedSpecializationsdesc">Implementation, optimization, content, and support for human capital management software platforms.</Card.Text>
                                                    </Card.Body>
                                                </Card>
                                                {/* <Col md={12} className="CloudImage">
                                                    <img
                                                        src={PeopleAnalytics}
                                                        alt="home pic"
                                                        className="img-fluid FearturedSpecializationsimg"
                                                    />
                                                    <div className="Cloudicondiv">
                                                        <div className="Cloudicon">
                                                            <img
                                                                src={PeopleAnalyticsIcon}
                                                                alt="home pic"
                                                                className="img-fluid FearturedSpecializationsicon"
                                                            />
                                                        </div>
                                                    </div>
                                                </Col> */}
                                                {/* <Col md={12} className="FearturedSpecializationsData">
                                                    <h6 className="FearturedSpecializationsTitle">People Analytics</h6>
                                                    <p className="FearturedSpecializationsdesc">When you rely on data to make the right decisions for your business,you need the best
                                                        talent you can get.</p>
                                                </Col> */}
                                            </Link>
                                        </Col>
                                    </Col>
                                </Row>
                            </Col>

                        </Row>
                        </Container>
                    </Col>
                </Row>

            </Container>
        </section >
    );
}

export default FearturedSpecializations;