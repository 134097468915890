import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import logoImage from "../../Assets/HomePageImages/Transparent_PhenomStaff_Logo.svg";


function MoreQuestions() {
    return (
        <section>
            <Container fluid className="MoreQuestions-section" id="MoreQuestions">
            
                <Row>
                    <Col md={12} className="MoreQuestionsConent">
                    <span className="BGlogodiv">
                                                <img
                                                    src={logoImage}
                                                    alt="ArchitectsImage pic"
                                                    className="img-fluid BGlogo"
                                                />
                                            </span>
                        <Row style={{ justifyContent: "center"}}>
                            <Col md={12} style={{padding:0}}>
                                <Container fluid='md' className = "justify-content-between" >
                                <Row style={{ alignItems:"center"}} className="justify-content-between">
                                    <Col md={6} style={{padding:0}} >
                                     <div className="MoreQuestionscard">
                                       <h4 className="MoreQuestionscardtitle fs-1">More Questions?</h4>
                                       <p className="MoreQuestionscarddesc">Get the answers. Talk with a consultant now or book an appointment.</p>
                                      </div>
                                </Col>
                                <Col md={6} style={{padding:0}}>
                                <div className="MoreQuestionsLinksdiv">
                                <div className="MoreQuestionsLink"> 
                                       <a href="/" target="_blank" rel="noreferrer" className="">Call Now <span>&#x27F6;</span></a> 
                                    </div>
                                    <div className="MoreQuestionsLink"> 
                                    <a href="/" target="_blank" rel="noreferrer" className="">Book an Appointment  <span>&#x27F6;</span></a>
                                    </div>
                                    </div>
                                </Col> 
                                </Row>
                                </Container>
                            </Col>

                        </Row>

                    </Col>
                </Row>
            </Container>
        </section >
    );
}

export default MoreQuestions;